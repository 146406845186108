export const cityjson = [{
    "label": "北京",
    "value": "110000",
    "center": "116.405285,39.904989",
    "children": [{
        "label": "北京市",
        "value": "110100",
        "center": "116.405285,39.904989",
        "children": [{
            "label": "东城区",
            "value": "110101",
            "center": "116.433,39.9339"
        }, {
            "label": "西城区",
            "value": "110102",
            "center": "116.359,39.8894"
        }, {
            "label": "朝阳区",
            "value": "110105",
            "center": "116.504,39.8866"
        }, {
            "label": "丰台区",
            "value": "110106",
            "center": "116.168,39.8164"
        }, {
            "label": "石景山区",
            "value": "110107",
            "center": "116.195,39.919"
        }, {
            "label": "海淀区",
            "value": "110108",
            "center": "116.242,40.1072"
        }, {
            "label": "门头沟区",
            "value": "110109",
            "center": "115.51,40.0623"
        }, {
            "label": "房山区",
            "value": "110111",
            "center": "115.56,39.7904"
        }, {
            "label": "通州区",
            "value": "110112",
            "center": "116.808,39.7867"
        }, {
            "label": "顺义区",
            "value": "110113",
            "center": "116.747,40.1224"
        }, {
            "label": "昌平区",
            "value": "110114",
            "center": "116.259,40.1128"
        }, {
            "label": "大兴区",
            "value": "110115",
            "center": "116.308,39.4845"
        }, {
            "label": "怀柔区",
            "value": "110116",
            "center": "116.517,40.4904"
        }, {
            "label": "平谷区",
            "value": "110117",
            "center": "117.067,40.1069"
        }, {
            "label": "密云县",
            "value": "110228",
            "center": "116.843352,40.377362"
        }, {
            "label": "延庆县",
            "value": "110229",
            "center": "115.985006,40.465325"
        }]
    }]
}, {
    "label": "天津",
    "value": "120000",
    "center": "117.190182,39.125596",
    "children": [{
        "label": "天津市",
        "value": "120100",
        "center": "117.190182,39.125596",
        "children": [{
            "label": "和平区",
            "value": "120101",
            "center": "117.188,39.1368"
        }, {
            "label": "河东区",
            "value": "120102",
            "center": "117.241,39.1182"
        }, {
            "label": "河西区",
            "value": "120103",
            "center": "117.209,39.0731"
        }, {
            "label": "南开区",
            "value": "120104",
            "center": "117.159,39.08"
        }, {
            "label": "河北区",
            "value": "120105",
            "center": "117.202,39.1887"
        }, {
            "label": "红桥区",
            "value": "120106",
            "center": "117.168,39.184"
        }, {
            "label": "东丽区",
            "value": "120110",
            "center": "117.405,39.1054"
        }, {
            "label": "西青区",
            "value": "120111",
            "center": "116.917,39.1099"
        }, {
            "label": "津南区",
            "value": "120112",
            "center": "117.264,39.0298"
        }, {
            "label": "北辰区",
            "value": "120113",
            "center": "117.229,39.2306"
        }, {
            "label": "武清区",
            "value": "120114",
            "center": "116.855,39.4804"
        }, {
            "label": "宝坻区",
            "value": "120115",
            "center": "117.32048,39.632329"
        }, {
            "label": "滨海新区",
            "value": "120116",
            "center": "117.971,39.3133"
        }, {
            "label": "宁河县",
            "value": "120221",
            "center": "117.82828,39.328886"
        }, {
            "label": "静海县",
            "value": "120223",
            "center": "116.925304,38.935671"
        }, {
            "label": "蓟县",
            "value": "120225",
            "center": "117.407449,40.045342"
        }]
    }]
}, {
    "label": "河北省",
    "value": "130000",
    "center": "114.502461,38.045474",
    "children": [{
        "label": "石家庄市",
        "value": "130100",
        "center": "114.502461,38.045474",
        "children": [{
            "label": "长安区",
            "value": "130102",
            "center": "114.548151,38.047501"
        }, {
            "label": "桥东区",
            "value": "130103",
            "center": "114.507131,37.064125"
        }, {
            "label": "桥西区",
            "value": "130104",
            "center": "114.462931,38.028383"
        }, {
            "label": "新华区",
            "value": "130105",
            "center": "114.465974,38.067142"
        }, {
            "label": "井陉矿区",
            "value": "130107",
            "center": "114.058178,38.069748"
        }, {
            "label": "鹿泉市",
            "value": "130185",
            "center": "114.321023,38.093994"
        }, {
            "label": "新乐市",
            "value": "130184",
            "center": "114.68578,38.344768"
        }, {
            "label": "晋州市",
            "value": "130183",
            "center": "115.044886,38.027478"
        }, {
            "label": "藁城市",
            "value": "130182",
            "center": "114.849647,38.033767"
        }, {
            "label": "辛集市",
            "value": "130181",
            "center": "115.217451,37.92904"
        }, {
            "label": "赵县",
            "value": "130133",
            "center": "114.775362,37.754341"
        }, {
            "label": "元氏县",
            "value": "130132",
            "center": "114.52618,37.762514"
        }, {
            "label": "平山县",
            "value": "130131",
            "center": "114.184144,38.259311"
        }, {
            "label": "无极县",
            "value": "130130",
            "center": "114.977845,38.176376"
        }, {
            "label": "赞皇县",
            "value": "130129",
            "center": "114.387756,37.660199"
        }, {
            "label": "深泽县",
            "value": "130128",
            "center": "115.200207,38.18454"
        }, {
            "label": "高邑县",
            "value": "130127",
            "center": "114.610699,37.605714"
        }, {
            "label": "灵寿县",
            "value": "130126",
            "center": "114.37946,38.306546"
        }, {
            "label": "行唐县",
            "value": "130125",
            "center": "114.552734,38.437422"
        }, {
            "label": "栾城县",
            "value": "130124",
            "center": "114.654281,37.886911"
        }, {
            "label": "正定县",
            "value": "130123",
            "center": "114.569887,38.147835"
        }, {
            "label": "井陉县",
            "value": "130121",
            "center": "114.144488,38.033614"
        }, {
            "label": "裕华区",
            "value": "130108",
            "center": "114.533257,38.027696"
        }]
    }, {
        "label": "衡水市",
        "value": "131100",
        "center": "115.665993,37.735097",
        "children": [{
            "label": "桃城区",
            "value": "131102",
            "center": "115.694945,37.732237"
        }, {
            "label": "枣强县",
            "value": "131121",
            "center": "115.726499,37.511512"
        }, {
            "label": "武邑县",
            "value": "131122",
            "center": "115.892415,37.803774"
        }, {
            "label": "武强县",
            "value": "131123",
            "center": "115.970236,38.03698"
        }, {
            "label": "饶阳县",
            "value": "131124",
            "center": "115.726577,38.232671"
        }, {
            "label": "安平县",
            "value": "131125",
            "center": "115.519627,38.233511"
        }, {
            "label": "故城县",
            "value": "131126",
            "center": "115.966747,37.350981"
        }, {
            "label": "景县",
            "value": "131127",
            "center": "116.258446,37.686622"
        }, {
            "label": "阜城县",
            "value": "131128",
            "center": "116.164727,37.869945"
        }, {
            "label": "冀州市",
            "value": "131181",
            "center": "115.579173,37.542788"
        }, {
            "label": "深州市",
            "value": "131182",
            "center": "115.554596,38.00347"
        }]
    }, {
        "label": "邢台市",
        "value": "130500",
        "center": "114.508851,37.0682",
        "children": [{
            "label": "桥东区",
            "value": "130502",
            "center": "114.507131,37.064125"
        }, {
            "label": "桥西区",
            "value": "130503",
            "center": "114.473687,37.068009"
        }, {
            "label": "邢台县",
            "value": "130521",
            "center": "114.561132,37.05073"
        }, {
            "label": "临城县",
            "value": "130522",
            "center": "114.506873,37.444009"
        }, {
            "label": "内丘县",
            "value": "130523",
            "center": "114.511523,37.287663"
        }, {
            "label": "柏乡县",
            "value": "130524",
            "center": "114.693382,37.483596"
        }, {
            "label": "隆尧县",
            "value": "130525",
            "center": "114.776348,37.350925"
        }, {
            "label": "任县",
            "value": "130526",
            "center": "114.684469,37.129952"
        }, {
            "label": "南和县",
            "value": "130527",
            "center": "114.691377,37.003812"
        }, {
            "label": "宁晋县",
            "value": "130528",
            "center": "114.921027,37.618956"
        }, {
            "label": "巨鹿县",
            "value": "130529",
            "center": "115.038782,37.21768"
        }, {
            "label": "新河县",
            "value": "130530",
            "center": "115.247537,37.526216"
        }, {
            "label": "广宗县",
            "value": "130531",
            "center": "115.142797,37.075548"
        }, {
            "label": "平乡县",
            "value": "130532",
            "center": "115.029218,37.069404"
        }, {
            "label": "威县",
            "value": "130533",
            "center": "115.272749,36.983272"
        }, {
            "label": "清河县",
            "value": "130534",
            "center": "115.668999,37.059991"
        }, {
            "label": "临西县",
            "value": "130535",
            "center": "115.498684,36.8642"
        }, {
            "label": "南宫市",
            "value": "130581",
            "center": "115.398102,37.359668"
        }, {
            "label": "沙河市",
            "value": "130582",
            "center": "114.504902,36.861903"
        }]
    }, {
        "label": "保定市",
        "value": "130600",
        "center": "115.482331,38.867657",
        "children": [{
            "label": "新市区",
            "value": "130602",
            "center": "115.470659,38.88662"
        }, {
            "label": "北市区",
            "value": "130603",
            "center": "115.500934,38.865005"
        }, {
            "label": "南市区",
            "value": "130604",
            "center": "115.500934,38.865005"
        }, {
            "label": "满城县",
            "value": "130621",
            "center": "115.32442,38.95138"
        }, {
            "label": "清苑县",
            "value": "130622",
            "center": "115.492221,38.771012"
        }, {
            "label": "涞水县",
            "value": "130623",
            "center": "115.711985,39.393148"
        }, {
            "label": "阜平县",
            "value": "130624",
            "center": "114.198801,38.847276"
        }, {
            "label": "徐水县",
            "value": "130625",
            "center": "115.64941,39.020395"
        }, {
            "label": "定兴县",
            "value": "130626",
            "center": "115.796895,39.266195"
        }, {
            "label": "唐县",
            "value": "130627",
            "center": "114.981241,38.748542"
        }, {
            "label": "高阳县",
            "value": "130628",
            "center": "115.778878,38.690092"
        }, {
            "label": "容城县",
            "value": "130629",
            "center": "115.866247,39.05282"
        }, {
            "label": "涞源县",
            "value": "130630",
            "center": "114.692567,39.35755"
        }, {
            "label": "望都县",
            "value": "130631",
            "center": "115.154009,38.707448"
        }, {
            "label": "安新县",
            "value": "130632",
            "center": "115.931979,38.929912"
        }, {
            "label": "易县",
            "value": "130633",
            "center": "115.501146,39.35297"
        }, {
            "label": "曲阳县",
            "value": "130634",
            "center": "114.704055,38.619992"
        }, {
            "label": "蠡县",
            "value": "130635",
            "center": "115.583631,38.496429"
        }, {
            "label": "顺平县",
            "value": "130636",
            "center": "115.132749,38.845127"
        }, {
            "label": "博野县",
            "value": "130637",
            "center": "115.461798,38.458271"
        }, {
            "label": "雄县",
            "value": "130638",
            "center": "116.107474,38.990819"
        }, {
            "label": "涿州市",
            "value": "130681",
            "center": "115.973409,39.485765"
        }, {
            "label": "定州市",
            "value": "130682",
            "center": "114.991389,38.517602"
        }, {
            "label": "安国市",
            "value": "130683",
            "center": "115.33141,38.421367"
        }, {
            "label": "高碑店市",
            "value": "130684",
            "center": "115.882704,39.327689"
        }]
    }, {
        "label": "张家口市",
        "value": "130700",
        "center": "114.884091,40.811901",
        "children": [{
            "label": "桥东区",
            "value": "130702",
            "center": "114.885658,40.813875"
        }, {
            "label": "桥西区",
            "value": "130703",
            "center": "114.882127,40.824385"
        }, {
            "label": "宣化区",
            "value": "130705",
            "center": "115.0632,40.609368"
        }, {
            "label": "下花园区",
            "value": "130706",
            "center": "115.281002,40.488645"
        }, {
            "label": "宣化县",
            "value": "130721",
            "center": "115.0632,40.609368"
        }, {
            "label": "张北县",
            "value": "130722",
            "center": "114.715951,41.151713"
        }, {
            "label": "康保县",
            "value": "130723",
            "center": "114.615809,41.850046"
        }, {
            "label": "沽源县",
            "value": "130724",
            "center": "115.684836,41.667419"
        }, {
            "label": "尚义县",
            "value": "130725",
            "center": "113.977713,41.080091"
        }, {
            "label": "蔚县",
            "value": "130726",
            "center": "114.582695,39.837181"
        }, {
            "label": "阳原县",
            "value": "130727",
            "center": "114.167343,40.113419"
        }, {
            "label": "怀安县",
            "value": "130728",
            "center": "114.422364,40.671274"
        }, {
            "label": "万全县",
            "value": "130729",
            "center": "114.736131,40.765136"
        }, {
            "label": "怀来县",
            "value": "130730",
            "center": "115.520846,40.405405"
        }, {
            "label": "涿鹿县",
            "value": "130731",
            "center": "115.219246,40.378701"
        }, {
            "label": "赤城县",
            "value": "130732",
            "center": "115.832708,40.912081"
        }, {
            "label": "崇礼县",
            "value": "130733",
            "center": "115.281652,40.971302"
        }]
    }, {
        "label": "承德市",
        "value": "130800",
        "center": "117.939152,40.976204",
        "children": [{
            "label": "双桥区",
            "value": "130802",
            "center": "117.939152,40.976204"
        }, {
            "label": "双滦区",
            "value": "130803",
            "center": "117.797485,40.959756"
        }, {
            "label": "鹰手营子矿区",
            "value": "130804",
            "center": "117.661154,40.546956"
        }, {
            "label": "承德县",
            "value": "130821",
            "center": "118.172496,40.768637"
        }, {
            "label": "兴隆县",
            "value": "130822",
            "center": "117.507098,40.418525"
        }, {
            "label": "平泉县",
            "value": "130823",
            "center": "118.690238,41.00561"
        }, {
            "label": "滦平县",
            "value": "130824",
            "center": "117.337124,40.936644"
        }, {
            "label": "隆化县",
            "value": "130825",
            "center": "117.736343,41.316667"
        }, {
            "label": "丰宁满族自治县",
            "value": "130826",
            "center": "116.65121,41.209903"
        }, {
            "label": "宽城满族自治县",
            "value": "130827",
            "center": "118.488642,40.607981"
        }, {
            "label": "围场满族蒙古族自治县",
            "value": "130828",
            "center": "117.764086,41.949404"
        }]
    }, {
        "label": "沧州市",
        "value": "130900",
        "center": "116.857461,38.310582",
        "children": [{
            "label": "新华区",
            "value": "130902",
            "center": "116.873049,38.308273"
        }, {
            "label": "运河区",
            "value": "130903",
            "center": "116.840063,38.307405"
        }, {
            "label": "青县",
            "value": "130922",
            "center": "116.838384,38.569646"
        }, {
            "label": "东光县",
            "value": "130923",
            "center": "116.542062,37.88655"
        }, {
            "label": "海兴县",
            "value": "130924",
            "center": "117.496606,38.141582"
        }, {
            "label": "盐山县",
            "value": "130925",
            "center": "117.229814,38.056141"
        }, {
            "label": "肃宁县",
            "value": "130926",
            "center": "115.835856,38.427102"
        }, {
            "label": "南皮县",
            "value": "130927",
            "center": "116.709171,38.042439"
        }, {
            "label": "吴桥县",
            "value": "130928",
            "center": "116.391512,37.628182"
        }, {
            "label": "献县",
            "value": "130929",
            "center": "116.123844,38.189661"
        }, {
            "label": "孟村回族自治县",
            "value": "130930",
            "center": "117.105104,38.057953"
        }, {
            "label": "泊头市",
            "value": "130981",
            "center": "116.570163,38.073479"
        }, {
            "label": "任丘市",
            "value": "130982",
            "center": "116.106764,38.706513"
        }, {
            "label": "黄骅市",
            "value": "130983",
            "center": "117.343803,38.369238"
        }, {
            "label": "河间市",
            "value": "130984",
            "center": "116.089452,38.44149"
        }, {
            "label": "沧县",
            "value": "130921",
            "center": "117.007478,38.219856"
        }]
    }, {
        "label": "廊坊市",
        "value": "131000",
        "center": "116.704441,39.523927",
        "children": [{
            "label": "广阳区",
            "value": "131003",
            "center": "116.713708,39.521931"
        }, {
            "label": "固安县",
            "value": "131022",
            "center": "116.299894,39.436468"
        }, {
            "label": "永清县",
            "value": "131023",
            "center": "116.498089,39.319717"
        }, {
            "label": "香河县",
            "value": "131024",
            "center": "117.007161,39.757212"
        }, {
            "label": "安次区",
            "value": "131002",
            "center": "116.694544,39.502569"
        }, {
            "label": "大城县",
            "value": "131025",
            "center": "116.640735,38.699215"
        }, {
            "label": "文安县",
            "value": "131026",
            "center": "116.460107,38.866801"
        }, {
            "label": "大厂回族自治县",
            "value": "131028",
            "center": "116.986501,39.889266"
        }, {
            "label": "霸州市",
            "value": "131081",
            "center": "116.392021,39.117331"
        }, {
            "label": "三河市",
            "value": "131082",
            "center": "117.077018,39.982778"
        }]
    }, {
        "label": "邯郸市",
        "value": "130400",
        "center": "114.490686,36.612273",
        "children": [{
            "label": "磁县",
            "value": "130427",
            "center": "114.38208,36.367673"
        }, {
            "label": "肥乡县",
            "value": "130428",
            "center": "114.805154,36.555778"
        }, {
            "label": "永年县",
            "value": "130429",
            "center": "114.496162,36.776413"
        }, {
            "label": "邱县",
            "value": "130430",
            "center": "115.168584,36.81325"
        }, {
            "label": "鸡泽县",
            "value": "130431",
            "center": "114.878517,36.914908"
        }, {
            "label": "广平县",
            "value": "130432",
            "center": "114.950859,36.483603"
        }, {
            "label": "馆陶县",
            "value": "130433",
            "center": "115.289057,36.539461"
        }, {
            "label": "魏县",
            "value": "130434",
            "center": "114.93411,36.354248"
        }, {
            "label": "曲周县",
            "value": "130435",
            "center": "114.957588,36.773398"
        }, {
            "label": "武安市",
            "value": "130481",
            "center": "114.194581,36.696115"
        }, {
            "label": "涉县",
            "value": "130426",
            "center": "113.673297,36.563143"
        }, {
            "label": "大名县",
            "value": "130425",
            "center": "115.152586,36.283316"
        }, {
            "label": "成安县",
            "value": "130424",
            "center": "114.680356,36.443832"
        }, {
            "label": "临漳县",
            "value": "130423",
            "center": "114.610703,36.337604"
        }, {
            "label": "邯郸县",
            "value": "130421",
            "center": "114.484989,36.603196"
        }, {
            "label": "峰峰矿区",
            "value": "130406",
            "center": "114.209936,36.420487"
        }, {
            "label": "复兴区",
            "value": "130404",
            "center": "114.458242,36.615484"
        }, {
            "label": "丛台区",
            "value": "130403",
            "center": "114.494703,36.611082"
        }, {
            "label": "邯山区",
            "value": "130402",
            "center": "114.484989,36.603196"
        }]
    }, {
        "label": "秦皇岛市",
        "value": "130300",
        "center": "119.586579,39.942531",
        "children": [{
            "label": "卢龙县",
            "value": "130324",
            "center": "118.881809,39.891639"
        }, {
            "label": "抚宁县",
            "value": "130323",
            "center": "119.240651,39.887053"
        }, {
            "label": "昌黎县",
            "value": "130322",
            "center": "119.164541,39.709729"
        }, {
            "label": "青龙满族自治县",
            "value": "130321",
            "center": "118.954555,40.406023"
        }, {
            "label": "北戴河区",
            "value": "130304",
            "center": "119.486286,39.825121"
        }, {
            "label": "山海关区",
            "value": "130303",
            "center": "119.753591,39.998023"
        }, {
            "label": "海港区",
            "value": "130302",
            "center": "119.596224,39.943458"
        }]
    }, {
        "label": "唐山市",
        "value": "130200",
        "center": "118.175393,39.635113",
        "children": [{
            "label": "迁安市",
            "value": "130283",
            "center": "118.701144,39.999174"
        }, {
            "label": "遵化市",
            "value": "130281",
            "center": "117.965892,40.189201"
        }, {
            "label": "曹妃甸区",
            "value": "130230",
            "center": "118.446585,39.278277"
        }, {
            "label": "玉田县",
            "value": "130229",
            "center": "117.738658,39.900401"
        }, {
            "label": "迁西县",
            "value": "130227",
            "center": "118.314715,40.1415"
        }, {
            "label": "乐亭县",
            "value": "130225",
            "center": "118.912571,39.425608"
        }, {
            "label": "滦南县",
            "value": "130224",
            "center": "118.682379,39.518996"
        }, {
            "label": "滦县",
            "value": "130223",
            "center": "118.703598,39.740593"
        }, {
            "label": "丰润区",
            "value": "130208",
            "center": "118.162215,39.832582"
        }, {
            "label": "丰南区",
            "value": "130207",
            "center": "118.085169,39.576031"
        }, {
            "label": "开平区",
            "value": "130205",
            "center": "118.261841,39.671001"
        }, {
            "label": "古冶区",
            "value": "130204",
            "center": "118.447635,39.733578"
        }, {
            "label": "路北区",
            "value": "130203",
            "center": "118.200692,39.624437"
        }, {
            "label": "路南区",
            "value": "130202",
            "center": "118.154354,39.625058"
        }]
    }]
}, {
    "label": "山西省",
    "value": "140000",
    "center": "112.549248,37.857014",
    "children": [{
        "label": "太原市",
        "value": "140100",
        "center": "112.549248,37.857014",
        "children": [{
            "label": "小店区",
            "value": "140105",
            "center": "112.564273,37.817974"
        }, {
            "label": "迎泽区",
            "value": "140106",
            "center": "112.558851,37.855804"
        }, {
            "label": "杏花岭区",
            "value": "140107",
            "center": "112.560743,37.879291"
        }, {
            "label": "尖草坪区",
            "value": "140108",
            "center": "112.487122,37.939893"
        }, {
            "label": "万柏林区",
            "value": "140109",
            "center": "112.522258,37.862653"
        }, {
            "label": "晋源区",
            "value": "140110",
            "center": "112.477849,37.715619"
        }, {
            "label": "清徐县",
            "value": "140121",
            "center": "112.357961,37.60729"
        }, {
            "label": "阳曲县",
            "value": "140122",
            "center": "112.673818,38.058797"
        }, {
            "label": "娄烦县",
            "value": "140123",
            "center": "111.793798,38.066035"
        }, {
            "label": "古交市",
            "value": "140181",
            "center": "112.174353,37.908534"
        }]
    }, {
        "label": "大同市",
        "value": "140200",
        "center": "113.295259,40.09031",
        "children": [{
            "label": "城区",
            "value": "140202",
            "center": "113.301438,40.090511"
        }, {
            "label": "矿区",
            "value": "140203",
            "center": "113.168656,40.03626"
        }, {
            "label": "南郊区",
            "value": "140211",
            "center": "113.16892,40.01802"
        }, {
            "label": "新荣区",
            "value": "140212",
            "center": "113.141044,40.258269"
        }, {
            "label": "阳高县",
            "value": "140221",
            "center": "113.749871,40.364927"
        }, {
            "label": "天镇县",
            "value": "140222",
            "center": "114.09112,40.421336"
        }, {
            "label": "广灵县",
            "value": "140223",
            "center": "114.279252,39.763051"
        }, {
            "label": "灵丘县",
            "value": "140224",
            "center": "114.23576,39.438867"
        }, {
            "label": "浑源县",
            "value": "140225",
            "center": "113.698091,39.699099"
        }, {
            "label": "左云县",
            "value": "140226",
            "center": "112.70641,40.012873"
        }, {
            "label": "大同县",
            "value": "140227",
            "center": "113.611306,40.039345"
        }]
    }, {
        "label": "阳泉市",
        "value": "140300",
        "center": "113.583285,37.861188",
        "children": [{
            "label": "城区",
            "value": "140302",
            "center": "113.586513,37.860938"
        }, {
            "label": "矿区",
            "value": "140303",
            "center": "113.559066,37.870085"
        }, {
            "label": "郊区",
            "value": "140311",
            "center": "113.58664,37.94096"
        }, {
            "label": "平定县",
            "value": "140321",
            "center": "113.631049,37.800289"
        }, {
            "label": "盂县",
            "value": "140322",
            "center": "113.41223,38.086131"
        }]
    }, {
        "label": "长治市",
        "value": "140400",
        "center": "113.113556,36.191112",
        "children": [{
            "label": "城区",
            "value": "140402",
            "center": "113.114107,36.187896"
        }, {
            "label": "郊区",
            "value": "140411",
            "center": "113.101211,36.218388"
        }, {
            "label": "长治县",
            "value": "140421",
            "center": "113.056679,36.052438"
        }, {
            "label": "襄垣县",
            "value": "140423",
            "center": "113.050094,36.532854"
        }, {
            "label": "屯留县",
            "value": "140424",
            "center": "112.892741,36.314072"
        }, {
            "label": "平顺县",
            "value": "140425",
            "center": "113.438791,36.200202"
        }, {
            "label": "黎城县",
            "value": "140426",
            "center": "113.387366,36.502971"
        }, {
            "label": "壶关县",
            "value": "140427",
            "center": "113.206138,36.110938"
        }, {
            "label": "长子县",
            "value": "140428",
            "center": "112.884656,36.119484"
        }, {
            "label": "武乡县",
            "value": "140429",
            "center": "112.8653,36.834315"
        }, {
            "label": "沁县",
            "value": "140430",
            "center": "112.70138,36.757123"
        }, {
            "label": "沁源县",
            "value": "140431",
            "center": "112.340878,36.500777"
        }, {
            "label": "潞城市",
            "value": "140481",
            "center": "113.223245,36.332233"
        }]
    }, {
        "label": "晋城市",
        "value": "140500",
        "center": "112.851274,35.497553",
        "children": [{
            "label": "城区",
            "value": "140502",
            "center": "112.853106,35.496641"
        }, {
            "label": "沁水县",
            "value": "140521",
            "center": "112.187213,35.689472"
        }, {
            "label": "阳城县",
            "value": "140522",
            "center": "112.422014,35.482177"
        }, {
            "label": "陵川县",
            "value": "140524",
            "center": "113.278877,35.775614"
        }, {
            "label": "泽州县",
            "value": "140525",
            "center": "112.899137,35.617221"
        }, {
            "label": "高平市",
            "value": "140581",
            "center": "112.930691,35.791355"
        }]
    }, {
        "label": "朔州市",
        "value": "140600",
        "center": "112.433387,39.331261",
        "children": [{
            "label": "朔城区",
            "value": "140602",
            "center": "112.428676,39.324525"
        }, {
            "label": "平鲁区",
            "value": "140603",
            "center": "112.295227,39.515603"
        }, {
            "label": "山阴县",
            "value": "140621",
            "center": "112.816396,39.52677"
        }, {
            "label": "应县",
            "value": "140622",
            "center": "113.187505,39.559187"
        }, {
            "label": "右玉县",
            "value": "140623",
            "center": "112.465588,39.988812"
        }, {
            "label": "怀仁县",
            "value": "140624",
            "center": "113.100512,39.82079"
        }]
    }, {
        "label": "晋中市",
        "value": "140700",
        "center": "112.736465,37.696495",
        "children": [{
            "label": "榆次区",
            "value": "140702",
            "center": "112.740056,37.6976"
        }, {
            "label": "榆社县",
            "value": "140721",
            "center": "112.973521,37.069019"
        }, {
            "label": "左权县",
            "value": "140722",
            "center": "113.377834,37.079672"
        }, {
            "label": "和顺县",
            "value": "140723",
            "center": "113.572919,37.327027"
        }, {
            "label": "昔阳县",
            "value": "140724",
            "center": "113.706166,37.60437"
        }, {
            "label": "寿阳县",
            "value": "140725",
            "center": "113.177708,37.891136"
        }, {
            "label": "太谷县",
            "value": "140726",
            "center": "112.554103,37.424595"
        }, {
            "label": "祁县",
            "value": "140727",
            "center": "112.330532,37.358739"
        }, {
            "label": "平遥县",
            "value": "140728",
            "center": "112.174059,37.195474"
        }, {
            "label": "灵石县",
            "value": "140729",
            "center": "111.772759,36.847469"
        }, {
            "label": "介休市",
            "value": "140781",
            "center": "111.913857,37.027616"
        }]
    }, {
        "label": "运城市",
        "value": "140800",
        "center": "111.003957,35.022778",
        "children": [{
            "label": "盐湖区",
            "value": "140802",
            "center": "111.000627,35.025643"
        }, {
            "label": "临猗县",
            "value": "140821",
            "center": "110.77493,35.141883"
        }, {
            "label": "万荣县",
            "value": "140822",
            "center": "110.843561,35.417042"
        }, {
            "label": "闻喜县",
            "value": "140823",
            "center": "111.220306,35.353839"
        }, {
            "label": "稷山县",
            "value": "140824",
            "center": "110.978996,35.600412"
        }, {
            "label": "新绛县",
            "value": "140825",
            "center": "111.225205,35.613697"
        }, {
            "label": "绛县",
            "value": "140826",
            "center": "111.576182,35.49045"
        }, {
            "label": "垣曲县",
            "value": "140827",
            "center": "111.67099,35.298293"
        }, {
            "label": "夏县",
            "value": "140828",
            "center": "111.223174,35.140441"
        }, {
            "label": "平陆县",
            "value": "140829",
            "center": "111.212377,34.837256"
        }, {
            "label": "芮城县",
            "value": "140830",
            "center": "110.69114,34.694769"
        }, {
            "label": "永济市",
            "value": "140881",
            "center": "110.447984,34.865125"
        }, {
            "label": "河津市",
            "value": "140882",
            "center": "110.710268,35.59715"
        }]
    }, {
        "label": "忻州市",
        "value": "140900",
        "center": "112.733538,38.41769",
        "children": [{
            "label": "忻府区",
            "value": "140902",
            "center": "112.734112,38.417743"
        }, {
            "label": "定襄县",
            "value": "140921",
            "center": "112.963231,38.484948"
        }, {
            "label": "五台县",
            "value": "140922",
            "center": "113.259012,38.725711"
        }, {
            "label": "代县",
            "value": "140923",
            "center": "112.962519,39.065138"
        }, {
            "label": "繁峙县",
            "value": "140924",
            "center": "113.267707,39.188104"
        }, {
            "label": "宁武县",
            "value": "140925",
            "center": "112.307936,39.001718"
        }, {
            "label": "静乐县",
            "value": "140926",
            "center": "111.940231,38.355947"
        }, {
            "label": "神池县",
            "value": "140927",
            "center": "112.200438,39.088467"
        }, {
            "label": "五寨县",
            "value": "140928",
            "center": "111.841015,38.912761"
        }, {
            "label": "岢岚县",
            "value": "140929",
            "center": "111.56981,38.705625"
        }, {
            "label": "河曲县",
            "value": "140930",
            "center": "111.146609,39.381895"
        }, {
            "label": "保德县",
            "value": "140931",
            "center": "111.085688,39.022576"
        }, {
            "label": "偏关县",
            "value": "140932",
            "center": "111.500477,39.442153"
        }, {
            "label": "原平市",
            "value": "140981",
            "center": "112.713132,38.729186"
        }]
    }, {
        "label": "临汾市",
        "value": "141000",
        "center": "111.517973,36.08415",
        "children": [{
            "label": "尧都区",
            "value": "141002",
            "center": "111.522945,36.080366"
        }, {
            "label": "曲沃县",
            "value": "141021",
            "center": "111.475529,35.641387"
        }, {
            "label": "翼城县",
            "value": "141022",
            "center": "111.713508,35.738621"
        }, {
            "label": "襄汾县",
            "value": "141023",
            "center": "111.442932,35.876139"
        }, {
            "label": "洪洞县",
            "value": "141024",
            "center": "111.673692,36.255742"
        }, {
            "label": "古县",
            "value": "141025",
            "center": "111.920207,36.26855"
        }, {
            "label": "安泽县",
            "value": "141026",
            "center": "112.251372,36.146032"
        }, {
            "label": "浮山县",
            "value": "141027",
            "center": "111.850039,35.971359"
        }, {
            "label": "吉县",
            "value": "141028",
            "center": "110.682853,36.099355"
        }, {
            "label": "乡宁县",
            "value": "141029",
            "center": "110.857365,35.975402"
        }, {
            "label": "大宁县",
            "value": "141030",
            "center": "110.751283,36.46383"
        }, {
            "label": "隰县",
            "value": "141031",
            "center": "110.935809,36.692675"
        }, {
            "label": "永和县",
            "value": "141032",
            "center": "110.631276,36.760614"
        }, {
            "label": "蒲县",
            "value": "141033",
            "center": "111.09733,36.411682"
        }, {
            "label": "汾西县",
            "value": "141034",
            "center": "111.563021,36.653368"
        }, {
            "label": "侯马市",
            "value": "141081",
            "center": "111.371272,35.620302"
        }, {
            "label": "霍州市",
            "value": "141082",
            "center": "111.723103,36.57202"
        }]
    }, {
        "label": "吕梁市",
        "value": "141100",
        "center": "111.134335,37.524366",
        "children": [{
            "label": "离石区",
            "value": "141102",
            "center": "111.134462,37.524037"
        }, {
            "label": "文水县",
            "value": "141121",
            "center": "112.032595,37.436314"
        }, {
            "label": "交城县",
            "value": "141122",
            "center": "112.159154,37.555155"
        }, {
            "label": "兴县",
            "value": "141123",
            "center": "111.124816,38.464136"
        }, {
            "label": "临县",
            "value": "141124",
            "center": "110.995963,37.960806"
        }, {
            "label": "柳林县",
            "value": "141125",
            "center": "110.89613,37.431664"
        }, {
            "label": "石楼县",
            "value": "141126",
            "center": "110.837119,36.999426"
        }, {
            "label": "岚县",
            "value": "141127",
            "center": "111.671555,38.278654"
        }, {
            "label": "方山县",
            "value": "141128",
            "center": "111.238885,37.892632"
        }, {
            "label": "中阳县",
            "value": "141129",
            "center": "111.193319,37.342054"
        }, {
            "label": "交口县",
            "value": "141130",
            "center": "111.183188,36.983068"
        }, {
            "label": "孝义市",
            "value": "141181",
            "center": "111.781568,37.144474"
        }, {
            "label": "汾阳市",
            "value": "141182",
            "center": "111.785273,37.267742"
        }]
    }]
}, {
    "label": "内蒙古自治区",
    "value": "150000",
    "center": "111.670801,40.818311",
    "children": [{
        "label": "鄂尔多斯市",
        "value": "150600",
        "center": "109.99029,39.817179",
        "children": [{
            "label": "东胜区",
            "value": "150602",
            "center": "109.98945,39.81788"
        }, {
            "label": "达拉特旗",
            "value": "150621",
            "center": "110.040281,40.404076"
        }, {
            "label": "准格尔旗",
            "value": "150622",
            "center": "111.238332,39.865221"
        }, {
            "label": "鄂托克前旗",
            "value": "150623",
            "center": "107.48172,38.183257"
        }, {
            "label": "鄂托克旗",
            "value": "150624",
            "center": "107.982604,39.095752"
        }, {
            "label": "杭锦旗",
            "value": "150625",
            "center": "108.736324,39.831789"
        }, {
            "label": "乌审旗",
            "value": "150626",
            "center": "108.842454,38.596611"
        }, {
            "label": "伊金霍洛旗",
            "value": "150627",
            "center": "109.787402,39.604312"
        }]
    }, {
        "label": "呼伦贝尔市",
        "value": "150700",
        "center": "119.758168,49.215333",
        "children": [{
            "label": "海拉尔区",
            "value": "150702",
            "center": "119.764923,49.213889"
        }, {
            "label": "阿荣旗",
            "value": "150721",
            "center": "123.464615,48.130503"
        }, {
            "label": "莫力达瓦达斡尔族自治旗",
            "value": "150722",
            "center": "124.507401,48.478385"
        }, {
            "label": "鄂伦春自治旗",
            "value": "150723",
            "center": "123.725684,50.590177"
        }, {
            "label": "鄂温克族自治旗",
            "value": "150724",
            "center": "119.754041,49.143293"
        }, {
            "label": "陈巴尔虎旗",
            "value": "150725",
            "center": "119.437609,49.328422"
        }, {
            "label": "新巴尔虎左旗",
            "value": "150726",
            "center": "118.267454,48.216571"
        }, {
            "label": "新巴尔虎右旗",
            "value": "150727",
            "center": "116.825991,48.669134"
        }, {
            "label": "满洲里市",
            "value": "150781",
            "center": "117.455561,49.590788"
        }, {
            "label": "牙克石市",
            "value": "150782",
            "center": "120.729005,49.287024"
        }, {
            "label": "扎兰屯市",
            "value": "150783",
            "center": "122.744401,48.007412"
        }, {
            "label": "额尔古纳市",
            "value": "150784",
            "center": "120.178636,50.2439"
        }, {
            "label": "根河市",
            "value": "150785",
            "center": "121.532724,50.780454"
        }]
    }, {
        "label": "巴彦淖尔市",
        "value": "150800",
        "center": "107.416959,40.757402",
        "children": [{
            "label": "临河区",
            "value": "150802",
            "center": "107.417018,40.757092"
        }, {
            "label": "五原县",
            "value": "150821",
            "center": "108.270658,41.097639"
        }, {
            "label": "磴口县",
            "value": "150822",
            "center": "107.006056,40.330479"
        }, {
            "label": "乌拉特前旗",
            "value": "150823",
            "center": "108.656816,40.725209"
        }, {
            "label": "乌拉特中旗",
            "value": "150824",
            "center": "108.515255,41.57254"
        }, {
            "label": "乌拉特后旗",
            "value": "150825",
            "center": "107.074941,41.084307"
        }, {
            "label": "杭锦后旗",
            "value": "150826",
            "center": "107.147682,40.888797"
        }]
    }, {
        "label": "乌兰察布市",
        "value": "150900",
        "center": "113.114543,41.034126",
        "children": [{
            "label": "集宁区",
            "value": "150902",
            "center": "113.116453,41.034134"
        }, {
            "label": "卓资县",
            "value": "150921",
            "center": "112.577702,40.89576"
        }, {
            "label": "化德县",
            "value": "150922",
            "center": "114.01008,41.899335"
        }, {
            "label": "商都县",
            "value": "150923",
            "center": "113.560643,41.560163"
        }, {
            "label": "兴和县",
            "value": "150924",
            "center": "113.834009,40.872437"
        }, {
            "label": "凉城县",
            "value": "150925",
            "center": "112.500911,40.531627"
        }, {
            "label": "察哈尔右翼前旗",
            "value": "150926",
            "center": "113.211958,40.786859"
        }, {
            "label": "察哈尔右翼中旗",
            "value": "150927",
            "center": "112.633563,41.274212"
        }, {
            "label": "察哈尔右翼后旗",
            "value": "150928",
            "center": "113.1906,41.447213"
        }, {
            "label": "四子王旗",
            "value": "150929",
            "center": "111.70123,41.528114"
        }, {
            "label": "丰镇市",
            "value": "150981",
            "center": "113.163462,40.437534"
        }]
    }, {
        "label": "乌海市",
        "value": "150300",
        "center": "106.825563,39.673734",
        "children": [{
            "label": "海勃湾区",
            "value": "150302",
            "center": "106.817762,39.673527"
        }, {
            "label": "海南区",
            "value": "150303",
            "center": "106.884789,39.44153"
        }, {
            "label": "乌达区",
            "value": "150304",
            "center": "106.722711,39.502288"
        }]
    }, {
        "label": "赤峰市",
        "value": "150400",
        "center": "118.956806,42.275317",
        "children": [{
            "label": "红山区",
            "value": "150402",
            "center": "118.961087,42.269732"
        }, {
            "label": "元宝山区",
            "value": "150403",
            "center": "119.289877,42.041168"
        }, {
            "label": "松山区",
            "value": "150404",
            "center": "118.938958,42.281046"
        }, {
            "label": "阿鲁科尔沁旗",
            "value": "150421",
            "center": "120.094969,43.87877"
        }, {
            "label": "巴林左旗",
            "value": "150422",
            "center": "119.391737,43.980715"
        }, {
            "label": "巴林右旗",
            "value": "150423",
            "center": "118.678347,43.528963"
        }, {
            "label": "林西县",
            "value": "150424",
            "center": "118.05775,43.605326"
        }, {
            "label": "克什克腾旗",
            "value": "150425",
            "center": "117.542465,43.256233"
        }, {
            "label": "翁牛特旗",
            "value": "150426",
            "center": "119.022619,42.937128"
        }, {
            "label": "喀喇沁旗",
            "value": "150428",
            "center": "118.708572,41.92778"
        }, {
            "label": "宁城县",
            "value": "150429",
            "center": "119.339242,41.598692"
        }, {
            "label": "敖汉旗",
            "value": "150430",
            "center": "119.906486,42.287012"
        }]
    }, {
        "label": "呼和浩特市",
        "value": "150100",
        "center": "111.670801,40.818311",
        "children": [{
            "label": "新城区",
            "value": "150102",
            "center": "111.685964,40.826225"
        }, {
            "label": "回民区",
            "value": "150103",
            "center": "111.662162,40.815149"
        }, {
            "label": "玉泉区",
            "value": "150104",
            "center": "111.66543,40.799421"
        }, {
            "label": "赛罕区",
            "value": "150105",
            "center": "111.698463,40.807834"
        }, {
            "label": "土默特左旗",
            "value": "150121",
            "center": "111.133615,40.720416"
        }, {
            "label": "托克托县",
            "value": "150122",
            "center": "111.197317,40.276729"
        }, {
            "label": "和林格尔县",
            "value": "150123",
            "center": "111.824143,40.380288"
        }, {
            "label": "清水河县",
            "value": "150124",
            "center": "111.67222,39.912479"
        }, {
            "label": "武川县",
            "value": "150125",
            "center": "111.456563,41.094483"
        }]
    }, {
        "label": "包头市",
        "value": "150200",
        "center": "109.840405,40.658168",
        "children": [{
            "label": "东河区",
            "value": "150202",
            "center": "110.026895,40.587056"
        }, {
            "label": "昆都仑区",
            "value": "150203",
            "center": "109.822932,40.661345"
        }, {
            "label": "青山区",
            "value": "150204",
            "center": "109.880049,40.668558"
        }, {
            "label": "石拐区",
            "value": "150205",
            "center": "110.272565,40.672094"
        }, {
            "label": "白云鄂博矿区",
            "value": "150206",
            "center": "109.97016,41.769246"
        }, {
            "label": "九原区",
            "value": "150207",
            "center": "109.968122,40.600581"
        }, {
            "label": "土默特右旗",
            "value": "150221",
            "center": "110.526766,40.566434"
        }, {
            "label": "固阳县",
            "value": "150222",
            "center": "110.063421,41.030004"
        }, {
            "label": "达尔罕茂明安联合旗",
            "value": "150223",
            "center": "110.438452,41.702836"
        }]
    }, {
        "label": "通辽市",
        "value": "150500",
        "center": "122.263119,43.617429",
        "children": [{
            "label": "科尔沁区",
            "value": "150502",
            "center": "122.264042,43.617422"
        }, {
            "label": "科尔沁左翼中旗",
            "value": "150521",
            "center": "123.313873,44.127166"
        }, {
            "label": "科尔沁左翼后旗",
            "value": "150522",
            "center": "122.355155,42.954564"
        }, {
            "label": "开鲁县",
            "value": "150523",
            "center": "121.308797,43.602432"
        }, {
            "label": "库伦旗",
            "value": "150524",
            "center": "121.774886,42.734692"
        }, {
            "label": "奈曼旗",
            "value": "150525",
            "center": "120.662543,42.84685"
        }, {
            "label": "扎鲁特旗",
            "value": "150526",
            "center": "120.905275,44.555294"
        }, {
            "label": "霍林郭勒市",
            "value": "150581",
            "center": "119.657862,45.532361"
        }]
    }, {
        "label": "锡林郭勒盟",
        "value": "152500",
        "center": "116.090996,43.944018",
        "children": [{
            "label": "二连浩特市",
            "value": "152501",
            "center": "111.97981,43.652895"
        }, {
            "label": "锡林浩特市",
            "value": "152502",
            "center": "116.091903,43.944301"
        }, {
            "label": "阿巴嘎旗",
            "value": "152522",
            "center": "114.970618,44.022728"
        }, {
            "label": "苏尼特左旗",
            "value": "152523",
            "center": "113.653412,43.854108"
        }, {
            "label": "苏尼特右旗",
            "value": "152524",
            "center": "112.65539,42.746662"
        }, {
            "label": "东乌珠穆沁旗",
            "value": "152525",
            "center": "116.980022,45.510307"
        }, {
            "label": "西乌珠穆沁旗",
            "value": "152526",
            "center": "117.615249,44.586147"
        }, {
            "label": "太仆寺旗",
            "value": "152527",
            "center": "115.28728,41.895199"
        }, {
            "label": "镶黄旗",
            "value": "152528",
            "center": "113.843869,42.239229"
        }, {
            "label": "正镶白旗",
            "value": "152529",
            "center": "115.031423,42.286807"
        }, {
            "label": "正蓝旗",
            "value": "152530",
            "center": "116.003311,42.245895"
        }, {
            "label": "多伦县",
            "value": "152531",
            "center": "116.477288,42.197962"
        }]
    }, {
        "label": "阿拉善盟",
        "value": "152900",
        "center": "105.706422,38.844814",
        "children": [{
            "label": "阿拉善左旗",
            "value": "152921",
            "center": "105.70192,38.847241"
        }, {
            "label": "阿拉善右旗",
            "value": "152922",
            "center": "101.671984,39.21159"
        }, {
            "label": "额济纳旗",
            "value": "152923",
            "center": "101.06944,41.958813"
        }]
    }, {
        "label": "兴安盟",
        "value": "152200",
        "center": "122.070317,46.076268",
        "children": [{
            "label": "突泉县",
            "value": "152224",
            "center": "121.564856,45.380986"
        }, {
            "label": "乌兰浩特市",
            "value": "152201",
            "center": "122.068975,46.077238"
        }, {
            "label": "阿尔山市",
            "value": "152202",
            "center": "119.943656,47.177"
        }, {
            "label": "科尔沁右翼前旗",
            "value": "152221",
            "center": "121.957544,46.076497"
        }, {
            "label": "科尔沁右翼中旗",
            "value": "152222",
            "center": "121.472818,45.059645"
        }, {
            "label": "扎赉特旗",
            "value": "152223",
            "center": "122.909332,46.725136"
        }]
    }]
}, {
    "label": "广西壮族自治区",
    "value": "450000",
    "center": "108.327546,22.815478",
    "children": [{
        "label": "南宁市",
        "value": "450100",
        "center": "108.320004,22.82402",
        "children": [{
            "label": "兴宁区",
            "value": "450102",
            "center": "108.320189,22.819511"
        }, {
            "label": "青秀区",
            "value": "450103",
            "center": "108.346113,22.816614"
        }, {
            "label": "江南区",
            "value": "450105",
            "center": "108.310478,22.799593"
        }, {
            "label": "西乡塘区",
            "value": "450107",
            "center": "108.306903,22.832779"
        }, {
            "label": "良庆区",
            "value": "450108",
            "center": "108.322102,22.75909"
        }, {
            "label": "邕宁区",
            "value": "450109",
            "center": "108.484251,22.756598"
        }, {
            "label": "武鸣县",
            "value": "450122",
            "center": "108.280717,23.157163"
        }, {
            "label": "隆安县",
            "value": "450123",
            "center": "107.688661,23.174763"
        }, {
            "label": "马山县",
            "value": "450124",
            "center": "108.172903,23.711758"
        }, {
            "label": "上林县",
            "value": "450125",
            "center": "108.603937,23.431769"
        }, {
            "label": "宾阳县",
            "value": "450126",
            "center": "108.816735,23.216884"
        }, {
            "label": "横县",
            "value": "450127",
            "center": "109.270987,22.68743"
        }]
    }, {
        "label": "柳州市",
        "value": "450200",
        "center": "109.411703,24.314617",
        "children": [{
            "label": "城中区",
            "value": "450202",
            "center": "109.411749,24.312324"
        }, {
            "label": "鱼峰区",
            "value": "450203",
            "center": "109.415364,24.303848"
        }, {
            "label": "柳南区",
            "value": "450204",
            "center": "109.395936,24.287013"
        }, {
            "label": "柳北区",
            "value": "450205",
            "center": "109.406577,24.359145"
        }, {
            "label": "柳江县",
            "value": "450221",
            "center": "109.334503,24.257512"
        }, {
            "label": "柳城县",
            "value": "450222",
            "center": "109.245812,24.655121"
        }, {
            "label": "鹿寨县",
            "value": "450223",
            "center": "109.740805,24.483405"
        }, {
            "label": "融安县",
            "value": "450224",
            "center": "109.403621,25.214703"
        }, {
            "label": "融水苗族自治县",
            "value": "450225",
            "center": "109.252744,25.068812"
        }, {
            "label": "三江侗族自治县",
            "value": "450226",
            "center": "109.614846,25.78553"
        }]
    }, {
        "label": "桂林市",
        "value": "450300",
        "center": "110.299121,25.274215",
        "children": [{
            "label": "秀峰区",
            "value": "450302",
            "center": "110.264183,25.273625"
        }, {
            "label": "叠彩区",
            "value": "450303",
            "center": "110.301723,25.314"
        }, {
            "label": "象山区",
            "value": "450304",
            "center": "110.281082,25.261686"
        }, {
            "label": "七星区",
            "value": "450305",
            "center": "110.317826,25.252701"
        }, {
            "label": "雁山区",
            "value": "450311",
            "center": "110.28669,25.101934"
        }, {
            "label": "阳朔县",
            "value": "450321",
            "center": "110.496593,24.77848"
        }, {
            "label": "临桂县",
            "value": "450322",
            "center": "110.212463,25.238628"
        }, {
            "label": "灵川县",
            "value": "450323",
            "center": "110.319897,25.394781"
        }, {
            "label": "全州县",
            "value": "450324",
            "center": "111.072946,25.928387"
        }, {
            "label": "兴安县",
            "value": "450325",
            "center": "110.67167,25.611704"
        }, {
            "label": "永福县",
            "value": "450326",
            "center": "109.983076,24.979855"
        }, {
            "label": "灌阳县",
            "value": "450327",
            "center": "111.160851,25.489383"
        }, {
            "label": "龙胜各族自治县",
            "value": "450328",
            "center": "110.011238,25.797931"
        }, {
            "label": "资源县",
            "value": "450329",
            "center": "110.6527,26.042443"
        }, {
            "label": "平乐县",
            "value": "450330",
            "center": "110.643305,24.633362"
        }, {
            "label": "荔蒲县",
            "value": "450331",
            "center": "110.395104,24.488342"
        }, {
            "label": "恭城瑶族自治县",
            "value": "450332",
            "center": "110.828409,24.831682"
        }]
    }, {
        "label": "梧州市",
        "value": "450400",
        "center": "111.297604,23.474803",
        "children": [{
            "label": "万秀区",
            "value": "450403",
            "center": "111.315817,23.471318"
        }, {
            "label": "蝶山区",
            "value": "450404",
            "center": "111.299575,23.476962"
        }, {
            "label": "长洲区",
            "value": "450405",
            "center": "111.275678,23.4777"
        }, {
            "label": "苍梧县",
            "value": "450421",
            "center": "111.544008,23.845097"
        }, {
            "label": "藤县",
            "value": "450422",
            "center": "110.931826,23.373963"
        }, {
            "label": "蒙山县",
            "value": "450423",
            "center": "110.5226,24.199829"
        }, {
            "label": "岑溪市",
            "value": "450481",
            "center": "110.998114,22.918406"
        }]
    }, {
        "label": "北海市",
        "value": "450500",
        "center": "109.119254,21.473343",
        "children": [{
            "label": "海城区",
            "value": "450502",
            "center": "109.107529,21.468443"
        }, {
            "label": "银海区",
            "value": "450503",
            "center": "109.118707,21.444909"
        }, {
            "label": "铁山港区",
            "value": "450512",
            "center": "109.450573,21.5928"
        }, {
            "label": "合浦县",
            "value": "450521",
            "center": "109.200695,21.663554"
        }]
    }, {
        "label": "防城港市",
        "value": "450600",
        "center": "108.345478,21.614631",
        "children": [{
            "label": "港口区",
            "value": "450602",
            "center": "108.346281,21.614406"
        }, {
            "label": "防城区",
            "value": "450603",
            "center": "108.358426,21.764758"
        }, {
            "label": "上思县",
            "value": "450621",
            "center": "107.982139,22.151423"
        }, {
            "label": "东兴市",
            "value": "450681",
            "center": "107.97017,21.541172"
        }]
    }, {
        "label": "钦州市",
        "value": "450700",
        "center": "108.624175,21.967127",
        "children": [{
            "label": "钦南区",
            "value": "450702",
            "center": "108.626629,21.966808"
        }, {
            "label": "钦北区",
            "value": "450703",
            "center": "108.44911,22.132761"
        }, {
            "label": "灵山县",
            "value": "450721",
            "center": "109.293468,22.418041"
        }, {
            "label": "浦北县",
            "value": "450722",
            "center": "109.556341,22.268335"
        }]
    }, {
        "label": "贵港市",
        "value": "450800",
        "center": "109.602146,23.0936",
        "children": [{
            "label": "港北区",
            "value": "450802",
            "center": "109.59481,23.107677"
        }, {
            "label": "港南区",
            "value": "450803",
            "center": "109.604665,23.067516"
        }, {
            "label": "覃塘区",
            "value": "450804",
            "center": "109.415697,23.132815"
        }, {
            "label": "平南县",
            "value": "450821",
            "center": "110.397485,23.544546"
        }, {
            "label": "桂平市",
            "value": "450881",
            "center": "110.074668,23.382473"
        }]
    }, {
        "label": "玉林市",
        "value": "450900",
        "center": "110.154393,22.63136",
        "children": [{
            "label": "玉州区",
            "value": "450902",
            "center": "110.154912,22.632132"
        }, {
            "label": "容县",
            "value": "450921",
            "center": "110.552467,22.856435"
        }, {
            "label": "陆川县",
            "value": "450922",
            "center": "110.264842,22.321054"
        }, {
            "label": "博白县",
            "value": "450923",
            "center": "109.980004,22.271285"
        }, {
            "label": "兴业县",
            "value": "450924",
            "center": "109.877768,22.74187"
        }, {
            "label": "北流市",
            "value": "450981",
            "center": "110.348052,22.701648"
        }]
    }, {
        "label": "百色市",
        "value": "451000",
        "center": "106.616285,23.897742",
        "children": [{
            "label": "右江区",
            "value": "451002",
            "center": "106.615727,23.897675"
        }, {
            "label": "田阳县",
            "value": "451021",
            "center": "106.904315,23.736079"
        }, {
            "label": "田东县",
            "value": "451022",
            "center": "107.12426,23.600444"
        }, {
            "label": "平果县",
            "value": "451023",
            "center": "107.580403,23.320479"
        }, {
            "label": "德保县",
            "value": "451024",
            "center": "106.618164,23.321464"
        }, {
            "label": "靖西县",
            "value": "451025",
            "center": "106.417549,23.134766"
        }, {
            "label": "那坡县",
            "value": "451026",
            "center": "105.833553,23.400785"
        }, {
            "label": "凌云县",
            "value": "451027",
            "center": "106.56487,24.345643"
        }, {
            "label": "乐业县",
            "value": "451028",
            "center": "106.559638,24.782204"
        }, {
            "label": "田林县",
            "value": "451029",
            "center": "106.235047,24.290262"
        }, {
            "label": "西林县",
            "value": "451030",
            "center": "105.095025,24.492041"
        }, {
            "label": "隆林各族自治县",
            "value": "451031",
            "center": "105.342363,24.774318"
        }]
    }, {
        "label": "贺州市",
        "value": "451100",
        "center": "111.552056,24.414141",
        "children": [{
            "label": "八步区",
            "value": "451102",
            "center": "111.551991,24.412446"
        }, {
            "label": "平桂管理区",
            "value": "451119",
            "center": "111.492364,24.463723"
        }, {
            "label": "昭平县",
            "value": "451121",
            "center": "110.810865,24.172958"
        }, {
            "label": "钟山县",
            "value": "451122",
            "center": "111.303629,24.528566"
        }, {
            "label": "富川瑶族自治县",
            "value": "451123",
            "center": "111.277228,24.81896"
        }]
    }, {
        "label": "河池市",
        "value": "451200",
        "center": "108.062105,24.695899",
        "children": [{
            "label": "金城江区",
            "value": "451202",
            "center": "108.062131,24.695625"
        }, {
            "label": "南丹县",
            "value": "451221",
            "center": "107.546605,24.983192"
        }, {
            "label": "天峨县",
            "value": "451222",
            "center": "107.174939,24.985964"
        }, {
            "label": "凤山县",
            "value": "451223",
            "center": "107.044592,24.544561"
        }, {
            "label": "东兰县",
            "value": "451224",
            "center": "107.373696,24.509367"
        }, {
            "label": "罗城仫佬族自治县",
            "value": "451225",
            "center": "108.902453,24.779327"
        }, {
            "label": "环江毛南族自治县",
            "value": "451226",
            "center": "108.258669,24.827628"
        }, {
            "label": "巴马瑶族自治县",
            "value": "451227",
            "center": "107.253126,24.139538"
        }, {
            "label": "都安瑶族自治县",
            "value": "451228",
            "center": "108.102761,23.934964"
        }, {
            "label": "大化瑶族自治县",
            "value": "451229",
            "center": "107.9945,23.739596"
        }, {
            "label": "宜州市",
            "value": "451281",
            "center": "108.653965,24.492193"
        }]
    }, {
        "label": "来宾市",
        "value": "451300",
        "center": "109.229772,23.733766",
        "children": [{
            "label": "兴宾区",
            "value": "451302",
            "center": "109.230541,23.732926"
        }, {
            "label": "忻城县",
            "value": "451321",
            "center": "108.667361,24.064779"
        }, {
            "label": "象州县",
            "value": "451322",
            "center": "109.684555,23.959824"
        }, {
            "label": "武宣县",
            "value": "451323",
            "center": "109.66287,23.604162"
        }, {
            "label": "金秀瑶族自治县",
            "value": "451324",
            "center": "110.188556,24.134941"
        }, {
            "label": "合山市",
            "value": "451381",
            "center": "108.88858,23.81311"
        }]
    }, {
        "label": "崇左市",
        "value": "451400",
        "center": "107.353926,22.404108",
        "children": [{
            "label": "江洲区",
            "value": "451402",
            "center": "107.354443,22.40469"
        }, {
            "label": "扶绥县",
            "value": "451421",
            "center": "107.911533,22.635821"
        }, {
            "label": "宁明县",
            "value": "451422",
            "center": "107.067616,22.131353"
        }, {
            "label": "龙州县",
            "value": "451423",
            "center": "106.857502,22.343716"
        }, {
            "label": "大新县",
            "value": "451424",
            "center": "107.200803,22.833369"
        }, {
            "label": "天等县",
            "value": "451425",
            "center": "107.142441,23.082484"
        }, {
            "label": "凭祥市",
            "value": "451481",
            "center": "106.759038,22.108882"
        }]
    }]
}, {
    "label": "福建省",
    "value": "350000",
    "center": "119.295143,26.100779",
    "children": [{
        "label": "福州市",
        "value": "350100",
        "center": "119.306239,26.075302",
        "children": [{
            "label": "鼓楼区",
            "value": "350102",
            "center": "119.29929,26.082284"
        }, {
            "label": "台江区",
            "value": "350103",
            "center": "119.310156,26.058616"
        }, {
            "label": "仓山区",
            "value": "350104",
            "center": "119.320988,26.038912"
        }, {
            "label": "马尾区",
            "value": "350105",
            "center": "119.458725,25.991975"
        }, {
            "label": "晋安区",
            "value": "350111",
            "center": "119.328597,26.078837"
        }, {
            "label": "闽侯县",
            "value": "350121",
            "center": "119.145117,26.148567"
        }, {
            "label": "连江县",
            "value": "350122",
            "center": "119.538365,26.202109"
        }, {
            "label": "罗源县",
            "value": "350123",
            "center": "119.552645,26.487234"
        }, {
            "label": "闽清县",
            "value": "350124",
            "center": "118.868416,26.223793"
        }, {
            "label": "永泰县",
            "value": "350125",
            "center": "118.939089,25.864825"
        }, {
            "label": "平潭县",
            "value": "350128",
            "center": "119.791197,25.503672"
        }, {
            "label": "福清市",
            "value": "350181",
            "center": "119.376992,25.720402"
        }, {
            "label": "长乐市",
            "value": "350182",
            "center": "119.510849,25.960583"
        }]
    }, {
        "label": "厦门市",
        "value": "350200",
        "center": "118.11022,24.490474",
        "children": [{
            "label": "思明区",
            "value": "350203",
            "center": "118.087828,24.462059"
        }, {
            "label": "海沧区",
            "value": "350205",
            "center": "118.036364,24.492512"
        }, {
            "label": "湖里区",
            "value": "350206",
            "center": "118.10943,24.512764"
        }, {
            "label": "集美区",
            "value": "350211",
            "center": "118.100869,24.572874"
        }, {
            "label": "同安区",
            "value": "350212",
            "center": "118.150455,24.729333"
        }, {
            "label": "翔安区",
            "value": "350213",
            "center": "118.242811,24.637479"
        }]
    }, {
        "label": "莆田市",
        "value": "350300",
        "center": "119.007558,25.431011",
        "children": [{
            "label": "城厢区",
            "value": "350302",
            "center": "119.001028,25.433737"
        }, {
            "label": "涵江区",
            "value": "350303",
            "center": "119.119102,25.459273"
        }, {
            "label": "荔城区",
            "value": "350304",
            "center": "119.020047,25.430047"
        }, {
            "label": "秀屿区",
            "value": "350305",
            "center": "119.092607,25.316141"
        }, {
            "label": "仙游县",
            "value": "350322",
            "center": "118.694331,25.356529"
        }]
    }, {
        "label": "三明市",
        "value": "350400",
        "center": "117.635001,26.265444",
        "children": [{
            "label": "梅列区",
            "value": "350402",
            "center": "117.63687,26.269208"
        }, {
            "label": "三元区",
            "value": "350403",
            "center": "117.607418,26.234191"
        }, {
            "label": "明溪县",
            "value": "350421",
            "center": "117.201845,26.357375"
        }, {
            "label": "清流县",
            "value": "350423",
            "center": "116.815821,26.17761"
        }, {
            "label": "宁化县",
            "value": "350424",
            "center": "116.659725,26.259932"
        }, {
            "label": "大田县",
            "value": "350425",
            "center": "117.849355,25.690803"
        }, {
            "label": "尤溪县",
            "value": "350426",
            "center": "118.188577,26.169261"
        }, {
            "label": "沙县",
            "value": "350427",
            "center": "117.789095,26.397361"
        }, {
            "label": "将乐县",
            "value": "350428",
            "center": "117.473558,26.728667"
        }, {
            "label": "泰宁县",
            "value": "350429",
            "center": "117.177522,26.897995"
        }, {
            "label": "建宁县",
            "value": "350430",
            "center": "116.845832,26.831398"
        }, {
            "label": "永安市",
            "value": "350481",
            "center": "117.364447,25.974075"
        }]
    }, {
        "label": "泉州市",
        "value": "350500",
        "center": "118.589421,24.908853",
        "children": [{
            "label": "鲤城区",
            "value": "350502",
            "center": "118.588929,24.907645"
        }, {
            "label": "丰泽区",
            "value": "350503",
            "center": "118.605147,24.896041"
        }, {
            "label": "洛江区",
            "value": "350504",
            "center": "118.670312,24.941153"
        }, {
            "label": "泉港区",
            "value": "350505",
            "center": "118.912285,25.126859"
        }, {
            "label": "惠安县",
            "value": "350521",
            "center": "118.798954,25.028718"
        }, {
            "label": "安溪县",
            "value": "350524",
            "center": "118.186014,25.056824"
        }, {
            "label": "永春县",
            "value": "350525",
            "center": "118.29503,25.320721"
        }, {
            "label": "德化县",
            "value": "350526",
            "center": "118.242986,25.489004"
        }, {
            "label": "金门县",
            "value": "350527",
            "center": "118.323221,24.436417"
        }, {
            "label": "石狮市",
            "value": "350581",
            "center": "118.628402,24.731978"
        }, {
            "label": "晋江市",
            "value": "350582",
            "center": "118.577338,24.807322"
        }, {
            "label": "南安市",
            "value": "350583",
            "center": "118.387031,24.959494"
        }]
    }, {
        "label": "漳州市",
        "value": "350600",
        "center": "117.661801,24.510897",
        "children": [{
            "label": "芗城区",
            "value": "350602",
            "center": "117.656461,24.509955"
        }, {
            "label": "龙文区",
            "value": "350603",
            "center": "117.671387,24.515656"
        }, {
            "label": "云霄县",
            "value": "350622",
            "center": "117.340946,23.950486"
        }, {
            "label": "漳浦县",
            "value": "350623",
            "center": "117.614023,24.117907"
        }, {
            "label": "诏安县",
            "value": "350624",
            "center": "117.176083,23.710834"
        }, {
            "label": "长泰县",
            "value": "350625",
            "center": "117.755913,24.621475"
        }, {
            "label": "东山县",
            "value": "350626",
            "center": "117.427679,23.702845"
        }, {
            "label": "南靖县",
            "value": "350627",
            "center": "117.365462,24.516425"
        }, {
            "label": "平和县",
            "value": "350628",
            "center": "117.313549,24.366158"
        }, {
            "label": "华安县",
            "value": "350629",
            "center": "117.53631,25.001416"
        }, {
            "label": "龙海市",
            "value": "350681",
            "center": "117.817292,24.445341"
        }]
    }, {
        "label": "南平市",
        "value": "350700",
        "center": "118.178459,26.635627",
        "children": [{
            "label": "延平区",
            "value": "350702",
            "center": "118.178918,26.636079"
        }, {
            "label": "顺昌县",
            "value": "350721",
            "center": "117.80771,26.792851"
        }, {
            "label": "浦城县",
            "value": "350722",
            "center": "118.536822,27.920412"
        }, {
            "label": "光泽县",
            "value": "350723",
            "center": "117.337897,27.542803"
        }, {
            "label": "松溪县",
            "value": "350724",
            "center": "118.783491,27.525785"
        }, {
            "label": "政和县",
            "value": "350725",
            "center": "118.858661,27.365398"
        }, {
            "label": "邵武市",
            "value": "350781",
            "center": "117.491544,27.337952"
        }, {
            "label": "武夷山市",
            "value": "350782",
            "center": "118.032796,27.751733"
        }, {
            "label": "建瓯市",
            "value": "350783",
            "center": "118.321765,27.03502"
        }, {
            "label": "建阳市",
            "value": "350784",
            "center": "118.12267,27.332067"
        }]
    }, {
        "label": "龙岩市",
        "value": "350800",
        "center": "117.02978,25.091603",
        "children": [{
            "label": "新罗区",
            "value": "350802",
            "center": "117.037155,25.098312"
        }, {
            "label": "长汀县",
            "value": "350821",
            "center": "116.357581,25.833531"
        }, {
            "label": "永定县",
            "value": "350822",
            "center": "116.732091,24.723961"
        }, {
            "label": "上杭县",
            "value": "350823",
            "center": "116.420098,25.049518"
        }, {
            "label": "武平县",
            "value": "350824",
            "center": "116.100414,25.095386"
        }, {
            "label": "连城县",
            "value": "350825",
            "center": "116.754472,25.710538"
        }, {
            "label": "漳平市",
            "value": "350881",
            "center": "117.419998,25.290184"
        }]
    }, {
        "label": "宁德市",
        "value": "350900",
        "center": "119.527082,26.65924",
        "children": [{
            "label": "蕉城区",
            "value": "350902",
            "center": "119.527225,26.659253"
        }, {
            "label": "霞浦县",
            "value": "350921",
            "center": "120.005214,26.882068"
        }, {
            "label": "古田县",
            "value": "350922",
            "center": "118.743156,26.577491"
        }, {
            "label": "屏南县",
            "value": "350923",
            "center": "118.987544,26.910826"
        }, {
            "label": "寿宁县",
            "value": "350924",
            "center": "119.506733,27.457798"
        }, {
            "label": "周宁县",
            "value": "350925",
            "center": "119.338239,27.103106"
        }, {
            "label": "柘荣县",
            "value": "350926",
            "center": "119.898226,27.236163"
        }, {
            "label": "福安市",
            "value": "350981",
            "center": "119.650798,27.084246"
        }, {
            "label": "福鼎市",
            "value": "350982",
            "center": "120.219761,27.318884"
        }]
    }]
}, {
    "label": "江西省",
    "value": "360000",
    "center": "115.892151,28.676493",
    "children": [{
        "label": "南昌市",
        "value": "360100",
        "center": "115.892151,28.676493",
        "children": [{
            "label": "东湖区",
            "value": "360102",
            "center": "115.889675,28.682988"
        }, {
            "label": "西湖区",
            "value": "360103",
            "center": "115.91065,28.662901"
        }, {
            "label": "青云谱区",
            "value": "360104",
            "center": "115.907292,28.635724"
        }, {
            "label": "湾里区",
            "value": "360105",
            "center": "115.731324,28.714803"
        }, {
            "label": "青山湖区",
            "value": "360111",
            "center": "115.949044,28.689292"
        }, {
            "label": "南昌县",
            "value": "360121",
            "center": "115.942465,28.543781"
        }, {
            "label": "新建县",
            "value": "360122",
            "center": "115.820806,28.690788"
        }, {
            "label": "安义县",
            "value": "360123",
            "center": "115.553109,28.841334"
        }, {
            "label": "进贤县",
            "value": "360124",
            "center": "116.267671,28.365681"
        }]
    }, {
        "label": "景德镇市",
        "value": "360200",
        "center": "117.214664,29.29256",
        "children": [{
            "label": "昌江区",
            "value": "360202",
            "center": "117.195023,29.288465"
        }, {
            "label": "珠山区",
            "value": "360203",
            "center": "117.214814,29.292812"
        }, {
            "label": "浮梁县",
            "value": "360222",
            "center": "117.217611,29.352251"
        }, {
            "label": "乐平市",
            "value": "360281",
            "center": "117.129376,28.967361"
        }]
    }, {
        "label": "萍乡市",
        "value": "360300",
        "center": "113.852186,27.622946",
        "children": [{
            "label": "安源区",
            "value": "360302",
            "center": "113.855044,27.625826"
        }, {
            "label": "湘东区",
            "value": "360313",
            "center": "113.7456,27.639319"
        }, {
            "label": "莲花县",
            "value": "360321",
            "center": "113.955582,27.127807"
        }, {
            "label": "上栗县",
            "value": "360322",
            "center": "113.800525,27.877041"
        }, {
            "label": "芦溪县",
            "value": "360323",
            "center": "114.041206,27.633633"
        }]
    }, {
        "label": "九江市",
        "value": "360400",
        "center": "115.992811,29.712034",
        "children": [{
            "label": "庐山区",
            "value": "360402",
            "center": "115.99012,29.676175"
        }, {
            "label": "浔阳区",
            "value": "360403",
            "center": "115.995947,29.72465"
        }, {
            "label": "九江县",
            "value": "360421",
            "center": "115.911323,29.608431"
        }, {
            "label": "武宁县",
            "value": "360423",
            "center": "115.105646,29.260182"
        }, {
            "label": "修水县",
            "value": "360424",
            "center": "114.573428,29.032729"
        }, {
            "label": "永修县",
            "value": "360425",
            "center": "115.809055,29.018212"
        }, {
            "label": "德安县",
            "value": "360426",
            "center": "115.762611,29.327474"
        }, {
            "label": "星子县",
            "value": "360427",
            "center": "116.04506,29.448128"
        }, {
            "label": "都昌县",
            "value": "360428",
            "center": "116.205114,29.275105"
        }, {
            "label": "湖口县",
            "value": "360429",
            "center": "116.244313,29.7263"
        }, {
            "label": "彭泽县",
            "value": "360430",
            "center": "116.55584,29.898865"
        }, {
            "label": "瑞昌市",
            "value": "360481",
            "center": "115.669081,29.676599"
        }, {
            "label": "共青城市",
            "value": "360482",
            "center": "115.805712,29.247884"
        }]
    }, {
        "label": "新余市",
        "value": "360500",
        "center": "114.930835,27.810834",
        "children": [{
            "label": "渝水区",
            "value": "360502",
            "center": "114.923923,27.819171"
        }, {
            "label": "分宜县",
            "value": "360521",
            "center": "114.675262,27.811301"
        }]
    }, {
        "label": "鹰潭市",
        "value": "360600",
        "center": "117.033838,28.238638",
        "children": [{
            "label": "月湖区",
            "value": "360602",
            "center": "117.102475,28.267018"
        }, {
            "label": "余江县",
            "value": "360622",
            "center": "116.85926,28.198652"
        }, {
            "label": "贵溪市",
            "value": "360681",
            "center": "117.245497,28.292519"
        }]
    }, {
        "label": "赣州市",
        "value": "360700",
        "center": "114.940278,25.85097",
        "children": [{
            "label": "章贡区",
            "value": "360702",
            "center": "114.93872,25.851367"
        }, {
            "label": "赣县",
            "value": "360721",
            "center": "115.020792,25.854725"
        }, {
            "label": "信丰县",
            "value": "360722",
            "center": "114.930893,25.38023"
        }, {
            "label": "大余县",
            "value": "360723",
            "center": "114.362243,25.395937"
        }, {
            "label": "上犹县",
            "value": "360724",
            "center": "114.540537,25.794284"
        }, {
            "label": "崇义县",
            "value": "360725",
            "center": "114.307348,25.687911"
        }, {
            "label": "安远县",
            "value": "360726",
            "center": "115.392328,25.134591"
        }, {
            "label": "龙南县",
            "value": "360727",
            "center": "114.792657,24.90476"
        }, {
            "label": "定南县",
            "value": "360728",
            "center": "115.03267,24.774277"
        }, {
            "label": "全南县",
            "value": "360729",
            "center": "114.531589,24.742651"
        }, {
            "label": "宁都县",
            "value": "360730",
            "center": "116.018782,26.472054"
        }, {
            "label": "于都县",
            "value": "360731",
            "center": "115.411198,25.955033"
        }, {
            "label": "兴国县",
            "value": "360732",
            "center": "115.351896,26.330489"
        }, {
            "label": "会昌县",
            "value": "360733",
            "center": "115.791158,25.599125"
        }, {
            "label": "寻乌县",
            "value": "360734",
            "center": "115.651399,24.954136"
        }, {
            "label": "石城县",
            "value": "360735",
            "center": "116.342249,26.326582"
        }, {
            "label": "瑞金市",
            "value": "360781",
            "center": "116.034854,25.875278"
        }, {
            "label": "南康市",
            "value": "360782",
            "center": "114.756933,25.661721"
        }]
    }, {
        "label": "吉安市",
        "value": "360800",
        "center": "114.986373,27.111699",
        "children": [{
            "label": "吉州区",
            "value": "360802",
            "center": "114.987331,27.112367"
        }, {
            "label": "青原区",
            "value": "360803",
            "center": "115.016306,27.105879"
        }, {
            "label": "吉安县",
            "value": "360821",
            "center": "114.905117,27.040042"
        }, {
            "label": "吉水县",
            "value": "360822",
            "center": "115.134569,27.213445"
        }, {
            "label": "峡江县",
            "value": "360823",
            "center": "115.319331,27.580862"
        }, {
            "label": "新干县",
            "value": "360824",
            "center": "115.399294,27.755758"
        }, {
            "label": "永丰县",
            "value": "360825",
            "center": "115.435559,27.321087"
        }, {
            "label": "泰和县",
            "value": "360826",
            "center": "114.901393,26.790164"
        }, {
            "label": "遂川县",
            "value": "360827",
            "center": "114.51689,26.323705"
        }, {
            "label": "万安县",
            "value": "360828",
            "center": "114.784694,26.462085"
        }, {
            "label": "安福县",
            "value": "360829",
            "center": "114.61384,27.382746"
        }, {
            "label": "永新县",
            "value": "360830",
            "center": "114.242534,26.944721"
        }, {
            "label": "井冈山市",
            "value": "360881",
            "center": "114.284421,26.745919"
        }]
    }, {
        "label": "宜春市",
        "value": "360900",
        "center": "114.391136,27.8043",
        "children": [{
            "label": "袁州区",
            "value": "360902",
            "center": "114.387379,27.800117"
        }, {
            "label": "奉新县",
            "value": "360921",
            "center": "115.389899,28.700672"
        }, {
            "label": "万载县",
            "value": "360922",
            "center": "114.449012,28.104528"
        }, {
            "label": "上高县",
            "value": "360923",
            "center": "114.932653,28.234789"
        }, {
            "label": "宜丰县",
            "value": "360924",
            "center": "114.787381,28.388289"
        }, {
            "label": "靖安县",
            "value": "360925",
            "center": "115.361744,28.86054"
        }, {
            "label": "铜鼓县",
            "value": "360926",
            "center": "114.37014,28.520956"
        }, {
            "label": "丰城市",
            "value": "360981",
            "center": "115.786005,28.191584"
        }, {
            "label": "樟树市",
            "value": "360982",
            "center": "115.543388,28.055898"
        }, {
            "label": "高安市",
            "value": "360983",
            "center": "115.381527,28.420951"
        }]
    }, {
        "label": "抚州市",
        "value": "361000",
        "center": "116.358351,27.98385",
        "children": [{
            "label": "临川区",
            "value": "361002",
            "center": "116.361404,27.981919"
        }, {
            "label": "南城县",
            "value": "361021",
            "center": "116.63945,27.55531"
        }, {
            "label": "黎川县",
            "value": "361022",
            "center": "116.91457,27.292561"
        }, {
            "label": "南丰县",
            "value": "361023",
            "center": "116.532994,27.210132"
        }, {
            "label": "崇仁县",
            "value": "361024",
            "center": "116.059109,27.760907"
        }, {
            "label": "乐安县",
            "value": "361025",
            "center": "115.838432,27.420101"
        }, {
            "label": "宜黄县",
            "value": "361026",
            "center": "116.223023,27.546512"
        }, {
            "label": "金溪县",
            "value": "361027",
            "center": "116.778751,27.907387"
        }, {
            "label": "资溪县",
            "value": "361028",
            "center": "117.066095,27.70653"
        }, {
            "label": "东乡县",
            "value": "361029",
            "center": "116.605341,28.2325"
        }, {
            "label": "广昌县",
            "value": "361030",
            "center": "116.327291,26.838426"
        }]
    }, {
        "label": "上饶市",
        "value": "361100",
        "center": "117.971185,28.44442",
        "children": [{
            "label": "信州区",
            "value": "361102",
            "center": "117.970522,28.445378"
        }, {
            "label": "上饶县",
            "value": "361121",
            "center": "117.90612,28.453897"
        }, {
            "label": "广丰县",
            "value": "361122",
            "center": "118.189852,28.440285"
        }, {
            "label": "玉山县",
            "value": "361123",
            "center": "118.244408,28.673479"
        }, {
            "label": "铅山县",
            "value": "361124",
            "center": "117.711906,28.310892"
        }, {
            "label": "横峰县",
            "value": "361125",
            "center": "117.608247,28.415103"
        }, {
            "label": "弋阳县",
            "value": "361126",
            "center": "117.435002,28.402391"
        }, {
            "label": "余干县",
            "value": "361127",
            "center": "116.691072,28.69173"
        }, {
            "label": "鄱阳县",
            "value": "361128",
            "center": "116.673748,28.993374"
        }, {
            "label": "万年县",
            "value": "361129",
            "center": "117.07015,28.692589"
        }, {
            "label": "婺源县",
            "value": "361130",
            "center": "117.86219,29.254015"
        }, {
            "label": "德兴市",
            "value": "361181",
            "center": "117.578732,28.945034"
        }]
    }]
}, {
    "label": "山东省",
    "value": "370000",
    "center": "117.000923,36.675807",
    "children": [{
        "label": "济南市",
        "value": "370100",
        "center": "117.000923,36.675807",
        "children": [{
            "label": "历下区",
            "value": "370102",
            "center": "117.03862,36.664169"
        }, {
            "label": "市中区",
            "value": "370103",
            "center": "116.99898,36.657354"
        }, {
            "label": "槐荫区",
            "value": "370104",
            "center": "116.947921,36.668205"
        }, {
            "label": "天桥区",
            "value": "370105",
            "center": "116.996086,36.693374"
        }, {
            "label": "历城区",
            "value": "370112",
            "center": "117.063744,36.681744"
        }, {
            "label": "长清区",
            "value": "370113",
            "center": "116.74588,36.561049"
        }, {
            "label": "平阴县",
            "value": "370124",
            "center": "116.455054,36.286923"
        }, {
            "label": "济阳县",
            "value": "370125",
            "center": "117.176035,36.976772"
        }, {
            "label": "商河县",
            "value": "370126",
            "center": "117.156369,37.310544"
        }, {
            "label": "章丘市",
            "value": "370181",
            "center": "117.54069,36.71209"
        }]
    }, {
        "label": "青岛市",
        "value": "370200",
        "center": "120.355173,36.082982",
        "children": [{
            "label": "市南区",
            "value": "370202",
            "center": "120.412392,36.075651"
        }, {
            "label": "市北区",
            "value": "370203",
            "center": "120.374701,36.0876"
        }, {
            "label": "四方区",
            "value": "370205",
            "center": "120.359978,36.105823"
        }, {
            "label": "黄岛区",
            "value": "370211",
            "center": "120.198055,35.960933"
        }, {
            "label": "崂山区",
            "value": "370212",
            "center": "120.468956,36.107538"
        }, {
            "label": "李沧区",
            "value": "370213",
            "center": "120.432922,36.145519"
        }, {
            "label": "城阳区",
            "value": "370214",
            "center": "120.396256,36.307559"
        }, {
            "label": "胶州市",
            "value": "370281",
            "center": "120.033382,36.26468"
        }, {
            "label": "即墨市",
            "value": "370282",
            "center": "120.447158,36.389408"
        }, {
            "label": "平度市",
            "value": "370283",
            "center": "119.98842,36.776357"
        }, {
            "label": "胶南市",
            "value": "370284",
            "center": "119.981294,35.885664"
        }, {
            "label": "莱西市",
            "value": "370285",
            "center": "120.51769,36.889084"
        }]
    }, {
        "label": "淄博市",
        "value": "370300",
        "center": "118.047648,36.814939",
        "children": [{
            "label": "淄川区",
            "value": "370302",
            "center": "117.967696,36.647272"
        }, {
            "label": "张店区",
            "value": "370303",
            "center": "118.053521,36.807049"
        }, {
            "label": "博山区",
            "value": "370304",
            "center": "117.85823,36.497567"
        }, {
            "label": "临淄区",
            "value": "370305",
            "center": "118.306018,36.816657"
        }, {
            "label": "周村区",
            "value": "370306",
            "center": "117.851036,36.803699"
        }, {
            "label": "桓台县",
            "value": "370321",
            "center": "118.101556,36.959773"
        }, {
            "label": "高青县",
            "value": "370322",
            "center": "117.829839,37.169581"
        }, {
            "label": "沂源县",
            "value": "370323",
            "center": "118.166161,36.186282"
        }]
    }, {
        "label": "枣庄市",
        "value": "370400",
        "center": "117.557964,34.856424",
        "children": [{
            "label": "市中区",
            "value": "370402",
            "center": "117.556139,34.863554"
        }, {
            "label": "薛城区",
            "value": "370403",
            "center": "117.263164,34.795062"
        }, {
            "label": "峄城区",
            "value": "370404",
            "center": "117.590816,34.773263"
        }, {
            "label": "台儿庄区",
            "value": "370405",
            "center": "117.734414,34.56244"
        }, {
            "label": "山亭区",
            "value": "370406",
            "center": "117.461517,35.099528"
        }, {
            "label": "滕州市",
            "value": "370481",
            "center": "117.165824,35.114155"
        }]
    }, {
        "label": "东营市",
        "value": "370500",
        "center": "118.66471,37.434564",
        "children": [{
            "label": "东营区",
            "value": "370502",
            "center": "118.507543,37.461567"
        }, {
            "label": "河口区",
            "value": "370503",
            "center": "118.529613,37.886015"
        }, {
            "label": "垦利县",
            "value": "370521",
            "center": "118.551314,37.588679"
        }, {
            "label": "利津县",
            "value": "370522",
            "center": "118.248854,37.493365"
        }, {
            "label": "广饶县",
            "value": "370523",
            "center": "118.407522,37.05161"
        }]
    }, {
        "label": "烟台市",
        "value": "370600",
        "center": "121.391382,37.539297",
        "children": [{
            "label": "芝罘区",
            "value": "370602",
            "center": "121.385877,37.540925"
        }, {
            "label": "福山区",
            "value": "370611",
            "center": "121.264741,37.496875"
        }, {
            "label": "牟平区",
            "value": "370612",
            "center": "121.60151,37.388356"
        }, {
            "label": "莱山区",
            "value": "370613",
            "center": "121.448866,37.473549"
        }, {
            "label": "长岛县",
            "value": "370634",
            "center": "120.738345,37.916194"
        }, {
            "label": "龙口市",
            "value": "370681",
            "center": "120.528328,37.648446"
        }, {
            "label": "莱阳市",
            "value": "370682",
            "center": "120.711151,36.977037"
        }, {
            "label": "莱州市",
            "value": "370683",
            "center": "119.942135,37.182725"
        }, {
            "label": "蓬莱市",
            "value": "370684",
            "center": "120.762689,37.811168"
        }, {
            "label": "招远市",
            "value": "370685",
            "center": "120.403142,37.364919"
        }, {
            "label": "栖霞市",
            "value": "370686",
            "center": "120.834097,37.305854"
        }, {
            "label": "海阳市",
            "value": "370687",
            "center": "121.168392,36.780657"
        }]
    }, {
        "label": "潍坊市",
        "value": "370700",
        "center": "119.107078,36.70925",
        "children": [{
            "label": "潍城区",
            "value": "370702",
            "center": "119.024835,36.7281"
        }, {
            "label": "寒亭区",
            "value": "370703",
            "center": "119.211157,36.755623"
        }, {
            "label": "坊子区",
            "value": "370704",
            "center": "119.166485,36.654448"
        }, {
            "label": "奎文区",
            "value": "370705",
            "center": "119.132482,36.70759"
        }, {
            "label": "临朐县",
            "value": "370724",
            "center": "118.542982,36.512506"
        }, {
            "label": "昌乐县",
            "value": "370725",
            "center": "118.829992,36.706964"
        }, {
            "label": "青州市",
            "value": "370781",
            "center": "118.479654,36.684789"
        }, {
            "label": "诸城市",
            "value": "370782",
            "center": "119.410103,35.995654"
        }, {
            "label": "寿光市",
            "value": "370783",
            "center": "118.790739,36.85576"
        }, {
            "label": "安丘市",
            "value": "370784",
            "center": "119.218978,36.478493"
        }, {
            "label": "高密市",
            "value": "370785",
            "center": "119.755597,36.382594"
        }, {
            "label": "昌邑市",
            "value": "370786",
            "center": "119.403069,36.843319"
        }]
    }, {
        "label": "济宁市",
        "value": "370800",
        "center": "116.587245,35.415393",
        "children": [{
            "label": "市中区",
            "value": "370802",
            "center": "116.59505,35.406596"
        }, {
            "label": "任城区",
            "value": "370811",
            "center": "116.595261,35.414828"
        }, {
            "label": "微山县",
            "value": "370826",
            "center": "117.12861,34.809525"
        }, {
            "label": "鱼台县",
            "value": "370827",
            "center": "116.650023,34.997706"
        }, {
            "label": "金乡县",
            "value": "370828",
            "center": "116.310364,35.06977"
        }, {
            "label": "嘉祥县",
            "value": "370829",
            "center": "116.342885,35.398098"
        }, {
            "label": "汶上县",
            "value": "370830",
            "center": "116.487146,35.721746"
        }, {
            "label": "泗水县",
            "value": "370831",
            "center": "117.273605,35.653216"
        }, {
            "label": "梁山县",
            "value": "370832",
            "center": "116.08963,35.801843"
        }, {
            "label": "曲阜市",
            "value": "370881",
            "center": "116.991885,35.592788"
        }, {
            "label": "兖州市",
            "value": "370882",
            "center": "116.828996,35.556445"
        }, {
            "label": "邹城市",
            "value": "370883",
            "center": "116.96673,35.405259"
        }]
    }, {
        "label": "泰安市",
        "value": "370900",
        "center": "117.129063,36.194968",
        "children": [{
            "label": "泰山区",
            "value": "370902",
            "center": "117.129984,36.189313"
        }, {
            "label": "岱岳区",
            "value": "370911",
            "center": "117.04353,36.1841"
        }, {
            "label": "宁阳县",
            "value": "370921",
            "center": "116.799297,35.76754"
        }, {
            "label": "东平县",
            "value": "370923",
            "center": "116.461052,35.930467"
        }, {
            "label": "新泰市",
            "value": "370982",
            "center": "117.766092,35.910387"
        }, {
            "label": "肥城市",
            "value": "370983",
            "center": "116.763703,36.1856"
        }]
    }, {
        "label": "威海市",
        "value": "371000",
        "center": "122.116394,37.509691",
        "children": [{
            "label": "环翠区",
            "value": "371002",
            "center": "122.116189,37.510754"
        }, {
            "label": "文登市",
            "value": "371081",
            "center": "122.057139,37.196211"
        }, {
            "label": "荣成市",
            "value": "371082",
            "center": "122.422896,37.160134"
        }, {
            "label": "乳山市",
            "value": "371083",
            "center": "121.536346,36.919622"
        }]
    }, {
        "label": "日照市",
        "value": "371100",
        "center": "119.461208,35.428588",
        "children": [{
            "label": "东港区",
            "value": "371102",
            "center": "119.457703,35.426152"
        }, {
            "label": "岚山区",
            "value": "371103",
            "center": "119.315844,35.119794"
        }, {
            "label": "五莲县",
            "value": "371121",
            "center": "119.206745,35.751936"
        }, {
            "label": "莒县",
            "value": "371122",
            "center": "118.832859,35.588115"
        }]
    }, {
        "label": "莱芜市",
        "value": "371200",
        "center": "117.677736,36.214397",
        "children": [{
            "label": "莱城区",
            "value": "371202",
            "center": "117.678351,36.213662"
        }, {
            "label": "钢城区",
            "value": "371203",
            "center": "117.82033,36.058038"
        }]
    }, {
        "label": "临沂市",
        "value": "371300",
        "center": "118.326443,35.065282",
        "children": [{
            "label": "兰山区",
            "value": "371302",
            "center": "118.327667,35.061631"
        }, {
            "label": "罗庄区",
            "value": "371311",
            "center": "118.284795,34.997204"
        }, {
            "label": "河东区",
            "value": "371312",
            "center": "118.398296,35.085004"
        }, {
            "label": "沂南县",
            "value": "371321",
            "center": "118.455395,35.547002"
        }, {
            "label": "郯城县",
            "value": "371322",
            "center": "118.342963,34.614741"
        }, {
            "label": "沂水县",
            "value": "371323",
            "center": "118.634543,35.787029"
        }, {
            "label": "苍山县",
            "value": "371324",
            "center": "118.049968,34.855573"
        }, {
            "label": "费县",
            "value": "371325",
            "center": "117.968869,35.269174"
        }, {
            "label": "平邑县",
            "value": "371326",
            "center": "117.631884,35.511519"
        }, {
            "label": "莒南县",
            "value": "371327",
            "center": "118.838322,35.175911"
        }, {
            "label": "蒙阴县",
            "value": "371328",
            "center": "117.943271,35.712435"
        }, {
            "label": "临沭县",
            "value": "371329",
            "center": "118.648379,34.917062"
        }]
    }, {
        "label": "德州市",
        "value": "371400",
        "center": "116.307428,37.453968",
        "children": [{
            "label": "德城区",
            "value": "371402",
            "center": "116.29947,37.450804"
        }, {
            "label": "陵县",
            "value": "371421",
            "center": "116.56899,37.334653"
        }, {
            "label": "宁津县",
            "value": "371422",
            "center": "116.800306,37.652189"
        }, {
            "label": "庆云县",
            "value": "371423",
            "center": "117.385256,37.775349"
        }, {
            "label": "临邑县",
            "value": "371424",
            "center": "116.866799,37.189797"
        }, {
            "label": "齐河县",
            "value": "371425",
            "center": "116.762893,36.784158"
        }, {
            "label": "平原县",
            "value": "371426",
            "center": "116.434032,37.165323"
        }, {
            "label": "夏津县",
            "value": "371427",
            "center": "116.001726,36.948371"
        }, {
            "label": "武城县",
            "value": "371428",
            "center": "116.069302,37.213311"
        }, {
            "label": "乐陵市",
            "value": "371481",
            "center": "117.231934,37.729907"
        }, {
            "label": "禹城市",
            "value": "371482",
            "center": "116.638327,36.933812"
        }]
    }, {
        "label": "聊城市",
        "value": "371500",
        "center": "115.980367,36.456013",
        "children": [{
            "label": "东昌府区",
            "value": "371502",
            "center": "115.988349,36.434669"
        }, {
            "label": "阳谷县",
            "value": "371521",
            "center": "115.79182,36.114392"
        }, {
            "label": "莘县",
            "value": "371522",
            "center": "115.671191,36.233598"
        }, {
            "label": "茌平县",
            "value": "371523",
            "center": "116.25527,36.580688"
        }, {
            "label": "东阿县",
            "value": "371524",
            "center": "116.247579,36.334917"
        }, {
            "label": "冠县",
            "value": "371525",
            "center": "115.442739,36.484009"
        }, {
            "label": "高唐县",
            "value": "371526",
            "center": "116.23016,36.846762"
        }, {
            "label": "临清市",
            "value": "371581",
            "center": "115.704881,36.838277"
        }]
    }, {
        "label": "滨州市",
        "value": "371600",
        "center": "118.016974,37.383542",
        "children": [{
            "label": "滨城区",
            "value": "371602",
            "center": "118.020149,37.384842"
        }, {
            "label": "惠民县",
            "value": "371621",
            "center": "117.508941,37.483876"
        }, {
            "label": "阳信县",
            "value": "371622",
            "center": "117.581326,37.640492"
        }, {
            "label": "无棣县",
            "value": "371623",
            "center": "117.616325,37.740848"
        }, {
            "label": "沾化县",
            "value": "371624",
            "center": "118.129902,37.698456"
        }, {
            "label": "博兴县",
            "value": "371625",
            "center": "118.123096,37.147002"
        }, {
            "label": "邹平县",
            "value": "371626",
            "center": "117.736807,36.87803"
        }]
    }, {
        "label": "菏泽市",
        "value": "371700",
        "center": "115.469381,35.246531",
        "children": [{
            "label": "牡丹区",
            "value": "371702",
            "center": "115.470946,35.24311"
        }, {
            "label": "曹县",
            "value": "371721",
            "center": "115.549482,34.823253"
        }, {
            "label": "单县",
            "value": "371722",
            "center": "116.08262,34.790851"
        }, {
            "label": "成武县",
            "value": "371723",
            "center": "115.897349,34.947366"
        }, {
            "label": "巨野县",
            "value": "371724",
            "center": "116.089341,35.390999"
        }, {
            "label": "郓城县",
            "value": "371725",
            "center": "115.93885,35.594773"
        }, {
            "label": "鄄城县",
            "value": "371726",
            "center": "115.51434,35.560257"
        }, {
            "label": "定陶县",
            "value": "371727",
            "center": "115.569601,35.072701"
        }, {
            "label": "东明县",
            "value": "371728",
            "center": "115.098412,35.289637"
        }]
    }]
}, {
    "label": "河南省",
    "value": "410000",
    "center": "113.665412,34.757975",
    "children": [{
        "label": "郑州市",
        "value": "410100",
        "center": "113.665412,34.757975",
        "children": [{
            "label": "中原区",
            "value": "410102",
            "center": "113.611576,34.748286"
        }, {
            "label": "二七区",
            "value": "410103",
            "center": "113.645422,34.730936"
        }, {
            "label": "管城回族区",
            "value": "410104",
            "center": "113.685313,34.746453"
        }, {
            "label": "金水区",
            "value": "410105",
            "center": "113.686037,34.775838"
        }, {
            "label": "上街区",
            "value": "410106",
            "center": "113.298282,34.808689"
        }, {
            "label": "惠济区",
            "value": "410108",
            "center": "113.61836,34.828591"
        }, {
            "label": "中牟县",
            "value": "410122",
            "center": "114.022521,34.721976"
        }, {
            "label": "巩义市",
            "value": "410181",
            "center": "112.98283,34.75218"
        }, {
            "label": "荥阳市",
            "value": "410182",
            "center": "113.391523,34.789077"
        }, {
            "label": "新密市",
            "value": "410183",
            "center": "113.380616,34.537846"
        }, {
            "label": "新郑市",
            "value": "410184",
            "center": "113.73967,34.394219"
        }, {
            "label": "登封市",
            "value": "410185",
            "center": "113.037768,34.459939"
        }, {
            "label": "郑东新区",
            "value": "410186",
            "center": "113.774877,34.784505"
        }, {
            "label": "高新区",
            "value": "410187",
            "center": "113.535591,34.80135"
        }, {
            "label": "经开区",
            "value": "410188",
            "center": "113.743171,34.721838"
        }, {
            "label": "航空港区",
            "value": "410189",
            "center": "113.82952,34.534428"
        }]
    }, {
        "label": "开封市",
        "value": "410200",
        "center": "114.341447,34.797049",
        "children": [{
            "label": "龙亭区",
            "value": "410202",
            "center": "114.353348,34.799833"
        }, {
            "label": "顺河回族区",
            "value": "410203",
            "center": "114.364875,34.800459"
        }, {
            "label": "鼓楼区",
            "value": "410204",
            "center": "114.3485,34.792383"
        }, {
            "label": "禹王台区",
            "value": "410205",
            "center": "114.350246,34.779727"
        }, {
            "label": "金明区",
            "value": "410211",
            "center": "114.323606,34.818549"
        }, {
            "label": "杞县",
            "value": "410221",
            "center": "114.770472,34.554585"
        }, {
            "label": "通许县",
            "value": "410222",
            "center": "114.467734,34.477302"
        }, {
            "label": "尉氏县",
            "value": "410223",
            "center": "114.193927,34.412256"
        }, {
            "label": "开封县",
            "value": "410224",
            "center": "114.461189,34.746426"
        }, {
            "label": "兰考县",
            "value": "410225",
            "center": "114.820572,34.829899"
        }]
    }, {
        "label": "洛阳市",
        "value": "410300",
        "center": "112.434468,34.663041",
        "children": [{
            "label": "老城区",
            "value": "410302",
            "center": "112.477298,34.682945"
        }, {
            "label": "西工区",
            "value": "410303",
            "center": "112.443232,34.667847"
        }, {
            "label": "瀍河回族区",
            "value": "410304",
            "center": "112.491625,34.684738"
        }, {
            "label": "涧西区",
            "value": "410305",
            "center": "112.399243,34.654251"
        }, {
            "label": "吉利区",
            "value": "410306",
            "center": "112.584796,34.899093"
        }, {
            "label": "洛龙区",
            "value": "410311",
            "center": "112.456634,34.618557"
        }, {
            "label": "孟津县",
            "value": "410322",
            "center": "112.443892,34.826485"
        }, {
            "label": "新安县",
            "value": "410323",
            "center": "112.141403,34.728679"
        }, {
            "label": "栾川县",
            "value": "410324",
            "center": "111.618386,33.783195"
        }, {
            "label": "嵩县",
            "value": "410325",
            "center": "112.087765,34.131563"
        }, {
            "label": "汝阳县",
            "value": "410326",
            "center": "112.473789,34.15323"
        }, {
            "label": "宜阳县",
            "value": "410327",
            "center": "112.179989,34.516478"
        }, {
            "label": "洛宁县",
            "value": "410328",
            "center": "111.655399,34.387179"
        }, {
            "label": "伊川县",
            "value": "410329",
            "center": "112.429384,34.423416"
        }, {
            "label": "偃师市",
            "value": "410381",
            "center": "112.787739,34.723042"
        }, {
            "label": "高新技术开发区",
            "value": "410307",
            "center": "112.378287,34.608625"
        }]
    }, {
        "label": "平顶山市",
        "value": "410400",
        "center": "113.307718,33.735241",
        "children": [{
            "label": "新华区",
            "value": "410402",
            "center": "113.299061,33.737579"
        }, {
            "label": "卫东区",
            "value": "410403",
            "center": "113.310327,33.739285"
        }, {
            "label": "石龙区",
            "value": "410404",
            "center": "112.889885,33.901538"
        }, {
            "label": "湛河区",
            "value": "410411",
            "center": "113.320873,33.725681"
        }, {
            "label": "宝丰县",
            "value": "410421",
            "center": "113.066812,33.866359"
        }, {
            "label": "叶县",
            "value": "410422",
            "center": "113.358298,33.621252"
        }, {
            "label": "鲁山县",
            "value": "410423",
            "center": "112.906703,33.740325"
        }, {
            "label": "郏县",
            "value": "410425",
            "center": "113.220451,33.971993"
        }, {
            "label": "舞钢市",
            "value": "410481",
            "center": "113.52625,33.302082"
        }, {
            "label": "汝州市",
            "value": "410482",
            "center": "112.845336,34.167408"
        }]
    }, {
        "label": "安阳市",
        "value": "410500",
        "center": "114.352482,36.103442",
        "children": [{
            "label": "文峰区",
            "value": "410502",
            "center": "114.352562,36.098101"
        }, {
            "label": "北关区",
            "value": "410503",
            "center": "114.352646,36.10978"
        }, {
            "label": "殷都区",
            "value": "410505",
            "center": "114.300098,36.108974"
        }, {
            "label": "龙安区",
            "value": "410506",
            "center": "114.323522,36.095568"
        }, {
            "label": "安阳县",
            "value": "410522",
            "center": "114.130207,36.130585"
        }, {
            "label": "汤阴县",
            "value": "410523",
            "center": "114.362357,35.922349"
        }, {
            "label": "滑县",
            "value": "410526",
            "center": "114.524,35.574628"
        }, {
            "label": "内黄县",
            "value": "410527",
            "center": "114.904582,35.953702"
        }, {
            "label": "林州市",
            "value": "410581",
            "center": "113.823767,36.063403"
        }, {
            "label": "开发区",
            "value": "410507",
            "center": "114.366608,36.029678"
        }]
    }, {
        "label": "鹤壁市",
        "value": "410600",
        "center": "114.295444,35.748236",
        "children": [{
            "label": "鹤山区",
            "value": "410602",
            "center": "114.166551,35.936128"
        }, {
            "label": "山城区",
            "value": "410603",
            "center": "114.184202,35.896058"
        }, {
            "label": "淇滨区",
            "value": "410611",
            "center": "114.293917,35.748382"
        }, {
            "label": "浚县",
            "value": "410621",
            "center": "114.550162,35.671282"
        }, {
            "label": "淇县",
            "value": "410622",
            "center": "114.200379,35.609478"
        }]
    }, {
        "label": "新乡市",
        "value": "410700",
        "center": "113.883991,35.302616",
        "children": [{
            "label": "红旗区",
            "value": "410702",
            "center": "113.875245,35.30385"
        }, {
            "label": "卫滨区",
            "value": "410703",
            "center": "113.865663,35.301992"
        }, {
            "label": "凤泉区",
            "value": "410704",
            "center": "113.915184,35.383978"
        }, {
            "label": "牧野区",
            "value": "410711",
            "center": "113.908772,35.315039"
        }, {
            "label": "新乡县",
            "value": "410721",
            "center": "113.805205,35.190836"
        }, {
            "label": "获嘉县",
            "value": "410724",
            "center": "113.657433,35.259808"
        }, {
            "label": "原阳县",
            "value": "410725",
            "center": "113.940046,35.065587"
        }, {
            "label": "延津县",
            "value": "410726",
            "center": "114.20509,35.141889"
        }, {
            "label": "封丘县",
            "value": "410727",
            "center": "114.418882,35.041198"
        }, {
            "label": "长垣县",
            "value": "410728",
            "center": "114.668936,35.201548"
        }, {
            "label": "卫辉市",
            "value": "410781",
            "center": "114.064907,35.398494"
        }, {
            "label": "辉县市",
            "value": "410782",
            "center": "113.805468,35.462312"
        }, {
            "label": "开发区",
            "value": "410783",
            "center": "113.898705,35.282166"
        }, {
            "label": "平原新区",
            "value": "410784",
            "center": "113.747106,35.01653"
        }]
    }, {
        "label": "焦作市",
        "value": "410800",
        "center": "113.238266,35.23904",
        "children": [{
            "label": "解放区",
            "value": "410802",
            "center": "113.226126,35.241353"
        }, {
            "label": "中站区",
            "value": "410803",
            "center": "113.175485,35.236145"
        }, {
            "label": "马村区",
            "value": "410804",
            "center": "113.321703,35.265453"
        }, {
            "label": "山阳区",
            "value": "410811",
            "center": "113.26766,35.21476"
        }, {
            "label": "修武县",
            "value": "410821",
            "center": "113.447465,35.229923"
        }, {
            "label": "博爱县",
            "value": "410822",
            "center": "113.069313,35.170351"
        }, {
            "label": "武陟县",
            "value": "410823",
            "center": "113.408334,35.09885"
        }, {
            "label": "温县",
            "value": "410825",
            "center": "113.079118,34.941233"
        }, {
            "label": "沁阳市",
            "value": "410882",
            "center": "112.934538,35.08901"
        }, {
            "label": "孟州市",
            "value": "410883",
            "center": "112.78708,34.90963"
        }]
    }, {
        "label": "濮阳市",
        "value": "410900",
        "center": "115.041299,35.768234",
        "children": [{
            "label": "华龙区",
            "value": "410902",
            "center": "115.03184,35.760473"
        }, {
            "label": "清丰县",
            "value": "410922",
            "center": "115.107287,35.902413"
        }, {
            "label": "南乐县",
            "value": "410923",
            "center": "115.204336,36.075204"
        }, {
            "label": "范县",
            "value": "410926",
            "center": "115.504212,35.851977"
        }, {
            "label": "台前县",
            "value": "410927",
            "center": "115.855681,35.996474"
        }, {
            "label": "濮阳县",
            "value": "410928",
            "center": "115.023844,35.710349"
        }]
    }, {
        "label": "许昌市",
        "value": "411000",
        "center": "113.826063,34.022956",
        "children": [{
            "label": "魏都区",
            "value": "411002",
            "center": "113.828307,34.02711"
        }, {
            "label": "许昌县",
            "value": "411023",
            "center": "113.825403,34.124874"
        }, {
            "label": "鄢陵县",
            "value": "411024",
            "center": "114.188507,34.100502"
        }, {
            "label": "襄城县",
            "value": "411025",
            "center": "113.493166,33.855943"
        }, {
            "label": "禹州市",
            "value": "411081",
            "center": "113.471316,34.154403"
        }, {
            "label": "长葛市",
            "value": "411082",
            "center": "113.768912,34.219257"
        }, {
            "label": "东城区",
            "value": "411083",
            "center": "113.869813,34.013615"
        }, {
            "label": "建安区",
            "value": "411084",
            "center": "113.825403,34.124874"
        }, {
            "label": "经济开发区",
            "value": "411085",
            "center": "113.805799,34.003614"
        }]
    }, {
        "label": "漯河市",
        "value": "411100",
        "center": "114.026405,33.575855",
        "children": [{
            "label": "源汇区",
            "value": "411102",
            "center": "114.017948,33.565441"
        }, {
            "label": "郾城区",
            "value": "411103",
            "center": "114.016813,33.588897"
        }, {
            "label": "召陵区",
            "value": "411104",
            "center": "114.051686,33.567555"
        }, {
            "label": "舞阳县",
            "value": "411121",
            "center": "113.610565,33.436278"
        }, {
            "label": "临颍县",
            "value": "411122",
            "center": "113.938891,33.80609"
        }, {
            "label": "经济开发区",
            "value": "411123",
            "center": "114.049827,33.550795"
        }]
    }, {
        "label": "三门峡市",
        "value": "411200",
        "center": "111.194099,34.777338",
        "children": [{
            "label": "湖滨区",
            "value": "411202",
            "center": "111.19487,34.77812"
        }, {
            "label": "渑池县",
            "value": "411221",
            "center": "111.762992,34.763487"
        }, {
            "label": "陕县",
            "value": "411222",
            "center": "111.103563,34.720548"
        }, {
            "label": "卢氏县",
            "value": "411224",
            "center": "111.052649,34.053995"
        }, {
            "label": "义马市",
            "value": "411281",
            "center": "111.869417,34.746868"
        }, {
            "label": "灵宝市",
            "value": "411282",
            "center": "110.88577,34.521264"
        }]
    }, {
        "label": "南阳市",
        "value": "411300",
        "center": "112.540918,32.999082",
        "children": [{
            "label": "宛城区",
            "value": "411302",
            "center": "112.544591,32.994857"
        }, {
            "label": "卧龙区",
            "value": "411303",
            "center": "112.528789,32.989877"
        }, {
            "label": "南召县",
            "value": "411321",
            "center": "112.435583,33.488617"
        }, {
            "label": "方城县",
            "value": "411322",
            "center": "113.010933,33.255138"
        }, {
            "label": "西峡县",
            "value": "411323",
            "center": "111.485772,33.302981"
        }, {
            "label": "镇平县",
            "value": "411324",
            "center": "112.232722,33.036651"
        }, {
            "label": "内乡县",
            "value": "411325",
            "center": "111.843801,33.046358"
        }, {
            "label": "淅川县",
            "value": "411326",
            "center": "111.489026,33.136106"
        }, {
            "label": "社旗县",
            "value": "411327",
            "center": "112.938279,33.056126"
        }, {
            "label": "唐河县",
            "value": "411328",
            "center": "112.838492,32.687892"
        }, {
            "label": "新野县",
            "value": "411329",
            "center": "112.365624,32.524006"
        }, {
            "label": "桐柏县",
            "value": "411330",
            "center": "113.406059,32.367153"
        }, {
            "label": "邓州市",
            "value": "411381",
            "center": "112.092716,32.681642"
        }]
    }, {
        "label": "商丘市",
        "value": "411400",
        "center": "115.650497,34.437054",
        "children": [{
            "label": "梁园区",
            "value": "411402",
            "center": "115.65459,34.436553"
        }, {
            "label": "睢阳区",
            "value": "411403",
            "center": "115.653813,34.390536"
        }, {
            "label": "民权县",
            "value": "411421",
            "center": "115.148146,34.648455"
        }, {
            "label": "睢县",
            "value": "411422",
            "center": "115.070109,34.428433"
        }, {
            "label": "宁陵县",
            "value": "411423",
            "center": "115.320055,34.449299"
        }, {
            "label": "柘城县",
            "value": "411424",
            "center": "115.307433,34.075277"
        }, {
            "label": "虞城县",
            "value": "411425",
            "center": "115.863811,34.399634"
        }, {
            "label": "夏邑县",
            "value": "411426",
            "center": "116.13989,34.240894"
        }, {
            "label": "永城市",
            "value": "411481",
            "center": "116.449672,33.931318"
        }]
    }, {
        "label": "信阳市",
        "value": "411500",
        "center": "114.075031,32.123274",
        "children": [{
            "label": "浉河区",
            "value": "411502",
            "center": "114.075031,32.123274"
        }, {
            "label": "平桥区",
            "value": "411503",
            "center": "114.126027,32.098395"
        }, {
            "label": "罗山县",
            "value": "411521",
            "center": "114.533414,32.203206"
        }, {
            "label": "光山县",
            "value": "411522",
            "center": "114.903577,32.010398"
        }, {
            "label": "新县",
            "value": "411523",
            "center": "114.87705,31.63515"
        }, {
            "label": "商城县",
            "value": "411524",
            "center": "115.406297,31.799982"
        }, {
            "label": "固始县",
            "value": "411525",
            "center": "115.667328,32.183074"
        }, {
            "label": "潢川县",
            "value": "411526",
            "center": "115.050123,32.134024"
        }, {
            "label": "淮滨县",
            "value": "411527",
            "center": "115.415451,32.452639"
        }, {
            "label": "息县",
            "value": "411528",
            "center": "114.740713,32.344744"
        }]
    }, {
        "label": "周口市",
        "value": "411600",
        "center": "114.649653,33.620357",
        "children": [{
            "label": "川汇区",
            "value": "411602",
            "center": "114.652136,33.614836"
        }, {
            "label": "扶沟县",
            "value": "411621",
            "center": "114.392008,34.054061"
        }, {
            "label": "西华县",
            "value": "411622",
            "center": "114.530067,33.784378"
        }, {
            "label": "商水县",
            "value": "411623",
            "center": "114.60927,33.543845"
        }, {
            "label": "沈丘县",
            "value": "411624",
            "center": "115.078375,33.395514"
        }, {
            "label": "郸城县",
            "value": "411625",
            "center": "115.189,33.643852"
        }, {
            "label": "淮阳县",
            "value": "411626",
            "center": "114.870166,33.732547"
        }, {
            "label": "太康县",
            "value": "411627",
            "center": "114.853834,34.065312"
        }, {
            "label": "鹿邑县",
            "value": "411628",
            "center": "115.486386,33.861067"
        }, {
            "label": "项城市",
            "value": "411681",
            "center": "114.899521,33.443085"
        }]
    }, {
        "label": "驻马店市",
        "value": "411700",
        "center": "114.024736,32.980169",
        "children": [{
            "label": "驿城区",
            "value": "411702",
            "center": "114.029149,32.977559"
        }, {
            "label": "西平县",
            "value": "411721",
            "center": "114.026864,33.382315"
        }, {
            "label": "上蔡县",
            "value": "411722",
            "center": "114.266892,33.264719"
        }, {
            "label": "平舆县",
            "value": "411723",
            "center": "114.637105,32.955626"
        }, {
            "label": "正阳县",
            "value": "411724",
            "center": "114.38948,32.601826"
        }, {
            "label": "确山县",
            "value": "411725",
            "center": "114.026679,32.801538"
        }, {
            "label": "泌阳县",
            "value": "411726",
            "center": "113.32605,32.725129"
        }, {
            "label": "汝南县",
            "value": "411727",
            "center": "114.359495,33.004535"
        }, {
            "label": "遂平县",
            "value": "411728",
            "center": "114.00371,33.14698"
        }, {
            "label": "新蔡县",
            "value": "411729",
            "center": "114.975246,32.749948"
        }, {
            "label": "市辖区",
            "value": "411730",
            "center": "114.024736,32.980169"
        }]
    }, {
        "label": "济源市",
        "value": "419001",
        "center": "112.509,35.0243",
        "children": []
    }]
}, {
    "label": "湖北省",
    "value": "420000",
    "center": "114.298572,30.584355",
    "children": [{
        "label": "武汉市",
        "value": "420100",
        "center": "114.298572,30.584355",
        "children": [{
            "label": "江岸区",
            "value": "420102",
            "center": "114.30304,30.594911"
        }, {
            "label": "江汉区",
            "value": "420103",
            "center": "114.283109,30.578771"
        }, {
            "label": "硚口区",
            "value": "420104",
            "center": "114.264568,30.57061"
        }, {
            "label": "汉阳区",
            "value": "420105",
            "center": "114.265807,30.549326"
        }, {
            "label": "武昌区",
            "value": "420106",
            "center": "114.307344,30.546536"
        }, {
            "label": "青山区",
            "value": "420107",
            "center": "114.39707,30.634215"
        }, {
            "label": "洪山区",
            "value": "420111",
            "center": "114.400718,30.504259"
        }, {
            "label": "东西湖区",
            "value": "420112",
            "center": "114.142483,30.622467"
        }, {
            "label": "汉南区",
            "value": "420113",
            "center": "114.08124,30.309637"
        }, {
            "label": "蔡甸区",
            "value": "420114",
            "center": "114.029341,30.582186"
        }, {
            "label": "江夏区",
            "value": "420115",
            "center": "114.313961,30.349045"
        }, {
            "label": "黄陂区",
            "value": "420116",
            "center": "114.374025,30.874155"
        }, {
            "label": "新洲区",
            "value": "420117",
            "center": "114.802108,30.842149"
        }]
    }, {
        "label": "黄石市",
        "value": "420200",
        "center": "115.077048,30.220074",
        "children": [{
            "label": "黄石港区",
            "value": "420202",
            "center": "115.090164,30.212086"
        }, {
            "label": "西塞山区",
            "value": "420203",
            "center": "115.093354,30.205365"
        }, {
            "label": "下陆区",
            "value": "420204",
            "center": "114.975755,30.177845"
        }, {
            "label": "铁山区",
            "value": "420205",
            "center": "114.901366,30.20601"
        }, {
            "label": "阳新县",
            "value": "420222",
            "center": "115.212883,29.841572"
        }, {
            "label": "大冶市",
            "value": "420281",
            "center": "114.974842,30.098804"
        }]
    }, {
        "label": "十堰市",
        "value": "420300",
        "center": "110.787916,32.646907",
        "children": [{
            "label": "茅箭区",
            "value": "420302",
            "center": "110.813719,32.591904"
        }, {
            "label": "张湾区",
            "value": "420303",
            "center": "110.769132,32.652297"
        }, {
            "label": "郧县",
            "value": "420321",
            "center": "110.748129,32.788834"
        }, {
            "label": "郧西县",
            "value": "420322",
            "center": "110.425983,32.993182"
        }, {
            "label": "竹山县",
            "value": "420323",
            "center": "110.228747,32.224808"
        }, {
            "label": "竹溪县",
            "value": "420324",
            "center": "109.715304,32.318255"
        }, {
            "label": "房县",
            "value": "420325",
            "center": "110.733181,32.050378"
        }, {
            "label": "丹江口市",
            "value": "420381",
            "center": "111.513127,32.540157"
        }]
    }, {
        "label": "宜昌市",
        "value": "420500",
        "center": "111.290843,30.702636",
        "children": [{
            "label": "西陵区",
            "value": "420502",
            "center": "111.295468,30.702476"
        }, {
            "label": "伍家岗区",
            "value": "420503",
            "center": "111.307215,30.679053"
        }, {
            "label": "点军区",
            "value": "420504",
            "center": "111.268163,30.692322"
        }, {
            "label": "猇亭区",
            "value": "420505",
            "center": "111.427642,30.530744"
        }, {
            "label": "夷陵区",
            "value": "420506",
            "center": "111.326747,30.770199"
        }, {
            "label": "远安县",
            "value": "420525",
            "center": "111.64331,31.059626"
        }, {
            "label": "兴山县",
            "value": "420526",
            "center": "110.754499,31.34795"
        }, {
            "label": "秭归县",
            "value": "420527",
            "center": "110.976785,30.823908"
        }, {
            "label": "长阳土家族自治县",
            "value": "420528",
            "center": "111.198475,30.466534"
        }, {
            "label": "五峰土家族自治县",
            "value": "420529",
            "center": "110.674938,30.199252"
        }, {
            "label": "宜都市",
            "value": "420581",
            "center": "111.454367,30.387234"
        }, {
            "label": "当阳市",
            "value": "420582",
            "center": "111.793419,30.824492"
        }, {
            "label": "枝江市",
            "value": "420583",
            "center": "111.751799,30.425364"
        }]
    }, {
        "label": "襄阳市",
        "value": "420600",
        "center": "112.144146,32.042426",
        "children": [{
            "label": "襄城区",
            "value": "420602",
            "center": "112.150327,32.015088"
        }, {
            "label": "樊城区",
            "value": "420606",
            "center": "112.13957,32.058589"
        }, {
            "label": "襄州区",
            "value": "420607",
            "center": "112.197378,32.085517"
        }, {
            "label": "南漳县",
            "value": "420624",
            "center": "111.844424,31.77692"
        }, {
            "label": "谷城县",
            "value": "420625",
            "center": "111.640147,32.262676"
        }, {
            "label": "保康县",
            "value": "420626",
            "center": "111.262235,31.873507"
        }, {
            "label": "老河口市",
            "value": "420682",
            "center": "111.675732,32.385438"
        }, {
            "label": "枣阳市",
            "value": "420683",
            "center": "112.765268,32.123083"
        }, {
            "label": "宜城市",
            "value": "420684",
            "center": "112.261441,31.709203"
        }]
    }, {
        "label": "鄂州市",
        "value": "420700",
        "center": "114.890593,30.396536",
        "children": [{
            "label": "梁子湖区",
            "value": "420702",
            "center": "114.681967,30.098191"
        }, {
            "label": "华容区",
            "value": "420703",
            "center": "114.74148,30.534468"
        }, {
            "label": "鄂城区",
            "value": "420704",
            "center": "114.890012,30.39669"
        }]
    }, {
        "label": "荆门市",
        "value": "420800",
        "center": "112.204251,31.03542",
        "children": [{
            "label": "东宝区",
            "value": "420802",
            "center": "112.204804,31.033461"
        }, {
            "label": "掇刀区",
            "value": "420804",
            "center": "112.198413,30.980798"
        }, {
            "label": "京山县",
            "value": "420821",
            "center": "113.114595,31.022458"
        }, {
            "label": "沙洋县",
            "value": "420822",
            "center": "112.595218,30.70359"
        }, {
            "label": "钟祥市",
            "value": "420881",
            "center": "112.587267,31.165573"
        }]
    }, {
        "label": "孝感市",
        "value": "420900",
        "center": "113.926655,30.926423",
        "children": [{
            "label": "孝南区",
            "value": "420902",
            "center": "113.925849,30.925966"
        }, {
            "label": "孝昌县",
            "value": "420921",
            "center": "113.988964,31.251618"
        }, {
            "label": "大悟县",
            "value": "420922",
            "center": "114.126249,31.565483"
        }, {
            "label": "云梦县",
            "value": "420923",
            "center": "113.750616,31.021691"
        }, {
            "label": "应城市",
            "value": "420981",
            "center": "113.573842,30.939038"
        }, {
            "label": "安陆市",
            "value": "420982",
            "center": "113.690401,31.26174"
        }, {
            "label": "汉川市",
            "value": "420984",
            "center": "113.835301,30.652165"
        }]
    }, {
        "label": "荆州市",
        "value": "421000",
        "center": "112.23813,30.326857",
        "children": [{
            "label": "沙市区",
            "value": "421002",
            "center": "112.257433,30.315895"
        }, {
            "label": "荆州区",
            "value": "421003",
            "center": "112.195354,30.350674"
        }, {
            "label": "公安县",
            "value": "421022",
            "center": "112.230179,30.059065"
        }, {
            "label": "监利县",
            "value": "421023",
            "center": "112.904344,29.820079"
        }, {
            "label": "江陵县",
            "value": "421024",
            "center": "112.41735,30.033919"
        }, {
            "label": "石首市",
            "value": "421081",
            "center": "112.40887,29.716437"
        }, {
            "label": "洪湖市",
            "value": "421083",
            "center": "113.470304,29.81297"
        }, {
            "label": "松滋市",
            "value": "421087",
            "center": "111.77818,30.176037"
        }]
    }, {
        "label": "黄冈市",
        "value": "421100",
        "center": "114.879365,30.447711",
        "children": [{
            "label": "黄州区",
            "value": "421102",
            "center": "114.878934,30.447435"
        }, {
            "label": "团风县",
            "value": "421121",
            "center": "114.872029,30.63569"
        }, {
            "label": "红安县",
            "value": "421122",
            "center": "114.615095,31.284777"
        }, {
            "label": "罗田县",
            "value": "421123",
            "center": "115.398984,30.781679"
        }, {
            "label": "英山县",
            "value": "421124",
            "center": "115.67753,30.735794"
        }, {
            "label": "浠水县",
            "value": "421125",
            "center": "115.26344,30.454837"
        }, {
            "label": "蕲春县",
            "value": "421126",
            "center": "115.433964,30.234927"
        }, {
            "label": "黄梅县",
            "value": "421127",
            "center": "115.942548,30.075113"
        }, {
            "label": "麻城市",
            "value": "421181",
            "center": "115.02541,31.177906"
        }, {
            "label": "武穴市",
            "value": "421182",
            "center": "115.56242,29.849342"
        }]
    }, {
        "label": "咸宁市",
        "value": "421200",
        "center": "114.328963,29.832798",
        "children": [{
            "label": "咸安区",
            "value": "421202",
            "center": "114.333894,29.824716"
        }, {
            "label": "嘉鱼县",
            "value": "421221",
            "center": "113.921547,29.973363"
        }, {
            "label": "通城县",
            "value": "421222",
            "center": "113.814131,29.246076"
        }, {
            "label": "崇阳县",
            "value": "421223",
            "center": "114.049958,29.54101"
        }, {
            "label": "通山县",
            "value": "421224",
            "center": "114.493163,29.604455"
        }, {
            "label": "赤壁市",
            "value": "421281",
            "center": "113.88366,29.716879"
        }]
    }, {
        "label": "随州市",
        "value": "421300",
        "center": "113.37377,31.717497",
        "children": [{
            "label": "曾都区",
            "value": "421303",
            "center": "113.374519,31.717521"
        }, {
            "label": "随县",
            "value": "421321",
            "center": "113.301384,31.854246"
        }, {
            "label": "广水市",
            "value": "421381",
            "center": "113.826601,31.617731"
        }]
    }, {
        "label": "恩施土家族苗族自治州",
        "value": "422800",
        "center": "109.48699,30.283114",
        "children": [{
            "label": "恩施市",
            "value": "422801",
            "center": "109.486761,30.282406"
        }, {
            "label": "利川市",
            "value": "422802",
            "center": "108.943491,30.294247"
        }, {
            "label": "建始县",
            "value": "422822",
            "center": "109.723822,30.601632"
        }, {
            "label": "巴东县",
            "value": "422823",
            "center": "110.336665,31.041403"
        }, {
            "label": "宣恩县",
            "value": "422825",
            "center": "109.482819,29.98867"
        }, {
            "label": "咸丰县",
            "value": "422826",
            "center": "109.15041,29.678967"
        }, {
            "label": "来凤县",
            "value": "422827",
            "center": "109.408328,29.506945"
        }, {
            "label": "鹤峰县",
            "value": "422828",
            "center": "110.033699,29.887298"
        }]
    }, {
        "label": "省直辖",
        "value": "429000",
        "center": "114.351688,30.546452",
        "children": [{
            "label": "仙桃市",
            "value": "429004",
            "center": "113.207,30.3395"
        }, {
            "label": "潜江市",
            "value": "429005",
            "center": "112.626,30.6203"
        }, {
            "label": "天门市",
            "value": "429006",
            "center": "113.397,30.6596"
        }, {
            "label": "神农架林区",
            "value": "429021",
            "center": "110.763,31.677"
        }]
    }]
}, {
    "label": "海南省",
    "value": "460000",
    "center": "110.33119,20.031971",
    "children": [{
        "label": "海口市",
        "value": "460100",
        "center": "110.33119,20.031971",
        "children": [{
            "label": "秀英区",
            "value": "460105",
            "center": "110.282393,20.008145"
        }, {
            "label": "龙华区",
            "value": "460106",
            "center": "110.330373,20.031026"
        }, {
            "label": "琼山区",
            "value": "460107",
            "center": "110.354722,20.001051"
        }, {
            "label": "美兰区",
            "value": "460108",
            "center": "110.356566,20.03074"
        }]
    }, {
        "label": "三亚市",
        "value": "460200",
        "center": "109.508268,18.247872",
        "children": [{
            "label": "三亚市",
            "value": "460201",
            "center": "109.508268,18.247872"
        }]
    }, {
        "label": "三沙市",
        "value": "460300",
        "center": "112.34882,16.831039",
        "children": [{
            "label": "西沙群岛",
            "value": "460321",
            "center": "111.792944,16.204546"
        }, {
            "label": "南沙群岛",
            "value": "460322",
            "center": "116.749998,11.471888"
        }, {
            "label": "中沙群岛",
            "value": "460323",
            "center": "117.740071,15.112856"
        }]
    }, {
        "label": "省直辖",
        "value": "469000",
        "center": "110.33119,20.031971",
        "children": [{
            "label": "五指山市",
            "value": "469001",
            "center": "109.356,18.7134"
        }, {
            "label": "琼海市",
            "value": "469002",
            "center": "110.594,19.3213"
        }, {
            "label": "儋州市",
            "value": "469003",
            "center": "109.576782,19.517486"
        }, {
            "label": "文昌市",
            "value": "469005",
            "center": "110.654,19.4341"
        }, {
            "label": "万宁市",
            "value": "469006",
            "center": "110.196,18.7201"
        }, {
            "label": "东方市",
            "value": "469007",
            "center": "108.976,19.1314"
        }, {
            "label": "定安县",
            "value": "469021",
            "center": "110.469,19.5845"
        }, {
            "label": "屯昌县",
            "value": "469022",
            "center": "110.093,19.1953"
        }, {
            "label": "澄迈县",
            "value": "469023",
            "center": "109.814,19.5367"
        }, {
            "label": "临高县",
            "value": "469024",
            "center": "109.764,19.8244"
        }, {
            "label": "白沙黎族自治县",
            "value": "469025",
            "center": "109.18,19.4104"
        }, {
            "label": "昌江黎族自治县",
            "value": "469026",
            "center": "109.094,18.9052"
        }, {
            "label": "乐东黎族自治县",
            "value": "469027",
            "center": "109.15,18.6353"
        }, {
            "label": "陵水黎族自治县",
            "value": "469028",
            "center": "109.876,18.4739"
        }, {
            "label": "保亭黎族苗族自治县",
            "value": "469029",
            "center": "109.803,18.5393"
        }, {
            "label": "琼中黎族苗族自治县",
            "value": "469030",
            "center": "109.734,19.0657"
        }]
    }]
}, {
    "label": "重庆",
    "value": "500000",
    "center": "106.504962,29.533155",
    "children": [{
        "label": "重庆市",
        "value": "500100",
        "center": "106.504962,29.533155",
        "children": [{
            "label": "万州区",
            "value": "500101",
            "center": "108.51,30.5321"
        }, {
            "label": "涪陵区",
            "value": "500102",
            "center": "107.393,29.698"
        }, {
            "label": "渝中区",
            "value": "500103",
            "center": "106.574,29.5525"
        }, {
            "label": "大渡口区",
            "value": "500104",
            "center": "106.476,29.4463"
        }, {
            "label": "江北区",
            "value": "500105",
            "center": "106.551,29.5843"
        }, {
            "label": "沙坪坝区",
            "value": "500106",
            "center": "106.457,29.5802"
        }, {
            "label": "九龙坡区",
            "value": "500107",
            "center": "106.511,29.5155"
        }, {
            "label": "南岸区",
            "value": "500108",
            "center": "106.61,29.5126"
        }, {
            "label": "北碚区",
            "value": "500109",
            "center": "106.374,29.8069"
        }, {
            "label": "綦江区",
            "value": "500110",
            "center": "106.687,28.5792"
        }, {
            "label": "大足区",
            "value": "500111",
            "center": "105.734,29.7947"
        }, {
            "label": "渝北区",
            "value": "500112",
            "center": "106.579,29.7584"
        }, {
            "label": "巴南区",
            "value": "500113",
            "center": "106.741,29.4649"
        }, {
            "label": "黔江区",
            "value": "500114",
            "center": "108.797,29.5637"
        }, {
            "label": "长寿区",
            "value": "500115",
            "center": "107.166,30.1248"
        }, {
            "label": "江津区",
            "value": "500116",
            "center": "106.048,28.998"
        }, {
            "label": "合川区",
            "value": "500117",
            "center": "106.599,30.1167"
        }, {
            "label": "永川区",
            "value": "500118",
            "center": "105.685,29.3466"
        }, {
            "label": "南川区",
            "value": "500119",
            "center": "107.157,29.0056"
        }, {
            "label": "潼南县",
            "value": "500223",
            "center": "105.841818,30.189554"
        }, {
            "label": "铜梁县",
            "value": "500224",
            "center": "106.054948,29.839944"
        }, {
            "label": "荣昌县",
            "value": "500226",
            "center": "105.594061,29.403627"
        }, {
            "label": "璧山县",
            "value": "500227",
            "center": "106.231126,29.593581"
        }, {
            "label": "梁平县",
            "value": "500228",
            "center": "107.800034,30.672168"
        }, {
            "label": "城口县",
            "value": "500229",
            "center": "108.286,31.9389"
        }, {
            "label": "丰都县",
            "value": "500230",
            "center": "107.957,29.9329"
        }, {
            "label": "垫江县",
            "value": "500231",
            "center": "107.339,30.1524"
        }, {
            "label": "武隆县",
            "value": "500232",
            "center": "107.75655,29.32376"
        }, {
            "label": "忠县",
            "value": "500233",
            "center": "108.067,30.4144"
        }, {
            "label": "开县",
            "value": "500234",
            "center": "108.393135,31.160711"
        }, {
            "label": "云阳县",
            "value": "500235",
            "center": "108.944,30.7174"
        }, {
            "label": "奉节县",
            "value": "500236",
            "center": "109.548,30.7992"
        }, {
            "label": "巫山县",
            "value": "500237",
            "center": "110.085,30.8864"
        }, {
            "label": "巫溪县",
            "value": "500238",
            "center": "109.526,31.5027"
        }, {
            "label": "石柱土家族自治县",
            "value": "500240",
            "center": "108.048,29.9928"
        }, {
            "label": "秀山土家族苗族自治县",
            "value": "500241",
            "center": "109.146,28.5437"
        }, {
            "label": "酉阳土家族苗族自治县",
            "value": "500242",
            "center": "108.852,28.8207"
        }, {
            "label": "彭水苗族土家族自治县",
            "value": "500243",
            "center": "108.111,29.5056"
        }]
    }]
}, {
    "label": "四川省",
    "value": "510000",
    "center": "104.065735,30.659462",
    "children": [{
        "label": "成都市",
        "value": "510100",
        "center": "104.065735,30.659462",
        "children": [{
            "label": "锦江区",
            "value": "510104",
            "center": "104.080989,30.657689"
        }, {
            "label": "青羊区",
            "value": "510105",
            "center": "104.055731,30.667648"
        }, {
            "label": "金牛区",
            "value": "510106",
            "center": "104.043487,30.692058"
        }, {
            "label": "武侯区",
            "value": "510107",
            "center": "104.05167,30.630862"
        }, {
            "label": "成华区",
            "value": "510108",
            "center": "104.103077,30.660275"
        }, {
            "label": "龙泉驿区",
            "value": "510112",
            "center": "104.269181,30.56065"
        }, {
            "label": "青白江区",
            "value": "510113",
            "center": "104.25494,30.883438"
        }, {
            "label": "新都区",
            "value": "510114",
            "center": "104.16022,30.824223"
        }, {
            "label": "温江区",
            "value": "510115",
            "center": "103.836776,30.697996"
        }, {
            "label": "金堂县",
            "value": "510121",
            "center": "104.415604,30.858417"
        }, {
            "label": "双流县",
            "value": "510122",
            "center": "103.922706,30.573243"
        }, {
            "label": "郫县",
            "value": "510124",
            "center": "103.996773,30.7479"
        }, {
            "label": "大邑县",
            "value": "510129",
            "center": "103.522397,30.586602"
        }, {
            "label": "蒲江县",
            "value": "510131",
            "center": "103.511541,30.194359"
        }, {
            "label": "新津县",
            "value": "510132",
            "center": "103.812449,30.414284"
        }, {
            "label": "都江堰市",
            "value": "510181",
            "center": "103.627898,30.99114"
        }, {
            "label": "彭州市",
            "value": "510182",
            "center": "103.941173,30.985161"
        }, {
            "label": "邛崃市",
            "value": "510183",
            "center": "103.46143,30.413271"
        }, {
            "label": "崇州市",
            "value": "510184",
            "center": "103.671049,30.631478"
        }]
    }, {
        "label": "自贡市",
        "value": "510300",
        "center": "104.773447,29.352765",
        "children": [{
            "label": "自流井区",
            "value": "510302",
            "center": "104.778188,29.343231"
        }, {
            "label": "贡井区",
            "value": "510303",
            "center": "104.714372,29.345675"
        }, {
            "label": "大安区",
            "value": "510304",
            "center": "104.783229,29.367136"
        }, {
            "label": "沿滩区",
            "value": "510311",
            "center": "104.876417,29.272521"
        }, {
            "label": "荣县",
            "value": "510321",
            "center": "104.423932,29.454851"
        }, {
            "label": "富顺县",
            "value": "510322",
            "center": "104.984256,29.181282"
        }]
    }, {
        "label": "攀枝花市",
        "value": "510400",
        "center": "101.716007,26.580446",
        "children": [{
            "label": "东区",
            "value": "510402",
            "center": "101.715134,26.580887"
        }, {
            "label": "西区",
            "value": "510403",
            "center": "101.637969,26.596776"
        }, {
            "label": "仁和区",
            "value": "510411",
            "center": "101.737916,26.497185"
        }, {
            "label": "米易县",
            "value": "510421",
            "center": "102.109877,26.887474"
        }, {
            "label": "盐边县",
            "value": "510422",
            "center": "101.851848,26.677619"
        }]
    }, {
        "label": "泸州市",
        "value": "510500",
        "center": "105.443348,28.889138",
        "children": [{
            "label": "江阳区",
            "value": "510502",
            "center": "105.445131,28.882889"
        }, {
            "label": "纳溪区",
            "value": "510503",
            "center": "105.37721,28.77631"
        }, {
            "label": "龙马潭区",
            "value": "510504",
            "center": "105.435228,28.897572"
        }, {
            "label": "泸县",
            "value": "510521",
            "center": "105.376335,29.151288"
        }, {
            "label": "合江县",
            "value": "510522",
            "center": "105.834098,28.810325"
        }, {
            "label": "叙永县",
            "value": "510524",
            "center": "105.437775,28.167919"
        }, {
            "label": "古蔺县",
            "value": "510525",
            "center": "105.813359,28.03948"
        }]
    }, {
        "label": "德阳市",
        "value": "510600",
        "center": "104.398651,31.127991",
        "children": [{
            "label": "旌阳区",
            "value": "510603",
            "center": "104.389648,31.130428"
        }, {
            "label": "中江县",
            "value": "510623",
            "center": "104.677831,31.03681"
        }, {
            "label": "罗江县",
            "value": "510626",
            "center": "104.507126,31.303281"
        }, {
            "label": "广汉市",
            "value": "510681",
            "center": "104.281903,30.97715"
        }, {
            "label": "什邡市",
            "value": "510682",
            "center": "104.173653,31.126881"
        }, {
            "label": "绵竹市",
            "value": "510683",
            "center": "104.200162,31.343084"
        }]
    }, {
        "label": "绵阳市",
        "value": "510700",
        "center": "104.741722,31.46402",
        "children": [{
            "label": "涪城区",
            "value": "510703",
            "center": "104.740971,31.463557"
        }, {
            "label": "游仙区",
            "value": "510704",
            "center": "104.770006,31.484772"
        }, {
            "label": "三台县",
            "value": "510722",
            "center": "105.090316,31.090909"
        }, {
            "label": "盐亭县",
            "value": "510723",
            "center": "105.391991,31.22318"
        }, {
            "label": "安县",
            "value": "510724",
            "center": "104.56586,31.532293"
        }, {
            "label": "梓潼县",
            "value": "510725",
            "center": "105.16353,31.635225"
        }, {
            "label": "北川羌族自治县",
            "value": "510726",
            "center": "104.468069,31.615863"
        }, {
            "label": "平武县",
            "value": "510727",
            "center": "104.530555,32.407588"
        }, {
            "label": "江油市",
            "value": "510781",
            "center": "104.744431,31.776386"
        }]
    }, {
        "label": "广元市",
        "value": "510800",
        "center": "105.829757,32.433668",
        "children": [{
            "label": "利州区",
            "value": "510802",
            "center": "105.826194,32.432276"
        }, {
            "label": "元坝区",
            "value": "510811",
            "center": "105.964121,32.322788"
        }, {
            "label": "朝天区",
            "value": "510812",
            "center": "105.88917,32.642632"
        }, {
            "label": "旺苍县",
            "value": "510821",
            "center": "106.290426,32.22833"
        }, {
            "label": "青川县",
            "value": "510822",
            "center": "105.238847,32.585655"
        }, {
            "label": "剑阁县",
            "value": "510823",
            "center": "105.527035,32.286517"
        }, {
            "label": "苍溪县",
            "value": "510824",
            "center": "105.939706,31.732251"
        }]
    }, {
        "label": "遂宁市",
        "value": "510900",
        "center": "105.571331,30.513311",
        "children": [{
            "label": "船山区",
            "value": "510903",
            "center": "105.582215,30.502647"
        }, {
            "label": "安居区",
            "value": "510904",
            "center": "105.459383,30.346121"
        }, {
            "label": "蓬溪县",
            "value": "510921",
            "center": "105.713699,30.774883"
        }, {
            "label": "射洪县",
            "value": "510922",
            "center": "105.381849,30.868752"
        }, {
            "label": "大英县",
            "value": "510923",
            "center": "105.252187,30.581571"
        }]
    }, {
        "label": "内江市",
        "value": "511000",
        "center": "105.066138,29.58708",
        "children": [{
            "label": "市中区",
            "value": "511002",
            "center": "105.065467,29.585265"
        }, {
            "label": "东兴区",
            "value": "511011",
            "center": "105.067203,29.600107"
        }, {
            "label": "威远县",
            "value": "511024",
            "center": "104.668327,29.52686"
        }, {
            "label": "资中县",
            "value": "511025",
            "center": "104.852463,29.775295"
        }, {
            "label": "隆昌县",
            "value": "511028",
            "center": "105.287612,29.339476"
        }]
    }, {
        "label": "乐山市",
        "value": "511100",
        "center": "103.761263,29.582024",
        "children": [{
            "label": "市中区",
            "value": "511102",
            "center": "103.75539,29.588327"
        }, {
            "label": "沙湾区",
            "value": "511111",
            "center": "103.549961,29.416536"
        }, {
            "label": "五通桥区",
            "value": "511112",
            "center": "103.816837,29.406186"
        }, {
            "label": "金口河区",
            "value": "511113",
            "center": "103.077831,29.24602"
        }, {
            "label": "犍为县",
            "value": "511123",
            "center": "103.944266,29.209782"
        }, {
            "label": "井研县",
            "value": "511124",
            "center": "104.06885,29.651645"
        }, {
            "label": "夹江县",
            "value": "511126",
            "center": "103.578862,29.741019"
        }, {
            "label": "沐川县",
            "value": "511129",
            "center": "103.90211,28.956338"
        }, {
            "label": "峨边彝族自治县",
            "value": "511132",
            "center": "103.262148,29.230271"
        }, {
            "label": "马边彝族自治县",
            "value": "511133",
            "center": "103.546851,28.838933"
        }, {
            "label": "峨眉山市",
            "value": "511181",
            "center": "103.492488,29.597478"
        }]
    }, {
        "label": "南充市",
        "value": "511300",
        "center": "106.082974,30.795281",
        "children": [{
            "label": "顺庆区",
            "value": "511302",
            "center": "106.084091,30.795572"
        }, {
            "label": "高坪区",
            "value": "511303",
            "center": "106.108996,30.781809"
        }, {
            "label": "嘉陵区",
            "value": "511304",
            "center": "106.067027,30.762976"
        }, {
            "label": "南部县",
            "value": "511321",
            "center": "106.061138,31.349407"
        }, {
            "label": "营山县",
            "value": "511322",
            "center": "106.564893,31.075907"
        }, {
            "label": "蓬安县",
            "value": "511323",
            "center": "106.413488,31.027978"
        }, {
            "label": "仪陇县",
            "value": "511324",
            "center": "106.297083,31.271261"
        }, {
            "label": "西充县",
            "value": "511325",
            "center": "105.893021,30.994616"
        }, {
            "label": "阆中市",
            "value": "511381",
            "center": "105.975266,31.580466"
        }]
    }, {
        "label": "眉山市",
        "value": "511400",
        "center": "103.831788,30.048318",
        "children": [{
            "label": "东坡区",
            "value": "511402",
            "center": "103.831553,30.048128"
        }, {
            "label": "仁寿县",
            "value": "511421",
            "center": "104.147646,29.996721"
        }, {
            "label": "彭山县",
            "value": "511422",
            "center": "103.8701,30.192298"
        }, {
            "label": "洪雅县",
            "value": "511423",
            "center": "103.375006,29.904867"
        }, {
            "label": "丹棱县",
            "value": "511424",
            "center": "103.518333,30.012751"
        }, {
            "label": "青神县",
            "value": "511425",
            "center": "103.846131,29.831469"
        }]
    }, {
        "label": "宜宾市",
        "value": "511500",
        "center": "104.630825,28.760189",
        "children": [{
            "label": "翠屏区",
            "value": "511502",
            "center": "104.630231,28.760179"
        }, {
            "label": "宜宾县",
            "value": "511521",
            "center": "104.541489,28.695678"
        }, {
            "label": "南溪区",
            "value": "511522",
            "center": "104.969882,28.845626"
        }, {
            "label": "江安县",
            "value": "511523",
            "center": "105.068697,28.728102"
        }, {
            "label": "长宁县",
            "value": "511524",
            "center": "104.921116,28.577271"
        }, {
            "label": "高县",
            "value": "511525",
            "center": "104.519187,28.435676"
        }, {
            "label": "珙县",
            "value": "511526",
            "center": "104.712268,28.449041"
        }, {
            "label": "筠连县",
            "value": "511527",
            "center": "104.507848,28.162017"
        }, {
            "label": "兴文县",
            "value": "511528",
            "center": "105.236549,28.302988"
        }, {
            "label": "屏山县",
            "value": "511529",
            "center": "104.162617,28.64237"
        }]
    }, {
        "label": "广安市",
        "value": "511600",
        "center": "106.633369,30.456398",
        "children": [{
            "label": "广安区",
            "value": "511602",
            "center": "106.632907,30.456462"
        }, {
            "label": "岳池县",
            "value": "511621",
            "center": "106.444451,30.533538"
        }, {
            "label": "武胜县",
            "value": "511622",
            "center": "106.292473,30.344291"
        }, {
            "label": "邻水县",
            "value": "511623",
            "center": "106.934968,30.334323"
        }, {
            "label": "华蓥市",
            "value": "511681",
            "center": "106.777882,30.380574"
        }]
    }, {
        "label": "达州市",
        "value": "511700",
        "center": "107.502262,31.209484",
        "children": [{
            "label": "通川区",
            "value": "511702",
            "center": "107.501062,31.213522"
        }, {
            "label": "达县",
            "value": "511721",
            "center": "107.516273,31.205587"
        }, {
            "label": "宣汉县",
            "value": "511722",
            "center": "107.722254,31.355025"
        }, {
            "label": "开江县",
            "value": "511723",
            "center": "107.864135,31.085537"
        }, {
            "label": "大竹县",
            "value": "511724",
            "center": "107.20742,30.736289"
        }, {
            "label": "渠县",
            "value": "511725",
            "center": "106.970746,30.836348"
        }, {
            "label": "万源市",
            "value": "511781",
            "center": "108.037548,32.06777"
        }]
    }, {
        "label": "雅安市",
        "value": "511800",
        "center": "103.001033,29.987722",
        "children": [{
            "label": "雨城区",
            "value": "511802",
            "center": "103.003398,29.981831"
        }, {
            "label": "名山县",
            "value": "511821",
            "center": "103.112214,30.084718"
        }, {
            "label": "荥经县",
            "value": "511822",
            "center": "102.844674,29.795529"
        }, {
            "label": "汉源县",
            "value": "511823",
            "center": "102.677145,29.349915"
        }, {
            "label": "石棉县",
            "value": "511824",
            "center": "102.35962,29.234063"
        }, {
            "label": "天全县",
            "value": "511825",
            "center": "102.763462,30.059955"
        }, {
            "label": "芦山县",
            "value": "511826",
            "center": "102.924016,30.152907"
        }, {
            "label": "宝兴县",
            "value": "511827",
            "center": "102.813377,30.369026"
        }]
    }, {
        "label": "巴中市",
        "value": "511900",
        "center": "106.753669,31.858809",
        "children": [{
            "label": "巴州区",
            "value": "511902",
            "center": "106.753671,31.858366"
        }, {
            "label": "通江县",
            "value": "511921",
            "center": "107.247621,31.91212"
        }, {
            "label": "南江县",
            "value": "511922",
            "center": "106.843418,32.353164"
        }, {
            "label": "平昌县",
            "value": "511923",
            "center": "107.101937,31.562814"
        }]
    }, {
        "label": "资阳市",
        "value": "512000",
        "center": "104.641917,30.122211",
        "children": [{
            "label": "雁江区",
            "value": "512002",
            "center": "104.642338,30.121686"
        }, {
            "label": "安岳县",
            "value": "512021",
            "center": "105.336764,30.099206"
        }, {
            "label": "乐至县",
            "value": "512022",
            "center": "105.031142,30.275619"
        }, {
            "label": "简阳市",
            "value": "512081",
            "center": "104.546773,30.410754"
        }]
    }, {
        "label": "阿坝藏族羌族自治州",
        "value": "513200",
        "center": "102.221374,31.899792",
        "children": [{
            "label": "汶川县",
            "value": "513221",
            "center": "103.580675,31.47463"
        }, {
            "label": "理县",
            "value": "513222",
            "center": "103.165486,31.436764"
        }, {
            "label": "茂县",
            "value": "513223",
            "center": "103.850684,31.680407"
        }, {
            "label": "松潘县",
            "value": "513224",
            "center": "103.599177,32.63838"
        }, {
            "label": "九寨沟县",
            "value": "513225",
            "center": "104.236344,33.262097"
        }, {
            "label": "金川县",
            "value": "513226",
            "center": "102.064647,31.476356"
        }, {
            "label": "小金县",
            "value": "513227",
            "center": "102.363193,30.999016"
        }, {
            "label": "黑水县",
            "value": "513228",
            "center": "102.990805,32.061721"
        }, {
            "label": "马尔康县",
            "value": "513229",
            "center": "102.206504,31.905813"
        }, {
            "label": "壤塘县",
            "value": "513230",
            "center": "100.979136,32.264887"
        }, {
            "label": "阿坝县",
            "value": "513231",
            "center": "101.700985,32.904223"
        }, {
            "label": "若尔盖县",
            "value": "513232",
            "center": "102.963726,33.575934"
        }, {
            "label": "红原县",
            "value": "513233",
            "center": "102.544906,32.793902"
        }]
    }, {
        "label": "甘孜藏族自治州",
        "value": "513300",
        "center": "101.963815,30.050663",
        "children": [{
            "label": "康定县",
            "value": "513321",
            "center": "101.957146,29.998436"
        }, {
            "label": "泸定县",
            "value": "513322",
            "center": "102.233225,29.912482"
        }, {
            "label": "丹巴县",
            "value": "513323",
            "center": "101.886125,30.877083"
        }, {
            "label": "九龙县",
            "value": "513324",
            "center": "101.506942,29.001975"
        }, {
            "label": "雅江县",
            "value": "513325",
            "center": "101.015735,30.03225"
        }, {
            "label": "道孚县",
            "value": "513326",
            "center": "101.123327,30.978767"
        }, {
            "label": "炉霍县",
            "value": "513327",
            "center": "100.679495,31.392674"
        }, {
            "label": "甘孜县",
            "value": "513328",
            "center": "99.991753,31.61975"
        }, {
            "label": "新龙县",
            "value": "513329",
            "center": "100.312094,30.93896"
        }, {
            "label": "德格县",
            "value": "513330",
            "center": "98.57999,31.806729"
        }, {
            "label": "白玉县",
            "value": "513331",
            "center": "98.824343,31.208805"
        }, {
            "label": "石渠县",
            "value": "513332",
            "center": "98.100887,32.975302"
        }, {
            "label": "色达县",
            "value": "513333",
            "center": "100.331657,32.268777"
        }, {
            "label": "理塘县",
            "value": "513334",
            "center": "100.269862,29.991807"
        }, {
            "label": "巴塘县",
            "value": "513335",
            "center": "99.109037,30.005723"
        }, {
            "label": "乡城县",
            "value": "513336",
            "center": "99.799943,28.930855"
        }, {
            "label": "稻城县",
            "value": "513337",
            "center": "100.296689,29.037544"
        }, {
            "label": "得荣县",
            "value": "513338",
            "center": "99.288036,28.71134"
        }]
    }, {
        "label": "凉山彝族自治州",
        "value": "513400",
        "center": "102.258746,27.886762",
        "children": [{
            "label": "西昌市",
            "value": "513401",
            "center": "102.258758,27.885786"
        }, {
            "label": "木里藏族自治县",
            "value": "513422",
            "center": "101.280184,27.926859"
        }, {
            "label": "盐源县",
            "value": "513423",
            "center": "101.508909,27.423415"
        }, {
            "label": "德昌县",
            "value": "513424",
            "center": "102.178845,27.403827"
        }, {
            "label": "会理县",
            "value": "513425",
            "center": "102.249548,26.658702"
        }, {
            "label": "会东县",
            "value": "513426",
            "center": "102.578985,26.630713"
        }, {
            "label": "宁南县",
            "value": "513427",
            "center": "102.757374,27.065205"
        }, {
            "label": "普格县",
            "value": "513428",
            "center": "102.541082,27.376828"
        }, {
            "label": "布拖县",
            "value": "513429",
            "center": "102.808801,27.709062"
        }, {
            "label": "金阳县",
            "value": "513430",
            "center": "103.248704,27.695916"
        }, {
            "label": "昭觉县",
            "value": "513431",
            "center": "102.843991,28.010554"
        }, {
            "label": "喜德县",
            "value": "513432",
            "center": "102.412342,28.305486"
        }, {
            "label": "冕宁县",
            "value": "513433",
            "center": "102.170046,28.550844"
        }, {
            "label": "越西县",
            "value": "513434",
            "center": "102.508875,28.639632"
        }, {
            "label": "甘洛县",
            "value": "513435",
            "center": "102.775924,28.977094"
        }, {
            "label": "美姑县",
            "value": "513436",
            "center": "103.132007,28.327946"
        }, {
            "label": "雷波县",
            "value": "513437",
            "center": "103.571584,28.262946"
        }]
    }]
}, {
    "label": "贵州省",
    "value": "520000",
    "center": "106.713478,26.578343",
    "children": [{
        "label": "贵阳市",
        "value": "520100",
        "center": "106.713478,26.578343",
        "children": [{
            "label": "南明区",
            "value": "520102",
            "center": "106.715963,26.573743"
        }, {
            "label": "云岩区",
            "value": "520103",
            "center": "106.713397,26.58301"
        }, {
            "label": "花溪区",
            "value": "520111",
            "center": "106.670791,26.410464"
        }, {
            "label": "乌当区",
            "value": "520112",
            "center": "106.762123,26.630928"
        }, {
            "label": "白云区",
            "value": "520113",
            "center": "106.633037,26.676849"
        }, {
            "label": "小河区",
            "value": "520114",
            "center": "106.70024,26.51234"
        }, {
            "label": "开阳县",
            "value": "520121",
            "center": "106.969438,27.056793"
        }, {
            "label": "息烽县",
            "value": "520122",
            "center": "106.737693,27.092665"
        }, {
            "label": "修文县",
            "value": "520123",
            "center": "106.599218,26.840672"
        }, {
            "label": "清镇市",
            "value": "520181",
            "center": "106.470278,26.551289"
        }]
    }, {
        "label": "六盘水市",
        "value": "520200",
        "center": "104.846743,26.584643",
        "children": [{
            "label": "钟山区",
            "value": "520201",
            "center": "104.846244,26.584805"
        }, {
            "label": "六枝特区",
            "value": "520203",
            "center": "105.474235,26.210662"
        }, {
            "label": "水城县",
            "value": "520221",
            "center": "104.95685,26.540478"
        }, {
            "label": "盘县",
            "value": "520222",
            "center": "104.471535,25.710002"
        }]
    }, {
        "label": "遵义市",
        "value": "520300",
        "center": "106.937265,27.706626",
        "children": [{
            "label": "红花岗区",
            "value": "520302",
            "center": "106.943784,27.694395"
        }, {
            "label": "汇川区",
            "value": "520303",
            "center": "106.937265,27.706626"
        }, {
            "label": "遵义县",
            "value": "520321",
            "center": "106.829571,27.536292"
        }, {
            "label": "桐梓县",
            "value": "520322",
            "center": "106.826591,28.131559"
        }, {
            "label": "绥阳县",
            "value": "520323",
            "center": "107.191024,27.951342"
        }, {
            "label": "正安县",
            "value": "520324",
            "center": "107.441872,28.550337"
        }, {
            "label": "道真仡佬族苗族自治县",
            "value": "520325",
            "center": "107.605342,28.880088"
        }, {
            "label": "务川仡佬族苗族自治县",
            "value": "520326",
            "center": "107.887857,28.521567"
        }, {
            "label": "凤冈县",
            "value": "520327",
            "center": "107.722021,27.960858"
        }, {
            "label": "湄潭县",
            "value": "520328",
            "center": "107.485723,27.765839"
        }, {
            "label": "余庆县",
            "value": "520329",
            "center": "107.892566,27.221552"
        }, {
            "label": "习水县",
            "value": "520330",
            "center": "106.200954,28.327826"
        }, {
            "label": "赤水市",
            "value": "520381",
            "center": "105.698116,28.587057"
        }, {
            "label": "仁怀市",
            "value": "520382",
            "center": "106.412476,27.803377"
        }]
    }, {
        "label": "安顺市",
        "value": "520400",
        "center": "105.932188,26.245544",
        "children": [{
            "label": "西秀区",
            "value": "520402",
            "center": "105.946169,26.248323"
        }, {
            "label": "平坝县",
            "value": "520421",
            "center": "106.259942,26.40608"
        }, {
            "label": "普定县",
            "value": "520422",
            "center": "105.745609,26.305794"
        }, {
            "label": "镇宁布依族苗族自治县",
            "value": "520423",
            "center": "105.768656,26.056096"
        }, {
            "label": "关岭布依族苗族自治县",
            "value": "520424",
            "center": "105.618454,25.944248"
        }, {
            "label": "紫云苗族布依族自治县",
            "value": "520425",
            "center": "106.084515,25.751567"
        }]
    }, {
        "label": "毕节市",
        "value": "520500",
        "center": "105.28501,27.301693",
        "children": [{
            "label": "七星关区",
            "value": "520501",
            "center": "105.305138,27.298494"
        }, {
            "label": "大方县",
            "value": "520522",
            "center": "106.038299,27.024923"
        }, {
            "label": "黔西县",
            "value": "520523",
            "center": "106.222103,27.459693"
        }, {
            "label": "金沙县",
            "value": "520524",
            "center": "105.768997,26.668497"
        }, {
            "label": "织金县",
            "value": "520525",
            "center": "105.375322,26.769875"
        }, {
            "label": "纳雍县",
            "value": "520526",
            "center": "104.286523,26.859099"
        }, {
            "label": "威宁彝族回族苗族自治县",
            "value": "520527",
            "center": "104.726438,27.119243"
        }, {
            "label": "赫章县",
            "value": "520528",
            "center": "104.726438,27.119243"
        }]
    }, {
        "label": "铜仁市",
        "value": "520600",
        "center": "109.191555,27.718346",
        "children": [{
            "label": "碧江区",
            "value": "520601",
            "center": "109.181122,27.690653"
        }, {
            "label": "万山区",
            "value": "520602",
            "center": "109.192117,27.718745"
        }, {
            "label": "江口县",
            "value": "520622",
            "center": "108.917882,27.238024"
        }, {
            "label": "玉屏侗族自治县",
            "value": "520623",
            "center": "108.229854,27.519386"
        }, {
            "label": "石阡县",
            "value": "520624",
            "center": "108.255827,27.941331"
        }, {
            "label": "思南县",
            "value": "520625",
            "center": "108.405517,27.997976"
        }, {
            "label": "印江土家族苗族自治县",
            "value": "520626",
            "center": "108.117317,28.26094"
        }, {
            "label": "德江县",
            "value": "520627",
            "center": "108.495746,28.560487"
        }, {
            "label": "沿河土家族自治县",
            "value": "520628",
            "center": "109.202627,28.165419"
        }, {
            "label": "松桃苗族自治县",
            "value": "520629",
            "center": "109.202627,28.165419"
        }]
    }, {
        "label": "黔西南布依族苗族自治州",
        "value": "522300",
        "center": "104.897971,25.08812",
        "children": [{
            "label": "兴义市",
            "value": "522301",
            "center": "104.897982,25.088599"
        }, {
            "label": "兴仁县",
            "value": "522322",
            "center": "105.192778,25.431378"
        }, {
            "label": "普安县",
            "value": "522323",
            "center": "104.955347,25.786404"
        }, {
            "label": "晴隆县",
            "value": "522324",
            "center": "105.218773,25.832881"
        }, {
            "label": "贞丰县",
            "value": "522325",
            "center": "105.650133,25.385752"
        }, {
            "label": "望谟县",
            "value": "522326",
            "center": "106.091563,25.166667"
        }, {
            "label": "册亨县",
            "value": "522327",
            "center": "105.81241,24.983338"
        }, {
            "label": "安龙县",
            "value": "522328",
            "center": "105.471498,25.108959"
        }]
    }, {
        "label": "黔东南苗族侗族自治州",
        "value": "522600",
        "center": "107.977488,26.583352",
        "children": [{
            "label": "凯里市",
            "value": "522601",
            "center": "107.977541,26.582964"
        }, {
            "label": "黄平县",
            "value": "522622",
            "center": "107.901337,26.896973"
        }, {
            "label": "施秉县",
            "value": "522623",
            "center": "108.12678,27.034657"
        }, {
            "label": "三穗县",
            "value": "522624",
            "center": "108.681121,26.959884"
        }, {
            "label": "镇远县",
            "value": "522625",
            "center": "108.423656,27.050233"
        }, {
            "label": "岑巩县",
            "value": "522626",
            "center": "108.816459,27.173244"
        }, {
            "label": "天柱县",
            "value": "522627",
            "center": "109.212798,26.909684"
        }, {
            "label": "锦屏县",
            "value": "522628",
            "center": "109.20252,26.680625"
        }, {
            "label": "剑河县",
            "value": "522629",
            "center": "108.440499,26.727349"
        }, {
            "label": "台江县",
            "value": "522630",
            "center": "108.314637,26.669138"
        }, {
            "label": "黎平县",
            "value": "522631",
            "center": "109.136504,26.230636"
        }, {
            "label": "榕江县",
            "value": "522632",
            "center": "108.521026,25.931085"
        }, {
            "label": "从江县",
            "value": "522633",
            "center": "108.912648,25.747058"
        }, {
            "label": "雷山县",
            "value": "522634",
            "center": "108.079613,26.381027"
        }, {
            "label": "麻江县",
            "value": "522635",
            "center": "107.593172,26.494803"
        }, {
            "label": "丹寨县",
            "value": "522636",
            "center": "107.794808,26.199497"
        }]
    }, {
        "label": "黔南布依族苗族自治州",
        "value": "522700",
        "center": "107.517156,26.258219",
        "children": [{
            "label": "都匀市",
            "value": "522701",
            "center": "107.517021,26.258205"
        }, {
            "label": "福泉市",
            "value": "522702",
            "center": "107.513508,26.702508"
        }, {
            "label": "荔波县",
            "value": "522722",
            "center": "107.8838,25.412239"
        }, {
            "label": "贵定县",
            "value": "522723",
            "center": "107.233588,26.580807"
        }, {
            "label": "瓮安县",
            "value": "522725",
            "center": "107.478417,27.066339"
        }, {
            "label": "独山县",
            "value": "522726",
            "center": "107.542757,25.826283"
        }, {
            "label": "平塘县",
            "value": "522727",
            "center": "107.32405,25.831803"
        }, {
            "label": "罗甸县",
            "value": "522728",
            "center": "106.750006,25.429894"
        }, {
            "label": "长顺县",
            "value": "522729",
            "center": "106.447376,26.022116"
        }, {
            "label": "龙里县",
            "value": "522730",
            "center": "106.977733,26.448809"
        }, {
            "label": "惠水县",
            "value": "522731",
            "center": "106.657848,26.128637"
        }, {
            "label": "三都水族自治县",
            "value": "522732",
            "center": "107.87747,25.985183"
        }]
    }]
}, {
    "label": "云南省",
    "value": "530000",
    "center": "102.712251,25.040609",
    "children": [{
        "label": "昆明市",
        "value": "530100",
        "center": "102.712251,25.040609",
        "children": [{
            "label": "五华区",
            "value": "530102",
            "center": "102.704412,25.042165"
        }, {
            "label": "盘龙区",
            "value": "530103",
            "center": "102.729044,25.070239"
        }, {
            "label": "官渡区",
            "value": "530111",
            "center": "102.723437,25.021211"
        }, {
            "label": "西山区",
            "value": "530112",
            "center": "102.705904,25.02436"
        }, {
            "label": "东川区",
            "value": "530113",
            "center": "103.182,26.08349"
        }, {
            "label": "呈贡区",
            "value": "530121",
            "center": "102.821468,24.885532"
        }, {
            "label": "晋宁县",
            "value": "530122",
            "center": "102.594987,24.666944"
        }, {
            "label": "富民县",
            "value": "530124",
            "center": "102.497888,25.219667"
        }, {
            "label": "宜良县",
            "value": "530125",
            "center": "103.145989,24.918215"
        }, {
            "label": "石林彝族自治县",
            "value": "530126",
            "center": "103.271962,24.754545"
        }, {
            "label": "嵩明县",
            "value": "530127",
            "center": "103.038777,25.335087"
        }, {
            "label": "禄劝彝族苗族自治县",
            "value": "530128",
            "center": "102.46905,25.556533"
        }, {
            "label": "寻甸回族彝族自治县",
            "value": "530129",
            "center": "103.257588,25.559474"
        }, {
            "label": "安宁市",
            "value": "530181",
            "center": "102.485544,24.921785"
        }]
    }, {
        "label": "曲靖市",
        "value": "530300",
        "center": "103.797851,25.501557",
        "children": [{
            "label": "麒麟区",
            "value": "530302",
            "center": "103.798054,25.501269"
        }, {
            "label": "马龙县",
            "value": "530321",
            "center": "103.578755,25.429451"
        }, {
            "label": "陆良县",
            "value": "530322",
            "center": "103.655233,25.022878"
        }, {
            "label": "师宗县",
            "value": "530323",
            "center": "103.993808,24.825681"
        }, {
            "label": "罗平县",
            "value": "530324",
            "center": "104.309263,24.885708"
        }, {
            "label": "富源县",
            "value": "530325",
            "center": "104.25692,25.67064"
        }, {
            "label": "会泽县",
            "value": "530326",
            "center": "103.300041,26.412861"
        }, {
            "label": "沾益县",
            "value": "530328",
            "center": "103.819262,25.600878"
        }, {
            "label": "宣威市",
            "value": "530381",
            "center": "104.09554,26.227777"
        }]
    }, {
        "label": "玉溪市",
        "value": "530400",
        "center": "102.543907,24.350461",
        "children": [{
            "label": "红塔区",
            "value": "530402",
            "center": "102.543468,24.350753"
        }, {
            "label": "江川县",
            "value": "530421",
            "center": "102.749839,24.291006"
        }, {
            "label": "澄江县",
            "value": "530422",
            "center": "102.916652,24.669679"
        }, {
            "label": "通海县",
            "value": "530423",
            "center": "102.760039,24.112205"
        }, {
            "label": "华宁县",
            "value": "530424",
            "center": "102.928982,24.189807"
        }, {
            "label": "易门县",
            "value": "530425",
            "center": "102.16211,24.669598"
        }, {
            "label": "峨山彝族自治县",
            "value": "530426",
            "center": "102.404358,24.173256"
        }, {
            "label": "新平彝族傣族自治县",
            "value": "530427",
            "center": "101.990903,24.0664"
        }, {
            "label": "元江哈尼族彝族傣族自治县",
            "value": "530428",
            "center": "101.999658,23.597618"
        }]
    }, {
        "label": "保山市",
        "value": "530500",
        "center": "99.167133,25.111802",
        "children": [{
            "label": "隆阳区",
            "value": "530502",
            "center": "99.165825,25.112144"
        }, {
            "label": "施甸县",
            "value": "530521",
            "center": "99.183758,24.730847"
        }, {
            "label": "腾冲县",
            "value": "530522",
            "center": "98.497291,25.01757"
        }, {
            "label": "龙陵县",
            "value": "530523",
            "center": "98.693567,24.591912"
        }, {
            "label": "昌宁县",
            "value": "530524",
            "center": "99.612344,24.823662"
        }]
    }, {
        "label": "昭通市",
        "value": "530600",
        "center": "103.717216,27.336999",
        "children": [{
            "label": "昭阳区",
            "value": "530602",
            "center": "103.717267,27.336636"
        }, {
            "label": "鲁甸县",
            "value": "530621",
            "center": "103.549333,27.191637"
        }, {
            "label": "巧家县",
            "value": "530622",
            "center": "102.929284,26.9117"
        }, {
            "label": "盐津县",
            "value": "530623",
            "center": "104.23506,28.106923"
        }, {
            "label": "大关县",
            "value": "530624",
            "center": "103.891608,27.747114"
        }, {
            "label": "永善县",
            "value": "530625",
            "center": "103.63732,28.231526"
        }, {
            "label": "绥江县",
            "value": "530626",
            "center": "103.961095,28.599953"
        }, {
            "label": "镇雄县",
            "value": "530627",
            "center": "104.873055,27.436267"
        }, {
            "label": "彝良县",
            "value": "530628",
            "center": "104.048492,27.627425"
        }, {
            "label": "威信县",
            "value": "530629",
            "center": "105.04869,27.843381"
        }, {
            "label": "水富县",
            "value": "530630",
            "center": "104.415376,28.629688"
        }]
    }, {
        "label": "丽江市",
        "value": "530700",
        "center": "100.233026,26.872108",
        "children": [{
            "label": "古城区",
            "value": "530702",
            "center": "100.234412,26.872229"
        }, {
            "label": "玉龙纳西族自治县",
            "value": "530721",
            "center": "100.238312,26.830593"
        }, {
            "label": "永胜县",
            "value": "530722",
            "center": "100.750901,26.685623"
        }, {
            "label": "华坪县",
            "value": "530723",
            "center": "101.267796,26.628834"
        }, {
            "label": "宁蒗彝族自治县",
            "value": "530724",
            "center": "100.852427,27.281109"
        }]
    }, {
        "label": "普洱市",
        "value": "530800",
        "center": "100.972344,22.777321",
        "children": [{
            "label": "思茅区",
            "value": "530802",
            "center": "100.973227,22.776595"
        }, {
            "label": "宁洱哈尼族彝族自治县",
            "value": "530821",
            "center": "101.04524,23.062507"
        }, {
            "label": "墨江哈尼族自治县",
            "value": "530822",
            "center": "101.687606,23.428165"
        }, {
            "label": "景东彝族自治县",
            "value": "530823",
            "center": "100.840011,24.448523"
        }, {
            "label": "景谷傣族彝族自治县",
            "value": "530824",
            "center": "100.701425,23.500278"
        }, {
            "label": "镇沅彝族哈尼族拉祜族自治县",
            "value": "530825",
            "center": "101.108512,24.005712"
        }, {
            "label": "江城哈尼族彝族自治县",
            "value": "530826",
            "center": "101.859144,22.58336"
        }, {
            "label": "孟连傣族拉祜族佤族自治县",
            "value": "530827",
            "center": "99.585406,22.325924"
        }, {
            "label": "澜沧拉祜族自治县",
            "value": "530828",
            "center": "99.931201,22.553083"
        }, {
            "label": "西盟佤族自治县",
            "value": "530829",
            "center": "99.594372,22.644423"
        }]
    }, {
        "label": "临沧市",
        "value": "530900",
        "center": "100.08697,23.886567",
        "children": [{
            "label": "临翔区",
            "value": "530902",
            "center": "100.086486,23.886562"
        }, {
            "label": "凤庆县",
            "value": "530921",
            "center": "99.91871,24.592738"
        }, {
            "label": "云县",
            "value": "530922",
            "center": "100.125637,24.439026"
        }, {
            "label": "永德县",
            "value": "530923",
            "center": "99.253679,24.028159"
        }, {
            "label": "镇康县",
            "value": "530924",
            "center": "98.82743,23.761415"
        }, {
            "label": "双江拉祜族佤族布朗族傣族自治县",
            "value": "530925",
            "center": "99.824419,23.477476"
        }, {
            "label": "耿马傣族佤族自治县",
            "value": "530926",
            "center": "99.402495,23.534579"
        }, {
            "label": "沧源佤族自治县",
            "value": "530927",
            "center": "99.2474,23.146887"
        }]
    }, {
        "label": "楚雄彝族自治州",
        "value": "532300",
        "center": "101.546046,25.041988",
        "children": [{
            "label": "楚雄市",
            "value": "532301",
            "center": "101.546145,25.040912"
        }, {
            "label": "双柏县",
            "value": "532322",
            "center": "101.63824,24.685094"
        }, {
            "label": "牟定县",
            "value": "532323",
            "center": "101.543044,25.312111"
        }, {
            "label": "南华县",
            "value": "532324",
            "center": "101.274991,25.192408"
        }, {
            "label": "姚安县",
            "value": "532325",
            "center": "101.238399,25.505403"
        }, {
            "label": "大姚县",
            "value": "532326",
            "center": "101.323602,25.722348"
        }, {
            "label": "永仁县",
            "value": "532327",
            "center": "101.671175,26.056316"
        }, {
            "label": "元谋县",
            "value": "532328",
            "center": "101.870837,25.703313"
        }, {
            "label": "武定县",
            "value": "532329",
            "center": "102.406785,25.5301"
        }, {
            "label": "禄丰县",
            "value": "532331",
            "center": "102.075694,25.14327"
        }]
    }, {
        "label": "红河哈尼族彝族自治州",
        "value": "532500",
        "center": "103.384182,23.366775",
        "children": [{
            "label": "个旧市",
            "value": "532501",
            "center": "103.154752,23.360383"
        }, {
            "label": "开远市",
            "value": "532502",
            "center": "103.258679,23.713832"
        }, {
            "label": "蒙自市",
            "value": "532503",
            "center": "103.385005,23.366843"
        }, {
            "label": "屏边苗族自治县",
            "value": "532523",
            "center": "103.687229,22.987013"
        }, {
            "label": "建水县",
            "value": "532524",
            "center": "102.820493,23.618387"
        }, {
            "label": "石屏县",
            "value": "532525",
            "center": "102.484469,23.712569"
        }, {
            "label": "弥勒县",
            "value": "532526",
            "center": "103.414874,24.411912"
        }, {
            "label": "泸西县",
            "value": "532527",
            "center": "103.759622,24.532368"
        }, {
            "label": "元阳县",
            "value": "532528",
            "center": "102.837056,23.219773"
        }, {
            "label": "红河县",
            "value": "532529",
            "center": "102.42121,23.369191"
        }, {
            "label": "金平苗族瑶族傣族自治县",
            "value": "532530",
            "center": "103.228359,22.779982"
        }, {
            "label": "绿春县",
            "value": "532531",
            "center": "102.39286,22.99352"
        }, {
            "label": "河口瑶族自治县",
            "value": "532532",
            "center": "103.961593,22.507563"
        }]
    }, {
        "label": "文山壮族苗族自治州",
        "value": "532600",
        "center": "104.24401,23.36951",
        "children": [{
            "label": "文山市",
            "value": "532621",
            "center": "104.244277,23.369216"
        }, {
            "label": "砚山县",
            "value": "532622",
            "center": "104.343989,23.612301"
        }, {
            "label": "西畴县",
            "value": "532623",
            "center": "104.675711,23.437439"
        }, {
            "label": "麻栗坡县",
            "value": "532624",
            "center": "104.701899,23.124202"
        }, {
            "label": "马关县",
            "value": "532625",
            "center": "104.398619,23.011723"
        }, {
            "label": "丘北县",
            "value": "532626",
            "center": "104.194366,24.040982"
        }, {
            "label": "广南县",
            "value": "532627",
            "center": "105.056684,24.050272"
        }, {
            "label": "富宁县",
            "value": "532628",
            "center": "105.62856,23.626494"
        }]
    }, {
        "label": "西双版纳傣族自治州",
        "value": "532800",
        "center": "100.797941,22.001724",
        "children": [{
            "label": "景洪市",
            "value": "532801",
            "center": "100.797947,22.002087"
        }, {
            "label": "勐海县",
            "value": "532822",
            "center": "100.448288,21.955866"
        }, {
            "label": "勐腊县",
            "value": "532823",
            "center": "101.567051,21.479449"
        }]
    }, {
        "label": "大理白族自治州",
        "value": "532900",
        "center": "100.225668,25.589449",
        "children": [{
            "label": "大理市",
            "value": "532901",
            "center": "100.241369,25.593067"
        }, {
            "label": "漾濞彝族自治县",
            "value": "532922",
            "center": "99.95797,25.669543"
        }, {
            "label": "祥云县",
            "value": "532923",
            "center": "100.554025,25.477072"
        }, {
            "label": "宾川县",
            "value": "532924",
            "center": "100.578957,25.825904"
        }, {
            "label": "弥渡县",
            "value": "532925",
            "center": "100.490669,25.342594"
        }, {
            "label": "南涧彝族自治县",
            "value": "532926",
            "center": "100.518683,25.041279"
        }, {
            "label": "巍山彝族回族自治县",
            "value": "532927",
            "center": "100.30793,25.230909"
        }, {
            "label": "永平县",
            "value": "532928",
            "center": "99.533536,25.461281"
        }, {
            "label": "云龙县",
            "value": "532929",
            "center": "99.369402,25.884955"
        }, {
            "label": "洱源县",
            "value": "532930",
            "center": "99.951708,26.111184"
        }, {
            "label": "剑川县",
            "value": "532931",
            "center": "99.905887,26.530066"
        }, {
            "label": "鹤庆县",
            "value": "532932",
            "center": "100.173375,26.55839"
        }]
    }, {
        "label": "德宏傣族景颇族自治州",
        "value": "533100",
        "center": "98.578363,24.436694",
        "children": [{
            "label": "瑞丽市",
            "value": "533102",
            "center": "97.855883,24.010734"
        }, {
            "label": "芒市",
            "value": "533103",
            "center": "98.577608,24.436699"
        }, {
            "label": "梁河县",
            "value": "533122",
            "center": "98.298196,24.80742"
        }, {
            "label": "盈江县",
            "value": "533123",
            "center": "97.93393,24.709541"
        }, {
            "label": "陇川县",
            "value": "533124",
            "center": "97.794441,24.184065"
        }]
    }, {
        "label": "怒江傈僳族自治州",
        "value": "533300",
        "center": "98.854304,25.850949",
        "children": [{
            "label": "泸水县",
            "value": "533321",
            "center": "98.86003,25.814439"
        }, {
            "label": "福贡县",
            "value": "533323",
            "center": "98.867413,26.902738"
        }, {
            "label": "贡山独龙族怒族自治县",
            "value": "533324",
            "center": "98.666141,27.738054"
        }, {
            "label": "兰坪白族普米族自治县",
            "value": "533325",
            "center": "99.421378,26.453839"
        }]
    }, {
        "label": "迪庆藏族自治州",
        "value": "533400",
        "center": "99.706463,27.826853",
        "children": [{
            "label": "香格里拉县",
            "value": "533421",
            "center": "99.7108,27.836605"
        }, {
            "label": "德钦县",
            "value": "533422",
            "center": "98.91506,28.483272"
        }, {
            "label": "维西傈僳族自治县",
            "value": "533423",
            "center": "99.286355,27.180948"
        }]
    }]
}, {
    "label": "西藏自治区",
    "value": "540000",
    "center": "91.117525,29.647535",
    "children": [{
        "label": "拉萨市",
        "value": "540100",
        "center": "91.132212,29.660361",
        "children": [{
            "label": "城关区",
            "value": "540102",
            "center": "91.132911,29.659472"
        }, {
            "label": "林周县",
            "value": "540121",
            "center": "91.261842,29.895754"
        }, {
            "label": "当雄县",
            "value": "540122",
            "center": "91.103551,30.474819"
        }, {
            "label": "尼木县",
            "value": "540123",
            "center": "90.165545,29.431346"
        }, {
            "label": "曲水县",
            "value": "540124",
            "center": "90.738051,29.349895"
        }, {
            "label": "堆龙德庆县",
            "value": "540125",
            "center": "91.002823,29.647347"
        }, {
            "label": "达孜县",
            "value": "540126",
            "center": "91.350976,29.670314"
        }, {
            "label": "墨竹工卡县",
            "value": "540127",
            "center": "91.731158,29.834657"
        }]
    }, {
        "label": "昌都地区",
        "value": "542100",
        "center": "97.18131,31.140161",
        "children": [{
            "label": "昌都县",
            "value": "542121",
            "center": "97.18039,31.138311"
        }, {
            "label": "江达县",
            "value": "542122",
            "center": "98.21843,31.499202"
        }, {
            "label": "贡觉县",
            "value": "542123",
            "center": "98.27097,30.860099"
        }, {
            "label": "类乌齐县",
            "value": "542124",
            "center": "96.600246,31.211601"
        }, {
            "label": "丁青县",
            "value": "542125",
            "center": "95.595761,31.412405"
        }, {
            "label": "察雅县",
            "value": "542126",
            "center": "97.568752,30.653943"
        }, {
            "label": "八宿县",
            "value": "542127",
            "center": "96.917836,30.053209"
        }, {
            "label": "左贡县",
            "value": "542128",
            "center": "97.841022,29.671069"
        }, {
            "label": "芒康县",
            "value": "542129",
            "center": "98.593113,29.679908"
        }, {
            "label": "洛隆县",
            "value": "542132",
            "center": "95.824567,30.741571"
        }, {
            "label": "边坝县",
            "value": "542133",
            "center": "94.7078,30.933652"
        }]
    }, {
        "label": "山南地区",
        "value": "542200",
        "center": "91.773134,29.237137",
        "children": [{
            "label": "乃东县",
            "value": "542221",
            "center": "91.761539,29.224904"
        }, {
            "label": "扎囊县",
            "value": "542222",
            "center": "91.33725,29.245114"
        }, {
            "label": "贡嘎县",
            "value": "542223",
            "center": "90.98414,29.289455"
        }, {
            "label": "桑日县",
            "value": "542224",
            "center": "92.015818,29.259189"
        }, {
            "label": "琼结县",
            "value": "542225",
            "center": "91.683881,29.024625"
        }, {
            "label": "曲松县",
            "value": "542226",
            "center": "92.203739,29.062826"
        }, {
            "label": "措美县",
            "value": "542227",
            "center": "91.433509,28.438202"
        }, {
            "label": "洛扎县",
            "value": "542228",
            "center": "90.859992,28.385713"
        }, {
            "label": "加查县",
            "value": "542229",
            "center": "92.593993,29.14029"
        }, {
            "label": "隆子县",
            "value": "542231",
            "center": "93.075149,28.626294"
        }, {
            "label": "错那县",
            "value": "542232",
            "center": "91.767413,28.249827"
        }, {
            "label": "浪卡子县",
            "value": "542233",
            "center": "90.397977,28.968031"
        }]
    }, {
        "label": "日喀则地区",
        "value": "542300",
        "center": "88.885817,29.26681",
        "children": [{
            "label": "日喀则市",
            "value": "542301",
            "center": "88.885148,29.267519"
        }, {
            "label": "南木林县",
            "value": "542322",
            "center": "89.099243,29.682331"
        }, {
            "label": "江孜县",
            "value": "542323",
            "center": "89.605574,28.911659"
        }, {
            "label": "定日县",
            "value": "542324",
            "center": "87.12612,28.658743"
        }, {
            "label": "萨迦县",
            "value": "542325",
            "center": "88.021674,28.899664"
        }, {
            "label": "拉孜县",
            "value": "542326",
            "center": "87.637041,29.08166"
        }, {
            "label": "昂仁县",
            "value": "542327",
            "center": "87.236051,29.294802"
        }, {
            "label": "谢通门县",
            "value": "542328",
            "center": "88.26162,29.432641"
        }, {
            "label": "白朗县",
            "value": "542329",
            "center": "89.261977,29.107688"
        }, {
            "label": "仁布县",
            "value": "542330",
            "center": "89.841984,29.230933"
        }, {
            "label": "康马县",
            "value": "542331",
            "center": "89.681663,28.555627"
        }, {
            "label": "定结县",
            "value": "542332",
            "center": "87.765872,28.364159"
        }, {
            "label": "仲巴县",
            "value": "542333",
            "center": "84.03153,29.770279"
        }, {
            "label": "亚东县",
            "value": "542334",
            "center": "88.907094,27.484806"
        }, {
            "label": "吉隆县",
            "value": "542335",
            "center": "85.297535,28.852394"
        }, {
            "label": "聂拉木县",
            "value": "542336",
            "center": "85.982237,28.155186"
        }, {
            "label": "萨嘎县",
            "value": "542337",
            "center": "85.232941,29.328818"
        }, {
            "label": "岗巴县",
            "value": "542338",
            "center": "88.520031,28.274601"
        }]
    }, {
        "label": "那曲地区",
        "value": "542400",
        "center": "92.051746,31.478148",
        "children": [{
            "label": "那曲县",
            "value": "542421",
            "center": "92.067501,31.461218"
        }, {
            "label": "嘉黎县",
            "value": "542422",
            "center": "93.232528,30.640815"
        }, {
            "label": "比如县",
            "value": "542423",
            "center": "93.679639,31.48025"
        }, {
            "label": "聂荣县",
            "value": "542424",
            "center": "92.303346,32.107772"
        }, {
            "label": "安多县",
            "value": "542425",
            "center": "91.68233,32.265176"
        }, {
            "label": "申扎县",
            "value": "542426",
            "center": "88.709853,30.930505"
        }, {
            "label": "索县",
            "value": "542427",
            "center": "93.785631,31.886918"
        }, {
            "label": "班戈县",
            "value": "542428",
            "center": "90.009957,31.392411"
        }, {
            "label": "巴青县",
            "value": "542429",
            "center": "94.053463,31.918563"
        }, {
            "label": "尼玛县",
            "value": "542430",
            "center": "87.236772,31.784701"
        }]
    }, {
        "label": "阿里地区",
        "value": "542500",
        "center": "80.105498,32.503187",
        "children": [{
            "label": "普兰县",
            "value": "542521",
            "center": "81.177588,30.291896"
        }, {
            "label": "札达县",
            "value": "542522",
            "center": "79.803191,31.478587"
        }, {
            "label": "噶尔县",
            "value": "542523",
            "center": "80.105005,32.503373"
        }, {
            "label": "日土县",
            "value": "542524",
            "center": "79.731937,33.382454"
        }, {
            "label": "革吉县",
            "value": "542525",
            "center": "81.142896,32.389192"
        }, {
            "label": "改则县",
            "value": "542526",
            "center": "84.062384,32.302076"
        }, {
            "label": "措勤县",
            "value": "542527",
            "center": "85.159254,31.016774"
        }]
    }, {
        "label": "林芝地区",
        "value": "542600",
        "center": "94.36149,29.649128",
        "children": [{
            "label": "林芝县",
            "value": "542621",
            "center": "94.363688,29.648335"
        }, {
            "label": "工布江达县",
            "value": "542622",
            "center": "93.246077,29.88528"
        }, {
            "label": "米林县",
            "value": "542623",
            "center": "94.711861,29.438272"
        }, {
            "label": "墨脱县",
            "value": "542624",
            "center": "95.332241,29.325734"
        }, {
            "label": "波密县",
            "value": "542625",
            "center": "95.768158,29.858766"
        }, {
            "label": "察隅县",
            "value": "542626",
            "center": "97.465014,28.660234"
        }, {
            "label": "朗县",
            "value": "542627",
            "center": "93.074702,29.046337"
        }]
    }]
}, {
    "label": "陕西省",
    "value": "610000",
    "center": "108.948024,34.263161",
    "children": [{
        "label": "西安市",
        "value": "610100",
        "center": "108.948024,34.263161",
        "children": [{
            "label": "新城区",
            "value": "610102",
            "center": "108.959903,34.26927"
        }, {
            "label": "碑林区",
            "value": "610103",
            "center": "108.946994,34.251061"
        }, {
            "label": "莲湖区",
            "value": "610104",
            "center": "108.933194,34.2656"
        }, {
            "label": "灞桥区",
            "value": "610111",
            "center": "109.067261,34.267453"
        }, {
            "label": "未央区",
            "value": "610112",
            "center": "108.946022,34.30823"
        }, {
            "label": "雁塔区",
            "value": "610113",
            "center": "108.926593,34.213389"
        }, {
            "label": "阎良区",
            "value": "610114",
            "center": "109.22802,34.662141"
        }, {
            "label": "临潼区",
            "value": "610115",
            "center": "109.213986,34.372065"
        }, {
            "label": "长安区",
            "value": "610116",
            "center": "108.941579,34.157097"
        }, {
            "label": "蓝田县",
            "value": "610122",
            "center": "109.317634,34.156189"
        }, {
            "label": "周至县",
            "value": "610124",
            "center": "108.216465,34.161532"
        }, {
            "label": "户县",
            "value": "610125",
            "center": "108.677376,34.103865"
        }, {
            "label": "高陵县",
            "value": "610126",
            "center": "109.088896,34.535065"
        }]
    }, {
        "label": "铜川市",
        "value": "610200",
        "center": "108.979608,34.916582",
        "children": [{
            "label": "王益区",
            "value": "610202",
            "center": "109.075862,35.069098"
        }, {
            "label": "印台区",
            "value": "610203",
            "center": "109.100814,35.111927"
        }, {
            "label": "耀州区",
            "value": "610204",
            "center": "108.962538,34.910206"
        }, {
            "label": "宜君县",
            "value": "610222",
            "center": "109.118278,35.398766"
        }]
    }, {
        "label": "宝鸡市",
        "value": "610300",
        "center": "107.14487,34.369315",
        "children": [{
            "label": "渭滨区",
            "value": "610302",
            "center": "107.144467,34.371008"
        }, {
            "label": "金台区",
            "value": "610303",
            "center": "107.149943,34.375192"
        }, {
            "label": "陈仓区",
            "value": "610304",
            "center": "107.383645,34.352747"
        }, {
            "label": "凤翔县",
            "value": "610322",
            "center": "107.400577,34.521668"
        }, {
            "label": "岐山县",
            "value": "610323",
            "center": "107.624464,34.44296"
        }, {
            "label": "扶风县",
            "value": "610324",
            "center": "107.891419,34.375497"
        }, {
            "label": "眉县",
            "value": "610326",
            "center": "107.752371,34.272137"
        }, {
            "label": "陇县",
            "value": "610327",
            "center": "106.857066,34.893262"
        }, {
            "label": "千阳县",
            "value": "610328",
            "center": "107.132987,34.642584"
        }, {
            "label": "麟游县",
            "value": "610329",
            "center": "107.796608,34.677714"
        }, {
            "label": "凤县",
            "value": "610330",
            "center": "106.525212,33.912464"
        }, {
            "label": "太白县",
            "value": "610331",
            "center": "107.316533,34.059215"
        }]
    }, {
        "label": "咸阳市",
        "value": "610400",
        "center": "108.705117,34.333439",
        "children": [{
            "label": "秦都区",
            "value": "610402",
            "center": "108.698636,34.329801"
        }, {
            "label": "杨陵区",
            "value": "610403",
            "center": "108.086348,34.27135"
        }, {
            "label": "渭城区",
            "value": "610404",
            "center": "108.730957,34.336847"
        }, {
            "label": "三原县",
            "value": "610422",
            "center": "108.943481,34.613996"
        }, {
            "label": "泾阳县",
            "value": "610423",
            "center": "108.83784,34.528493"
        }, {
            "label": "乾县",
            "value": "610424",
            "center": "108.247406,34.527261"
        }, {
            "label": "礼泉县",
            "value": "610425",
            "center": "108.428317,34.482583"
        }, {
            "label": "永寿县",
            "value": "610426",
            "center": "108.143129,34.692619"
        }, {
            "label": "彬县",
            "value": "610427",
            "center": "108.083674,35.034233"
        }, {
            "label": "长武县",
            "value": "610428",
            "center": "107.795835,35.206122"
        }, {
            "label": "旬邑县",
            "value": "610429",
            "center": "108.337231,35.112234"
        }, {
            "label": "淳化县",
            "value": "610430",
            "center": "108.581173,34.79797"
        }, {
            "label": "武功县",
            "value": "610431",
            "center": "108.212857,34.259732"
        }, {
            "label": "兴平市",
            "value": "610481",
            "center": "108.488493,34.297134"
        }]
    }, {
        "label": "渭南市",
        "value": "610500",
        "center": "109.502882,34.499381",
        "children": [{
            "label": "临渭区",
            "value": "610502",
            "center": "109.503299,34.501271"
        }, {
            "label": "华县",
            "value": "610521",
            "center": "109.776026,34.496578"
        }, {
            "label": "潼关县",
            "value": "610522",
            "center": "110.24726,34.544515"
        }, {
            "label": "大荔县",
            "value": "610523",
            "center": "109.943123,34.795011"
        }, {
            "label": "合阳县",
            "value": "610524",
            "center": "110.147979,35.237098"
        }, {
            "label": "澄城县",
            "value": "610525",
            "center": "109.937609,35.184"
        }, {
            "label": "蒲城县",
            "value": "610526",
            "center": "109.589653,34.956034"
        }, {
            "label": "白水县",
            "value": "610527",
            "center": "109.594309,35.177291"
        }, {
            "label": "富平县",
            "value": "610528",
            "center": "109.187174,34.746679"
        }, {
            "label": "韩城市",
            "value": "610581",
            "center": "110.452391,35.475238"
        }, {
            "label": "华阴市",
            "value": "610582",
            "center": "110.08952,34.565359"
        }]
    }, {
        "label": "延安市",
        "value": "610600",
        "center": "109.49081,36.596537",
        "children": [{
            "label": "宝塔区",
            "value": "610602",
            "center": "109.49069,36.596291"
        }, {
            "label": "延长县",
            "value": "610621",
            "center": "110.012961,36.578306"
        }, {
            "label": "延川县",
            "value": "610622",
            "center": "110.190314,36.882066"
        }, {
            "label": "子长县",
            "value": "610623",
            "center": "109.675968,37.14207"
        }, {
            "label": "安塞县",
            "value": "610624",
            "center": "109.325341,36.86441"
        }, {
            "label": "志丹县",
            "value": "610625",
            "center": "108.768898,36.823031"
        }, {
            "label": "吴起县",
            "value": "610626",
            "center": "108.176976,36.924852"
        }, {
            "label": "甘泉县",
            "value": "610627",
            "center": "109.34961,36.277729"
        }, {
            "label": "富县",
            "value": "610628",
            "center": "109.384136,35.996495"
        }, {
            "label": "洛川县",
            "value": "610629",
            "center": "109.435712,35.762133"
        }, {
            "label": "宜川县",
            "value": "610630",
            "center": "110.175537,36.050391"
        }, {
            "label": "黄龙县",
            "value": "610631",
            "center": "109.83502,35.583276"
        }, {
            "label": "黄陵县",
            "value": "610632",
            "center": "109.262469,35.580165"
        }]
    }, {
        "label": "汉中市",
        "value": "610700",
        "center": "107.028621,33.077668",
        "children": [{
            "label": "汉台区",
            "value": "610702",
            "center": "107.028233,33.077674"
        }, {
            "label": "南郑县",
            "value": "610721",
            "center": "106.942393,33.003341"
        }, {
            "label": "城固县",
            "value": "610722",
            "center": "107.329887,33.153098"
        }, {
            "label": "洋县",
            "value": "610723",
            "center": "107.549962,33.223283"
        }, {
            "label": "西乡县",
            "value": "610724",
            "center": "107.765858,32.987961"
        }, {
            "label": "勉县",
            "value": "610725",
            "center": "106.680175,33.155618"
        }, {
            "label": "宁强县",
            "value": "610726",
            "center": "106.25739,32.830806"
        }, {
            "label": "略阳县",
            "value": "610727",
            "center": "106.153899,33.329638"
        }, {
            "label": "镇巴县",
            "value": "610728",
            "center": "107.89531,32.535854"
        }, {
            "label": "留坝县",
            "value": "610729",
            "center": "106.924377,33.61334"
        }, {
            "label": "佛坪县",
            "value": "610730",
            "center": "107.988582,33.520745"
        }]
    }, {
        "label": "榆林市",
        "value": "610800",
        "center": "109.741193,38.290162",
        "children": [{
            "label": "榆阳区",
            "value": "610802",
            "center": "109.74791,38.299267"
        }, {
            "label": "神木县",
            "value": "610821",
            "center": "110.498868,38.842498"
        }, {
            "label": "府谷县",
            "value": "610822",
            "center": "111.069645,39.029243"
        }, {
            "label": "横山县",
            "value": "610823",
            "center": "109.292596,37.964048"
        }, {
            "label": "靖边县",
            "value": "610824",
            "center": "108.80567,37.596084"
        }, {
            "label": "定边县",
            "value": "610825",
            "center": "107.601284,37.59523"
        }, {
            "label": "绥德县",
            "value": "610826",
            "center": "110.265377,37.507701"
        }, {
            "label": "米脂县",
            "value": "610827",
            "center": "110.178683,37.759081"
        }, {
            "label": "佳县",
            "value": "610828",
            "center": "110.493367,38.021597"
        }, {
            "label": "吴堡县",
            "value": "610829",
            "center": "110.739315,37.451925"
        }, {
            "label": "清涧县",
            "value": "610830",
            "center": "110.12146,37.087702"
        }, {
            "label": "子洲县",
            "value": "610831",
            "center": "110.03457,37.611573"
        }]
    }, {
        "label": "安康市",
        "value": "610900",
        "center": "109.029273,32.6903",
        "children": [{
            "label": "汉滨区",
            "value": "610902",
            "center": "109.029098,32.690817"
        }, {
            "label": "汉阴县",
            "value": "610921",
            "center": "108.510946,32.891121"
        }, {
            "label": "石泉县",
            "value": "610922",
            "center": "108.250512,33.038512"
        }, {
            "label": "宁陕县",
            "value": "610923",
            "center": "108.313714,33.312184"
        }, {
            "label": "紫阳县",
            "value": "610924",
            "center": "108.537788,32.520176"
        }, {
            "label": "岚皋县",
            "value": "610925",
            "center": "108.900663,32.31069"
        }, {
            "label": "平利县",
            "value": "610926",
            "center": "109.361865,32.387933"
        }, {
            "label": "镇坪县",
            "value": "610927",
            "center": "109.526437,31.883395"
        }, {
            "label": "旬阳县",
            "value": "610928",
            "center": "109.368149,32.833567"
        }, {
            "label": "白河县",
            "value": "610929",
            "center": "110.114186,32.809484"
        }]
    }, {
        "label": "商洛市",
        "value": "611000",
        "center": "109.939776,33.868319",
        "children": [{
            "label": "商州区",
            "value": "611002",
            "center": "109.937685,33.869208"
        }, {
            "label": "洛南县",
            "value": "611021",
            "center": "110.145716,34.088502"
        }, {
            "label": "丹凤县",
            "value": "611022",
            "center": "110.33191,33.694711"
        }, {
            "label": "商南县",
            "value": "611023",
            "center": "110.885437,33.526367"
        }, {
            "label": "山阳县",
            "value": "611024",
            "center": "109.880435,33.530411"
        }, {
            "label": "镇安县",
            "value": "611025",
            "center": "109.151075,33.423981"
        }, {
            "label": "柞水县",
            "value": "611026",
            "center": "109.111249,33.682773"
        }]
    }]
}, {
    "label": "甘肃省",
    "value": "620000",
    "center": "103.826447,36.05956",
    "children": [{
        "label": "兰州市",
        "value": "620100",
        "center": "103.823557,36.058039",
        "children": [{
            "label": "城关区",
            "value": "620102",
            "center": "103.841032,36.049115"
        }, {
            "label": "七里河区",
            "value": "620103",
            "center": "103.784326,36.06673"
        }, {
            "label": "西固区",
            "value": "620104",
            "center": "103.622331,36.100369"
        }, {
            "label": "安宁区",
            "value": "620105",
            "center": "103.724038,36.10329"
        }, {
            "label": "红古区",
            "value": "620111",
            "center": "102.861814,36.344177"
        }, {
            "label": "永登县",
            "value": "620121",
            "center": "103.262203,36.734428"
        }, {
            "label": "皋兰县",
            "value": "620122",
            "center": "103.94933,36.331254"
        }, {
            "label": "榆中县",
            "value": "620123",
            "center": "104.114975,35.84443"
        }]
    }, {
        "label": "嘉峪关市",
        "value": "620200",
        "center": "98.255,39.8552",
        "children": [{
            "label": "嘉峪关市",
            "value": "620201",
            "center": "98.277304,39.786529"
        }]
    }, {
        "label": "金昌市",
        "value": "620300",
        "center": "102.187888,38.514238",
        "children": [{
            "label": "金川区",
            "value": "620302",
            "center": "102.187683,38.513793"
        }, {
            "label": "永昌县",
            "value": "620321",
            "center": "101.971957,38.247354"
        }]
    }, {
        "label": "白银市",
        "value": "620400",
        "center": "104.173606,36.54568",
        "children": [{
            "label": "白银区",
            "value": "620402",
            "center": "104.17425,36.545649"
        }, {
            "label": "平川区",
            "value": "620403",
            "center": "104.819207,36.72921"
        }, {
            "label": "靖远县",
            "value": "620421",
            "center": "104.686972,36.561424"
        }, {
            "label": "会宁县",
            "value": "620422",
            "center": "105.054337,35.692486"
        }, {
            "label": "景泰县",
            "value": "620423",
            "center": "104.066394,37.193519"
        }]
    }, {
        "label": "天水市",
        "value": "620500",
        "center": "105.724998,34.578529",
        "children": [{
            "label": "秦州区",
            "value": "620502",
            "center": "105.724477,34.578645"
        }, {
            "label": "麦积区",
            "value": "620503",
            "center": "105.897631,34.563504"
        }, {
            "label": "清水县",
            "value": "620521",
            "center": "106.139878,34.75287"
        }, {
            "label": "秦安县",
            "value": "620522",
            "center": "105.6733,34.862354"
        }, {
            "label": "甘谷县",
            "value": "620523",
            "center": "105.332347,34.747327"
        }, {
            "label": "武山县",
            "value": "620524",
            "center": "104.891696,34.721955"
        }, {
            "label": "张家川回族自治县",
            "value": "620525",
            "center": "106.212416,34.993237"
        }]
    }, {
        "label": "武威市",
        "value": "620600",
        "center": "102.634697,37.929996",
        "children": [{
            "label": "凉州区",
            "value": "620602",
            "center": "102.634492,37.93025"
        }, {
            "label": "民勤县",
            "value": "620621",
            "center": "103.090654,38.624621"
        }, {
            "label": "古浪县",
            "value": "620622",
            "center": "102.898047,37.470571"
        }, {
            "label": "天祝藏族自治县",
            "value": "620623",
            "center": "103.142034,36.971678"
        }]
    }, {
        "label": "张掖市",
        "value": "620700",
        "center": "100.455472,38.932897",
        "children": [{
            "label": "甘州区",
            "value": "620702",
            "center": "100.454862,38.931774"
        }, {
            "label": "肃南裕固族自治县",
            "value": "620721",
            "center": "99.617086,38.837269"
        }, {
            "label": "民乐县",
            "value": "620722",
            "center": "100.816623,38.434454"
        }, {
            "label": "临泽县",
            "value": "620723",
            "center": "100.166333,39.152151"
        }, {
            "label": "高台县",
            "value": "620724",
            "center": "99.81665,39.376308"
        }, {
            "label": "山丹县",
            "value": "620725",
            "center": "101.088442,38.784839"
        }]
    }, {
        "label": "平凉市",
        "value": "620800",
        "center": "106.684691,35.54279",
        "children": [{
            "label": "崆峒区",
            "value": "620802",
            "center": "106.684223,35.54173"
        }, {
            "label": "泾川县",
            "value": "620821",
            "center": "107.365218,35.335283"
        }, {
            "label": "灵台县",
            "value": "620822",
            "center": "107.620587,35.064009"
        }, {
            "label": "崇信县",
            "value": "620823",
            "center": "107.031253,35.304533"
        }, {
            "label": "华亭县",
            "value": "620824",
            "center": "106.649308,35.215342"
        }, {
            "label": "庄浪县",
            "value": "620825",
            "center": "106.041979,35.203428"
        }, {
            "label": "静宁县",
            "value": "620826",
            "center": "105.733489,35.525243"
        }]
    }, {
        "label": "酒泉市",
        "value": "620900",
        "center": "98.510795,39.744023",
        "children": [{
            "label": "肃州区",
            "value": "620902",
            "center": "98.511155,39.743858"
        }, {
            "label": "金塔县",
            "value": "620921",
            "center": "98.902959,39.983036"
        }, {
            "label": "瓜州县",
            "value": "620922",
            "center": "95.780591,40.516525"
        }, {
            "label": "肃北蒙古族自治县",
            "value": "620923",
            "center": "94.87728,39.51224"
        }, {
            "label": "阿克塞哈萨克族自治县",
            "value": "620924",
            "center": "94.337642,39.631642"
        }, {
            "label": "玉门市",
            "value": "620981",
            "center": "97.037206,40.28682"
        }, {
            "label": "敦煌市",
            "value": "620982",
            "center": "94.664279,40.141119"
        }]
    }, {
        "label": "庆阳市",
        "value": "621000",
        "center": "107.638372,35.734218",
        "children": [{
            "label": "西峰区",
            "value": "621002",
            "center": "107.638824,35.733713"
        }, {
            "label": "庆城县",
            "value": "621021",
            "center": "107.885664,36.013504"
        }, {
            "label": "环县",
            "value": "621022",
            "center": "107.308754,36.569322"
        }, {
            "label": "华池县",
            "value": "621023",
            "center": "107.986288,36.457304"
        }, {
            "label": "合水县",
            "value": "621024",
            "center": "108.019865,35.819005"
        }, {
            "label": "正宁县",
            "value": "621025",
            "center": "108.361068,35.490642"
        }, {
            "label": "宁县",
            "value": "621026",
            "center": "107.921182,35.50201"
        }, {
            "label": "镇原县",
            "value": "621027",
            "center": "107.195706,35.677806"
        }]
    }, {
        "label": "定西市",
        "value": "621100",
        "center": "104.626294,35.579578",
        "children": [{
            "label": "安定区",
            "value": "621102",
            "center": "104.62577,35.579764"
        }, {
            "label": "通渭县",
            "value": "621121",
            "center": "105.250102,35.208922"
        }, {
            "label": "陇西县",
            "value": "621122",
            "center": "104.637554,35.003409"
        }, {
            "label": "渭源县",
            "value": "621123",
            "center": "104.211742,35.133023"
        }, {
            "label": "临洮县",
            "value": "621124",
            "center": "103.862186,35.376233"
        }, {
            "label": "漳县",
            "value": "621125",
            "center": "104.466756,34.848642"
        }, {
            "label": "岷县",
            "value": "621126",
            "center": "104.039882,34.439105"
        }]
    }, {
        "label": "陇南市",
        "value": "621200",
        "center": "104.929379,33.388598",
        "children": [{
            "label": "武都区",
            "value": "621202",
            "center": "104.929866,33.388155"
        }, {
            "label": "成县",
            "value": "621221",
            "center": "105.734434,33.739863"
        }, {
            "label": "文县",
            "value": "621222",
            "center": "104.682448,32.942171"
        }, {
            "label": "宕昌县",
            "value": "621223",
            "center": "104.394475,34.042655"
        }, {
            "label": "康县",
            "value": "621224",
            "center": "105.609534,33.328266"
        }, {
            "label": "西和县",
            "value": "621225",
            "center": "105.299737,34.013718"
        }, {
            "label": "礼县",
            "value": "621226",
            "center": "105.181616,34.189387"
        }, {
            "label": "徽县",
            "value": "621227",
            "center": "106.085632,33.767785"
        }, {
            "label": "两当县",
            "value": "621228",
            "center": "106.306959,33.910729"
        }]
    }, {
        "label": "临夏回族自治州",
        "value": "622900",
        "center": "103.212006,35.599446",
        "children": [{
            "label": "临夏市",
            "value": "622901",
            "center": "103.211634,35.59941"
        }, {
            "label": "临夏县",
            "value": "622921",
            "center": "102.993873,35.49236"
        }, {
            "label": "康乐县",
            "value": "622922",
            "center": "103.709852,35.371906"
        }, {
            "label": "永靖县",
            "value": "622923",
            "center": "103.319871,35.938933"
        }, {
            "label": "广河县",
            "value": "622924",
            "center": "103.576188,35.481688"
        }, {
            "label": "和政县",
            "value": "622925",
            "center": "103.350357,35.425971"
        }, {
            "label": "东乡族自治县",
            "value": "622926",
            "center": "103.389568,35.66383"
        }, {
            "label": "积石山保安族东乡族撒拉族自治县",
            "value": "622927",
            "center": "102.877473,35.712906"
        }]
    }, {
        "label": "甘南藏族自治州",
        "value": "623000",
        "center": "102.911008,34.986354",
        "children": [{
            "label": "合作市",
            "value": "623001",
            "center": "102.91149,34.985973"
        }, {
            "label": "临潭县",
            "value": "623021",
            "center": "103.353054,34.69164"
        }, {
            "label": "卓尼县",
            "value": "623022",
            "center": "103.508508,34.588165"
        }, {
            "label": "舟曲县",
            "value": "623023",
            "center": "104.370271,33.782964"
        }, {
            "label": "迭部县",
            "value": "623024",
            "center": "103.221009,34.055348"
        }, {
            "label": "玛曲县",
            "value": "623025",
            "center": "102.075767,33.998068"
        }, {
            "label": "碌曲县",
            "value": "623026",
            "center": "102.488495,34.589591"
        }, {
            "label": "夏河县",
            "value": "623027",
            "center": "102.520743,35.200853"
        }]
    }]
}, {
    "label": "青海省",
    "value": "630000",
    "center": "101.778916,36.623178",
    "children": [{
        "label": "西宁市",
        "value": "630100",
        "center": "101.778916,36.623178",
        "children": [{
            "label": "城东区",
            "value": "630102",
            "center": "101.796095,36.616043"
        }, {
            "label": "城中区",
            "value": "630103",
            "center": "101.784554,36.621181"
        }, {
            "label": "城西区",
            "value": "630104",
            "center": "101.763649,36.628323"
        }, {
            "label": "城北区",
            "value": "630105",
            "center": "101.761297,36.648448"
        }, {
            "label": "大通回族土族自治县",
            "value": "630121",
            "center": "101.684183,36.931343"
        }, {
            "label": "湟中县",
            "value": "630122",
            "center": "101.569475,36.500419"
        }, {
            "label": "湟源县",
            "value": "630123",
            "center": "101.263435,36.684818"
        }]
    }, {
        "label": "海东地区",
        "value": "632100",
        "center": "102.106684,36.496876",
        "children": [{
            "label": "平安县",
            "value": "632121",
            "center": "102.108835,36.500563"
        }, {
            "label": "民和回族土族自治县",
            "value": "632122",
            "center": "102.830892,36.320321"
        }, {
            "label": "乐都县",
            "value": "632123",
            "center": "102.39123,36.475487"
        }, {
            "label": "互助土族自治县",
            "value": "632126",
            "center": "101.959271,36.844249"
        }, {
            "label": "化隆回族自治县",
            "value": "632127",
            "center": "102.264143,36.094908"
        }, {
            "label": "循化撒拉族自治县",
            "value": "632128",
            "center": "102.485646,35.848586"
        }]
    }, {
        "label": "海北藏族自治州",
        "value": "632200",
        "center": "100.901059,36.959435",
        "children": [{
            "label": "门源回族自治县",
            "value": "632221",
            "center": "101.618461,37.376627"
        }, {
            "label": "祁连县",
            "value": "632222",
            "center": "100.249778,38.175409"
        }, {
            "label": "海晏县",
            "value": "632223",
            "center": "100.90049,36.959542"
        }, {
            "label": "刚察县",
            "value": "632224",
            "center": "100.138417,37.326263"
        }]
    }, {
        "label": "黄南藏族自治州",
        "value": "632300",
        "center": "102.019988,35.517744",
        "children": [{
            "label": "同仁县",
            "value": "632321",
            "center": "102.017604,35.516337"
        }, {
            "label": "尖扎县",
            "value": "632322",
            "center": "102.031953,35.938205"
        }, {
            "label": "泽库县",
            "value": "632323",
            "center": "101.469343,35.036842"
        }, {
            "label": "河南蒙古族自治县",
            "value": "632324",
            "center": "101.611877,34.734522"
        }]
    }, {
        "label": "海南藏族自治州",
        "value": "632500",
        "center": "100.619542,36.280353",
        "children": [{
            "label": "共和县",
            "value": "632521",
            "center": "100.619597,36.280286"
        }, {
            "label": "同德县",
            "value": "632522",
            "center": "100.579465,35.254492"
        }, {
            "label": "贵德县",
            "value": "632523",
            "center": "101.431856,36.040456"
        }, {
            "label": "兴海县",
            "value": "632524",
            "center": "99.986963,35.58909"
        }, {
            "label": "贵南县",
            "value": "632525",
            "center": "100.74792,35.587085"
        }]
    }, {
        "label": "果洛藏族自治州",
        "value": "632600",
        "center": "100.242143,34.4736",
        "children": [{
            "label": "玛沁县",
            "value": "632621",
            "center": "100.243531,34.473386"
        }, {
            "label": "班玛县",
            "value": "632622",
            "center": "100.737955,32.931589"
        }, {
            "label": "甘德县",
            "value": "632623",
            "center": "99.902589,33.966987"
        }, {
            "label": "达日县",
            "value": "632624",
            "center": "99.651715,33.753259"
        }, {
            "label": "久治县",
            "value": "632625",
            "center": "101.484884,33.430217"
        }, {
            "label": "玛多县",
            "value": "632626",
            "center": "98.211343,34.91528"
        }]
    }, {
        "label": "玉树藏族自治州",
        "value": "632700",
        "center": "97.008522,33.004049",
        "children": [{
            "label": "玉树县",
            "value": "632721",
            "center": "97.008723,32.993793"
        }, {
            "label": "杂多县",
            "value": "632722",
            "center": "95.293423,32.891886"
        }, {
            "label": "称多县",
            "value": "632723",
            "center": "97.110893,33.367884"
        }, {
            "label": "治多县",
            "value": "632724",
            "center": "95.616843,33.852322"
        }, {
            "label": "囊谦县",
            "value": "632725",
            "center": "96.479797,32.203206"
        }, {
            "label": "曲麻莱县",
            "value": "632726",
            "center": "95.800674,34.12654"
        }]
    }, {
        "label": "海西蒙古族藏族自治州",
        "value": "632800",
        "center": "97.370785,37.374663",
        "children": [{
            "label": "格尔木市",
            "value": "632801",
            "center": "94.905777,36.401541"
        }, {
            "label": "德令哈市",
            "value": "632802",
            "center": "97.370143,37.374555"
        }, {
            "label": "乌兰县",
            "value": "632821",
            "center": "98.479852,36.930389"
        }, {
            "label": "都兰县",
            "value": "632822",
            "center": "98.089161,36.298553"
        }, {
            "label": "天峻县",
            "value": "632823",
            "center": "99.02078,37.29906"
        }]
    }]
}, {
    "label": "宁夏回族自治区",
    "value": "640000",
    "center": "106.278179,38.46637",
    "children": [{
        "label": "银川市",
        "value": "640100",
        "center": "106.278179,38.46637",
        "children": [{
            "label": "兴庆区",
            "value": "640104",
            "center": "106.278393,38.46747"
        }, {
            "label": "西夏区",
            "value": "640105",
            "center": "106.132116,38.492424"
        }, {
            "label": "金凤区",
            "value": "640106",
            "center": "106.228486,38.477353"
        }, {
            "label": "永宁县",
            "value": "640121",
            "center": "106.253781,38.28043"
        }, {
            "label": "贺兰县",
            "value": "640122",
            "center": "106.345904,38.554563"
        }, {
            "label": "灵武市",
            "value": "640181",
            "center": "106.334701,38.094058"
        }]
    }, {
        "label": "石嘴山市",
        "value": "640200",
        "center": "106.376173,39.01333",
        "children": [{
            "label": "大武口区",
            "value": "640202",
            "center": "106.376651,39.014158"
        }, {
            "label": "惠农区",
            "value": "640205",
            "center": "106.775513,39.230094"
        }, {
            "label": "平罗县",
            "value": "640221",
            "center": "106.54489,38.90674"
        }]
    }, {
        "label": "吴忠市",
        "value": "640300",
        "center": "106.199409,37.986165",
        "children": [{
            "label": "利通区",
            "value": "640302",
            "center": "106.199419,37.985967"
        }, {
            "label": "红寺堡区",
            "value": "640303",
            "center": "106.067315,37.421616"
        }, {
            "label": "盐池县",
            "value": "640323",
            "center": "107.40541,37.784222"
        }, {
            "label": "同心县",
            "value": "640324",
            "center": "105.914764,36.9829"
        }, {
            "label": "青铜峡市",
            "value": "640381",
            "center": "106.075395,38.021509"
        }]
    }, {
        "label": "固原市",
        "value": "640400",
        "center": "106.285241,36.004561",
        "children": [{
            "label": "原州区",
            "value": "640402",
            "center": "106.28477,36.005337"
        }, {
            "label": "西吉县",
            "value": "640422",
            "center": "105.731801,35.965384"
        }, {
            "label": "隆德县",
            "value": "640423",
            "center": "106.12344,35.618234"
        }, {
            "label": "泾源县",
            "value": "640424",
            "center": "106.338674,35.49344"
        }, {
            "label": "彭阳县",
            "value": "640425",
            "center": "106.641512,35.849975"
        }]
    }, {
        "label": "中卫市",
        "value": "640500",
        "center": "105.189568,37.514951",
        "children": [{
            "label": "沙坡头区",
            "value": "640502",
            "center": "105.190536,37.514564"
        }, {
            "label": "中宁县",
            "value": "640521",
            "center": "105.675784,37.489736"
        }, {
            "label": "海原县",
            "value": "640522",
            "center": "105.647323,36.562007"
        }]
    }]
}, {
    "label": "新疆维吾尔自治区",
    "value": "650000",
    "center": "87.627704,43.793026",
    "children": [{
        "label": "乌鲁木齐市",
        "value": "650100",
        "center": "87.617733,43.792818",
        "children": [{
            "label": "天山区",
            "value": "650102",
            "center": "87.620116,43.796428"
        }, {
            "label": "沙依巴克区",
            "value": "650103",
            "center": "87.596639,43.788872"
        }, {
            "label": "新市区",
            "value": "650104",
            "center": "87.560653,43.870882"
        }, {
            "label": "水磨沟区",
            "value": "650105",
            "center": "87.613093,43.816747"
        }, {
            "label": "头屯河区",
            "value": "650106",
            "center": "87.425823,43.876053"
        }, {
            "label": "达坂城区",
            "value": "650107",
            "center": "88.30994,43.36181"
        }, {
            "label": "米东区",
            "value": "650109",
            "center": "87.691801,43.960982"
        }, {
            "label": "乌鲁木齐县",
            "value": "650121",
            "center": "87.505603,43.982546"
        }]
    }, {
        "label": "克拉玛依市",
        "value": "650200",
        "center": "84.873946,45.595886",
        "children": [{
            "label": "独山子区",
            "value": "650202",
            "center": "84.882267,44.327207"
        }, {
            "label": "克拉玛依区",
            "value": "650203",
            "center": "84.868918,45.600477"
        }, {
            "label": "白碱滩区",
            "value": "650204",
            "center": "85.129882,45.689021"
        }, {
            "label": "乌尔禾区",
            "value": "650205",
            "center": "85.697767,46.08776"
        }]
    }, {
        "label": "吐鲁番地区",
        "value": "652100",
        "center": "89.186661,42.941938",
        "children": [{
            "label": "吐鲁番市",
            "value": "652101",
            "center": "89.189537,42.951227"
        }, {
            "label": "鄯善县",
            "value": "652122",
            "center": "90.21333,42.868744"
        }, {
            "label": "托克逊县",
            "value": "652123",
            "center": "88.653814,42.792521"
        }]
    }, {
        "label": "哈密地区",
        "value": "652200",
        "center": "93.491679,42.918549",
        "children": [{
            "label": "哈密市",
            "value": "652201",
            "center": "93.51316,42.833248"
        }, {
            "label": "巴里坤哈萨克自治县",
            "value": "652222",
            "center": "93.016624,43.598762"
        }, {
            "label": "伊吾县",
            "value": "652223",
            "center": "94.697074,43.254978"
        }]
    }, {
        "label": "昌吉回族自治州",
        "value": "652300",
        "center": "87.304012,44.014577",
        "children": [{
            "label": "昌吉市",
            "value": "652301",
            "center": "87.304112,44.013183"
        }, {
            "label": "阜康市",
            "value": "652302",
            "center": "87.98384,44.152153"
        }, {
            "label": "呼图壁县",
            "value": "652323",
            "center": "86.888613,44.189342"
        }, {
            "label": "玛纳斯县",
            "value": "652324",
            "center": "86.217687,44.305625"
        }, {
            "label": "奇台县",
            "value": "652325",
            "center": "89.591437,44.021996"
        }, {
            "label": "吉木萨尔县",
            "value": "652327",
            "center": "89.181288,43.997162"
        }, {
            "label": "木垒哈萨克自治县",
            "value": "652328",
            "center": "90.282833,43.832442"
        }]
    }, {
        "label": "博尔塔拉蒙古自治州",
        "value": "652700",
        "center": "82.074778,44.903258",
        "children": [{
            "label": "博乐市",
            "value": "652701",
            "center": "82.072237,44.903087"
        }, {
            "label": "精河县",
            "value": "652722",
            "center": "82.892938,44.605645"
        }, {
            "label": "温泉县",
            "value": "652723",
            "center": "81.03099,44.973751"
        }]
    }, {
        "label": "巴音郭楞蒙古自治州",
        "value": "652800",
        "center": "86.150969,41.768552",
        "children": [{
            "label": "库尔勒市",
            "value": "652801",
            "center": "86.145948,41.763122"
        }, {
            "label": "轮台县",
            "value": "652822",
            "center": "84.248542,41.781266"
        }, {
            "label": "尉犁县",
            "value": "652823",
            "center": "86.263412,41.337428"
        }, {
            "label": "若羌县",
            "value": "652824",
            "center": "88.168807,39.023807"
        }, {
            "label": "且末县",
            "value": "652825",
            "center": "85.532629,38.138562"
        }, {
            "label": "焉耆回族自治县",
            "value": "652826",
            "center": "86.5698,42.064349"
        }, {
            "label": "和静县",
            "value": "652827",
            "center": "86.391067,42.31716"
        }, {
            "label": "和硕县",
            "value": "652828",
            "center": "86.864947,42.268863"
        }, {
            "label": "博湖县",
            "value": "652829",
            "center": "86.631576,41.980166"
        }]
    }, {
        "label": "阿克苏地区",
        "value": "652900",
        "center": "80.265068,41.170712",
        "children": [{
            "label": "阿克苏市",
            "value": "652901",
            "center": "80.2629,41.171272"
        }, {
            "label": "温宿县",
            "value": "652922",
            "center": "80.243273,41.272995"
        }, {
            "label": "库车县",
            "value": "652923",
            "center": "82.96304,41.717141"
        }, {
            "label": "沙雅县",
            "value": "652924",
            "center": "82.78077,41.226268"
        }, {
            "label": "新和县",
            "value": "652925",
            "center": "82.610828,41.551176"
        }, {
            "label": "拜城县",
            "value": "652926",
            "center": "81.869881,41.796101"
        }, {
            "label": "乌什县",
            "value": "652927",
            "center": "79.230805,41.21587"
        }, {
            "label": "阿瓦提县",
            "value": "652928",
            "center": "80.378426,40.638422"
        }, {
            "label": "柯坪县",
            "value": "652929",
            "center": "79.04785,40.50624"
        }]
    }, {
        "label": "克孜勒苏柯尔克孜自治州",
        "value": "653000",
        "center": "76.172825,39.713431",
        "children": [{
            "label": "阿图什市",
            "value": "653001",
            "center": "76.173939,39.712898"
        }, {
            "label": "阿克陶县",
            "value": "653022",
            "center": "75.945159,39.147079"
        }, {
            "label": "阿合奇县",
            "value": "653023",
            "center": "78.450164,40.937567"
        }, {
            "label": "乌恰县",
            "value": "653024",
            "center": "75.25969,39.716633"
        }]
    }, {
        "label": "喀什地区",
        "value": "653100",
        "center": "75.989138,39.467664",
        "children": [{
            "label": "喀什市",
            "value": "653101",
            "center": "75.98838,39.467861"
        }, {
            "label": "疏附县",
            "value": "653121",
            "center": "75.863075,39.378306"
        }, {
            "label": "疏勒县",
            "value": "653122",
            "center": "76.053653,39.399461"
        }, {
            "label": "英吉沙县",
            "value": "653123",
            "center": "76.174292,38.929839"
        }, {
            "label": "泽普县",
            "value": "653124",
            "center": "77.273593,38.191217"
        }, {
            "label": "莎车县",
            "value": "653125",
            "center": "77.248884,38.414499"
        }, {
            "label": "叶城县",
            "value": "653126",
            "center": "77.420353,37.884679"
        }, {
            "label": "麦盖提县",
            "value": "653127",
            "center": "77.651538,38.903384"
        }, {
            "label": "岳普湖县",
            "value": "653128",
            "center": "76.7724,39.235248"
        }, {
            "label": "伽师县",
            "value": "653129",
            "center": "76.741982,39.494325"
        }, {
            "label": "巴楚县",
            "value": "653130",
            "center": "78.55041,39.783479"
        }, {
            "label": "塔什库尔干塔吉克自治县",
            "value": "653131",
            "center": "75.228068,37.775437"
        }]
    }, {
        "label": "和田地区",
        "value": "653200",
        "center": "79.92533,37.110687",
        "children": [{
            "label": "和田市",
            "value": "653201",
            "center": "79.927542,37.108944"
        }, {
            "label": "和田县",
            "value": "653221",
            "center": "79.81907,37.120031"
        }, {
            "label": "墨玉县",
            "value": "653222",
            "center": "79.736629,37.271511"
        }, {
            "label": "皮山县",
            "value": "653223",
            "center": "78.282301,37.616332"
        }, {
            "label": "洛浦县",
            "value": "653224",
            "center": "80.184038,37.074377"
        }, {
            "label": "策勒县",
            "value": "653225",
            "center": "80.803572,37.001672"
        }, {
            "label": "于田县",
            "value": "653226",
            "center": "81.667845,36.854628"
        }, {
            "label": "民丰县",
            "value": "653227",
            "center": "82.692354,37.064909"
        }]
    }, {
        "label": "伊犁哈萨克自治州",
        "value": "654000",
        "center": "81.317946,43.92186",
        "children": [{
            "label": "伊宁市",
            "value": "654002",
            "center": "81.316343,43.922209"
        }, {
            "label": "奎屯市",
            "value": "654003",
            "center": "84.901602,44.423445"
        }, {
            "label": "伊宁县",
            "value": "654021",
            "center": "81.524671,43.977876"
        }, {
            "label": "察布查尔锡伯自治县",
            "value": "654022",
            "center": "81.150874,43.838883"
        }, {
            "label": "霍城县",
            "value": "654023",
            "center": "80.872508,44.049912"
        }, {
            "label": "巩留县",
            "value": "654024",
            "center": "82.227044,43.481618"
        }, {
            "label": "新源县",
            "value": "654025",
            "center": "83.258493,43.434249"
        }, {
            "label": "昭苏县",
            "value": "654026",
            "center": "81.126029,43.157765"
        }, {
            "label": "特克斯县",
            "value": "654027",
            "center": "81.840058,43.214861"
        }, {
            "label": "尼勒克县",
            "value": "654028",
            "center": "82.504119,43.789737"
        }]
    }, {
        "label": "塔城地区",
        "value": "654200",
        "center": "82.985732,46.746301",
        "children": [{
            "label": "塔城市",
            "value": "654201",
            "center": "82.983988,46.746281"
        }, {
            "label": "乌苏市",
            "value": "654202",
            "center": "84.677624,44.430115"
        }, {
            "label": "额敏县",
            "value": "654221",
            "center": "83.622118,46.522555"
        }, {
            "label": "沙湾县",
            "value": "654223",
            "center": "85.622508,44.329544"
        }, {
            "label": "托里县",
            "value": "654224",
            "center": "83.60469,45.935863"
        }, {
            "label": "裕民县",
            "value": "654225",
            "center": "82.982157,46.202781"
        }, {
            "label": "和布克赛尔蒙古自治县",
            "value": "654226",
            "center": "85.733551,46.793001"
        }]
    }, {
        "label": "阿勒泰地区",
        "value": "654300",
        "center": "88.13963,47.848393",
        "children": [{
            "label": "阿勒泰市",
            "value": "654301",
            "center": "88.138743,47.848911"
        }, {
            "label": "布尔津县",
            "value": "654321",
            "center": "86.86186,47.70453"
        }, {
            "label": "富蕴县",
            "value": "654322",
            "center": "89.524993,46.993106"
        }, {
            "label": "福海县",
            "value": "654323",
            "center": "87.494569,47.113128"
        }, {
            "label": "哈巴河县",
            "value": "654324",
            "center": "86.418964,48.059284"
        }, {
            "label": "青河县",
            "value": "654325",
            "center": "90.381561,46.672446"
        }, {
            "label": "吉木乃县",
            "value": "654326",
            "center": "85.876064,47.434633"
        }]
    }, {
        "label": "自治区直辖",
        "value": "659000",
        "center": "87.617733,43.792818",
        "children": [{
            "label": "石河子市",
            "value": "659001",
            "center": "85.9823,44.2893"
        }, {
            "label": "阿拉尔市",
            "value": "659002",
            "center": "81.2988,40.5154"
        }, {
            "label": "图木舒克市",
            "value": "659003",
            "center": "79.1926,39.9168"
        }, {
            "label": "五家渠市",
            "value": "659004",
            "center": "87.5245,44.1764"
        }]
    }]
}, {
    "label": "台湾省",
    "value": "710000",
    "center": "121.509062,25.044332",
    "children": [{
        "label": "台北市",
        "value": "710100",
        "center": "",
        "children": [{
            "label": "中正区",
            "value": "710101",
            "center": ""
        }, {
            "label": "大同区",
            "value": "710102",
            "center": ""
        }, {
            "label": "中山区",
            "value": "710103",
            "center": ""
        }, {
            "label": "松山区",
            "value": "710104",
            "center": ""
        }, {
            "label": "大安区",
            "value": "710105",
            "center": ""
        }, {
            "label": "万华区",
            "value": "710106",
            "center": ""
        }, {
            "label": "信义区",
            "value": "710107",
            "center": ""
        }, {
            "label": "士林区",
            "value": "710108",
            "center": ""
        }, {
            "label": "北投区",
            "value": "710109",
            "center": ""
        }, {
            "label": "内湖区",
            "value": "710110",
            "center": ""
        }, {
            "label": "南港区",
            "value": "710111",
            "center": ""
        }, {
            "label": "文山区",
            "value": "710112",
            "center": ""
        }]
    }, {
        "label": "高雄市",
        "value": "710200",
        "center": "",
        "children": [{
            "label": "新兴区",
            "value": "710201",
            "center": ""
        }, {
            "label": "前金区",
            "value": "710202",
            "center": ""
        }, {
            "label": "芩雅区",
            "value": "710203",
            "center": ""
        }, {
            "label": "盐埕区",
            "value": "710204",
            "center": ""
        }, {
            "label": "鼓山区",
            "value": "710205",
            "center": ""
        }, {
            "label": "旗津区",
            "value": "710206",
            "center": ""
        }, {
            "label": "前镇区",
            "value": "710207",
            "center": ""
        }, {
            "label": "三民区",
            "value": "710208",
            "center": ""
        }, {
            "label": "左营区",
            "value": "710209",
            "center": ""
        }, {
            "label": "楠梓区",
            "value": "710210",
            "center": ""
        }, {
            "label": "小港区",
            "value": "710211",
            "center": ""
        }]
    }, {
        "label": "基隆市",
        "value": "710300",
        "center": "",
        "children": [{
            "label": "仁爱区",
            "value": "710301",
            "center": ""
        }, {
            "label": "信义区",
            "value": "710302",
            "center": ""
        }, {
            "label": "中正区",
            "value": "710303",
            "center": ""
        }, {
            "label": "中山区",
            "value": "710304",
            "center": ""
        }, {
            "label": "安乐区",
            "value": "710305",
            "center": ""
        }, {
            "label": "暖暖区",
            "value": "710306",
            "center": ""
        }, {
            "label": "七堵区",
            "value": "710307",
            "center": ""
        }]
    }, {
        "label": "台中市",
        "value": "710400",
        "center": "",
        "children": [{
            "label": "中区",
            "value": "710401",
            "center": ""
        }, {
            "label": "东区",
            "value": "710402",
            "center": ""
        }, {
            "label": "南区",
            "value": "710403",
            "center": ""
        }, {
            "label": "西区",
            "value": "710404",
            "center": ""
        }, {
            "label": "北区",
            "value": "710405",
            "center": ""
        }, {
            "label": "北屯区",
            "value": "710406",
            "center": ""
        }, {
            "label": "西屯区",
            "value": "710407",
            "center": ""
        }, {
            "label": "南屯区",
            "value": "710408",
            "center": ""
        }]
    }, {
        "label": "台南市",
        "value": "710500",
        "center": "",
        "children": [{
            "label": "中西区",
            "value": "710501",
            "center": ""
        }, {
            "label": "东区",
            "value": "710502",
            "center": ""
        }, {
            "label": "南区",
            "value": "710503",
            "center": ""
        }, {
            "label": "北区",
            "value": "710504",
            "center": ""
        }, {
            "label": "安平区",
            "value": "710505",
            "center": ""
        }, {
            "label": "安南区",
            "value": "710506",
            "center": ""
        }]
    }, {
        "label": "新竹市",
        "value": "710600",
        "center": "",
        "children": [{
            "label": "东区",
            "value": "710601",
            "center": ""
        }, {
            "label": "北区",
            "value": "710602",
            "center": ""
        }, {
            "label": "香山区",
            "value": "710603",
            "center": ""
        }]
    }, {
        "label": "嘉义市",
        "value": "710700",
        "center": "",
        "children": [{
            "label": "东区",
            "value": "710701",
            "center": ""
        }, {
            "label": "西区",
            "value": "710702",
            "center": ""
        }]
    }, {
        "label": "省直辖",
        "value": "719000",
        "center": "",
        "children": [{
            "label": "台北县",
            "value": "719001",
            "center": ""
        }, {
            "label": "宜兰县",
            "value": "719002",
            "center": ""
        }, {
            "label": "新竹县",
            "value": "719003",
            "center": ""
        }, {
            "label": "桃园县",
            "value": "719004",
            "center": ""
        }, {
            "label": "苗栗县",
            "value": "719005",
            "center": ""
        }, {
            "label": "台中县",
            "value": "719006",
            "center": ""
        }, {
            "label": "彰化县",
            "value": "719007",
            "center": ""
        }, {
            "label": "南投县",
            "value": "719008",
            "center": ""
        }, {
            "label": "嘉义县",
            "value": "719009",
            "center": ""
        }, {
            "label": "云林县",
            "value": "719010",
            "center": ""
        }, {
            "label": "台南县",
            "value": "719011",
            "center": ""
        }, {
            "label": "高雄县",
            "value": "719012",
            "center": ""
        }, {
            "label": "屏东县",
            "value": "719013",
            "center": ""
        }, {
            "label": "台东县",
            "value": "719014",
            "center": ""
        }, {
            "label": "花莲县",
            "value": "719015",
            "center": ""
        }, {
            "label": "澎湖县",
            "value": "719016",
            "center": ""
        }]
    }]
}, {
    "label": "香港特别行政区",
    "value": "810000",
    "center": "114.173355,22.320048",
    "children": [{
        "label": "香港岛",
        "value": "810100",
        "center": "",
        "children": [{
            "label": "中西区",
            "value": "810101",
            "center": ""
        }, {
            "label": "湾仔区",
            "value": "810102",
            "center": ""
        }, {
            "label": "东区",
            "value": "810103",
            "center": ""
        }, {
            "label": "南区",
            "value": "810104",
            "center": ""
        }]
    }, {
        "label": "九龙",
        "value": "810200",
        "center": "",
        "children": [{
            "label": "油尖旺区",
            "value": "810201",
            "center": ""
        }, {
            "label": "深水埗区",
            "value": "810202",
            "center": ""
        }, {
            "label": "九龙城区",
            "value": "810203",
            "center": ""
        }, {
            "label": "黄大仙区",
            "value": "810204",
            "center": ""
        }, {
            "label": "观塘区",
            "value": "810205",
            "center": ""
        }]
    }, {
        "label": "新界",
        "value": "810300",
        "center": "",
        "children": [{
            "label": "北区",
            "value": "810301",
            "center": ""
        }, {
            "label": "大埔区",
            "value": "810302",
            "center": ""
        }, {
            "label": "沙田区",
            "value": "810303",
            "center": ""
        }, {
            "label": "西贡区",
            "value": "810304",
            "center": ""
        }, {
            "label": "荃湾区",
            "value": "810305",
            "center": ""
        }, {
            "label": "屯门区",
            "value": "810306",
            "center": ""
        }, {
            "label": "元朗区",
            "value": "810307",
            "center": ""
        }, {
            "label": "葵青区",
            "value": "810308",
            "center": ""
        }, {
            "label": "离岛区",
            "value": "810309",
            "center": ""
        }]
    }]
}, {
    "label": "澳门特别行政区",
    "value": "820000",
    "center": "113.54909,22.198951",
    "children": [{
        "label": "澳门半岛",
        "value": "820100",
        "center": "",
        "children": [{
            "label": "花地玛堂区",
            "value": "820101",
            "center": ""
        }, {
            "label": "圣安多尼堂区",
            "value": "820102",
            "center": ""
        }, {
            "label": "大堂区",
            "value": "820103",
            "center": ""
        }, {
            "label": "望德堂区",
            "value": "820104",
            "center": ""
        }, {
            "label": "风顺堂区",
            "value": "820105",
            "center": ""
        }]
    }, {
        "label": "澳门离岛",
        "value": "820200",
        "center": "",
        "children": [{
            "label": "嘉模堂区",
            "value": "820201",
            "center": ""
        }, {
            "label": "圣方济各堂区",
            "value": "820202",
            "center": ""
        }]
    }, {
        "label": "无堂区划分区域",
        "value": "820300",
        "center": "",
        "children": [{
            "label": "路氹城",
            "value": "820301",
            "center": ""
        }]
    }]
}, {
    "label": "湖南省",
    "value": "430000",
    "center": "112.982279,28.19409",
    "children": [{
        "label": "邵阳市",
        "value": "430500",
        "center": "111.46923,27.237842",
        "children": [{
            "label": "双清区",
            "value": "430502",
            "center": "111.479756,27.240001"
        }, {
            "label": "大祥区",
            "value": "430503",
            "center": "111.462968,27.233593"
        }, {
            "label": "北塔区",
            "value": "430511",
            "center": "111.452315,27.245688"
        }, {
            "label": "邵东县",
            "value": "430521",
            "center": "111.743168,27.257273"
        }, {
            "label": "新邵县",
            "value": "430522",
            "center": "111.459762,27.311429"
        }, {
            "label": "邵阳县",
            "value": "430523",
            "center": "111.2757,26.989713"
        }, {
            "label": "隆回县",
            "value": "430524",
            "center": "111.038785,27.116002"
        }, {
            "label": "洞口县",
            "value": "430525",
            "center": "110.579212,27.062286"
        }, {
            "label": "绥宁县",
            "value": "430527",
            "center": "110.155075,26.580622"
        }, {
            "label": "新宁县",
            "value": "430528",
            "center": "110.859115,26.438912"
        }, {
            "label": "城步苗族自治县",
            "value": "430529",
            "center": "110.313226,26.363575"
        }, {
            "label": "武冈市",
            "value": "430581",
            "center": "110.636804,26.732086"
        }]
    }, {
        "label": "常德市",
        "value": "430700",
        "center": "111.691347,29.040225",
        "children": [{
            "label": "武陵区",
            "value": "430702",
            "center": "111.690718,29.040477"
        }, {
            "label": "鼎城区",
            "value": "430703",
            "center": "111.685327,29.014426"
        }, {
            "label": "安乡县",
            "value": "430721",
            "center": "112.172289,29.414483"
        }, {
            "label": "汉寿县",
            "value": "430722",
            "center": "111.968506,28.907319"
        }, {
            "label": "澧县",
            "value": "430723",
            "center": "111.761682,29.64264"
        }, {
            "label": "临澧县",
            "value": "430724",
            "center": "111.645602,29.443217"
        }, {
            "label": "桃源县",
            "value": "430725",
            "center": "111.484503,28.902734"
        }, {
            "label": "石门县",
            "value": "430726",
            "center": "111.379087,29.584703"
        }, {
            "label": "津市市",
            "value": "430781",
            "center": "111.879609,29.630867"
        }]
    }, {
        "label": "张家界市",
        "value": "430800",
        "center": "110.479921,29.127401",
        "children": [{
            "label": "永定区",
            "value": "430802",
            "center": "110.484559,29.125961"
        }, {
            "label": "武陵源区",
            "value": "430811",
            "center": "110.54758,29.347827"
        }, {
            "label": "慈利县",
            "value": "430821",
            "center": "111.132702,29.423876"
        }, {
            "label": "桑植县",
            "value": "430822",
            "center": "110.164039,29.399939"
        }]
    }, {
        "label": "益阳市",
        "value": "430900",
        "center": "112.355042,28.570066",
        "children": [{
            "label": "资阳区",
            "value": "430902",
            "center": "112.33084,28.592771"
        }, {
            "label": "赫山区",
            "value": "430903",
            "center": "112.360946,28.568327"
        }, {
            "label": "南县",
            "value": "430921",
            "center": "112.410399,29.372181"
        }, {
            "label": "桃江县",
            "value": "430922",
            "center": "112.139732,28.520993"
        }, {
            "label": "安化县",
            "value": "430923",
            "center": "111.221824,28.377421"
        }, {
            "label": "沅江市",
            "value": "430981",
            "center": "112.361088,28.839713"
        }]
    }, {
        "label": "长沙市",
        "value": "430100",
        "center": "112.982279,28.19409",
        "children": [{
            "label": "芙蓉区",
            "value": "430102",
            "center": "112.988094,28.193106"
        }, {
            "label": "天心区",
            "value": "430103",
            "center": "112.97307,28.192375"
        }, {
            "label": "岳麓区",
            "value": "430104",
            "center": "112.911591,28.213044"
        }, {
            "label": "开福区",
            "value": "430105",
            "center": "112.985525,28.201336"
        }, {
            "label": "雨花区",
            "value": "430111",
            "center": "113.016337,28.109937"
        }, {
            "label": "望城区",
            "value": "430112",
            "center": "112.819549,28.347458"
        }, {
            "label": "长沙县",
            "value": "430121",
            "center": "113.080098,28.237888"
        }, {
            "label": "宁乡县",
            "value": "430124",
            "center": "112.556183,28.275053"
        }, {
            "label": "浏阳市",
            "value": "430181",
            "center": "113.633301,28.141112"
        }]
    }, {
        "label": "郴州市",
        "value": "431000",
        "center": "113.032067,25.793589",
        "children": [{
            "label": "北湖区",
            "value": "431002",
            "center": "113.032208,25.792628"
        }, {
            "label": "苏仙区",
            "value": "431003",
            "center": "113.038698,25.793157"
        }, {
            "label": "桂阳县",
            "value": "431021",
            "center": "112.734466,25.737447"
        }, {
            "label": "宜章县",
            "value": "431022",
            "center": "112.947884,25.394345"
        }, {
            "label": "永兴县",
            "value": "431023",
            "center": "113.114819,26.129392"
        }, {
            "label": "嘉禾县",
            "value": "431024",
            "center": "112.370618,25.587309"
        }, {
            "label": "临武县",
            "value": "431025",
            "center": "112.564589,25.279119"
        }, {
            "label": "汝城县",
            "value": "431026",
            "center": "113.685686,25.553759"
        }, {
            "label": "桂东县",
            "value": "431027",
            "center": "113.945879,26.073917"
        }, {
            "label": "安仁县",
            "value": "431028",
            "center": "113.27217,26.708625"
        }, {
            "label": "资兴市",
            "value": "431081",
            "center": "113.23682,25.974152"
        }]
    }, {
        "label": "永州市",
        "value": "431100",
        "center": "111.608019,26.434516",
        "children": [{
            "label": "零陵区",
            "value": "431102",
            "center": "111.626348,26.223347"
        }, {
            "label": "冷水滩区",
            "value": "431103",
            "center": "111.607156,26.434364"
        }, {
            "label": "祁阳县",
            "value": "431121",
            "center": "111.85734,26.585929"
        }, {
            "label": "东安县",
            "value": "431122",
            "center": "111.313035,26.397278"
        }, {
            "label": "双牌县",
            "value": "431123",
            "center": "111.662146,25.959397"
        }, {
            "label": "道县",
            "value": "431124",
            "center": "111.591614,25.518444"
        }, {
            "label": "江永县",
            "value": "431125",
            "center": "111.346803,25.268154"
        }, {
            "label": "宁远县",
            "value": "431126",
            "center": "111.944529,25.584112"
        }, {
            "label": "蓝山县",
            "value": "431127",
            "center": "112.194195,25.375255"
        }, {
            "label": "新田县",
            "value": "431128",
            "center": "112.220341,25.906927"
        }, {
            "label": "江华瑶族自治县",
            "value": "431129",
            "center": "111.577276,25.182596"
        }]
    }, {
        "label": "怀化市",
        "value": "431200",
        "center": "109.97824,27.550082",
        "children": [{
            "label": "鹤城区",
            "value": "431202",
            "center": "109.982242,27.548474"
        }, {
            "label": "中方县",
            "value": "431221",
            "center": "109.948061,27.43736"
        }, {
            "label": "沅陵县",
            "value": "431222",
            "center": "110.399161,28.455554"
        }, {
            "label": "辰溪县",
            "value": "431223",
            "center": "110.196953,28.005474"
        }, {
            "label": "溆浦县",
            "value": "431224",
            "center": "110.593373,27.903802"
        }, {
            "label": "会同县",
            "value": "431225",
            "center": "109.720785,26.870789"
        }, {
            "label": "麻阳苗族自治县",
            "value": "431226",
            "center": "109.802807,27.865991"
        }, {
            "label": "新晃侗族自治县",
            "value": "431227",
            "center": "109.174443,27.359897"
        }, {
            "label": "芷江侗族自治县",
            "value": "431228",
            "center": "109.687777,27.437996"
        }, {
            "label": "靖州苗族侗族自治县",
            "value": "431229",
            "center": "109.691159,26.573511"
        }, {
            "label": "通道侗族自治县",
            "value": "431230",
            "center": "109.783359,26.158349"
        }, {
            "label": "洪江市",
            "value": "431281",
            "center": "109.831765,27.201876"
        }]
    }, {
        "label": "娄底市",
        "value": "431300",
        "center": "112.008497,27.728136",
        "children": [{
            "label": "娄星区",
            "value": "431302",
            "center": "112.008486,27.726643"
        }, {
            "label": "双峰县",
            "value": "431321",
            "center": "112.198245,27.459126"
        }, {
            "label": "新化县",
            "value": "431322",
            "center": "111.306747,27.737456"
        }, {
            "label": "冷水江市",
            "value": "431381",
            "center": "111.434674,27.685759"
        }, {
            "label": "涟源市",
            "value": "431382",
            "center": "111.670847,27.692301"
        }]
    }, {
        "label": "湘西土家族苗族自治州",
        "value": "433100",
        "center": "109.739735,28.314296",
        "children": [{
            "label": "吉首市",
            "value": "433101",
            "center": "109.738273,28.314827"
        }, {
            "label": "泸溪县",
            "value": "433122",
            "center": "110.214428,28.214516"
        }, {
            "label": "凤凰县",
            "value": "433123",
            "center": "109.599191,27.948308"
        }, {
            "label": "花垣县",
            "value": "433124",
            "center": "109.479063,28.581352"
        }, {
            "label": "保靖县",
            "value": "433125",
            "center": "109.651445,28.709605"
        }, {
            "label": "古丈县",
            "value": "433126",
            "center": "109.949592,28.616973"
        }, {
            "label": "永顺县",
            "value": "433127",
            "center": "109.853292,28.998068"
        }, {
            "label": "龙山县",
            "value": "433130",
            "center": "109.441189,29.453438"
        }]
    }, {
        "label": "株洲市",
        "value": "430200",
        "center": "113.151737,27.835806",
        "children": [{
            "label": "荷塘区",
            "value": "430202",
            "center": "113.162548,27.833036"
        }, {
            "label": "芦淞区",
            "value": "430203",
            "center": "113.155169,27.827246"
        }, {
            "label": "石峰区",
            "value": "430204",
            "center": "113.11295,27.871945"
        }, {
            "label": "天元区",
            "value": "430211",
            "center": "113.136252,27.826909"
        }, {
            "label": "株洲县",
            "value": "430221",
            "center": "113.146176,27.705844"
        }, {
            "label": "攸县",
            "value": "430223",
            "center": "113.345774,27.000071"
        }, {
            "label": "茶陵县",
            "value": "430224",
            "center": "113.546509,26.789534"
        }, {
            "label": "炎陵县",
            "value": "430225",
            "center": "113.776884,26.489459"
        }, {
            "label": "醴陵市",
            "value": "430281",
            "center": "113.507157,27.657873"
        }]
    }, {
        "label": "湘潭市",
        "value": "430300",
        "center": "112.944052,27.82973",
        "children": [{
            "label": "雨湖区",
            "value": "430302",
            "center": "112.907427,27.86077"
        }, {
            "label": "岳塘区",
            "value": "430304",
            "center": "112.927707,27.828854"
        }, {
            "label": "湘潭县",
            "value": "430321",
            "center": "112.952829,27.778601"
        }, {
            "label": "湘乡市",
            "value": "430381",
            "center": "112.525217,27.734918"
        }, {
            "label": "韶山市",
            "value": "430382",
            "center": "112.52848,27.922682"
        }]
    }, {
        "label": "衡阳市",
        "value": "430400",
        "center": "112.607693,26.900358",
        "children": [{
            "label": "祁东县",
            "value": "430426",
            "center": "112.111192,26.787109"
        }, {
            "label": "耒阳市",
            "value": "430481",
            "center": "112.847215,26.414162"
        }, {
            "label": "常宁市",
            "value": "430482",
            "center": "112.396821,26.406773"
        }, {
            "label": "珠晖区",
            "value": "430405",
            "center": "112.626324,26.891063"
        }, {
            "label": "雁峰区",
            "value": "430406",
            "center": "112.612241,26.893694"
        }, {
            "label": "石鼓区",
            "value": "430407",
            "center": "112.607635,26.903908"
        }, {
            "label": "蒸湘区",
            "value": "430408",
            "center": "112.570608,26.89087"
        }, {
            "label": "南岳区",
            "value": "430412",
            "center": "112.734147,27.240536"
        }, {
            "label": "衡阳县",
            "value": "430421",
            "center": "112.379643,26.962388"
        }, {
            "label": "衡南县",
            "value": "430422",
            "center": "112.677459,26.739973"
        }, {
            "label": "衡山县",
            "value": "430423",
            "center": "112.86971,27.234808"
        }, {
            "label": "衡东县",
            "value": "430424",
            "center": "112.950412,27.083531"
        }]
    }, {
        "label": "岳阳市",
        "value": "430600",
        "center": "113.132855,29.37029",
        "children": [{
            "label": "汨罗市",
            "value": "430681",
            "center": "113.079419,28.803149"
        }, {
            "label": "临湘市",
            "value": "430682",
            "center": "113.450809,29.471594"
        }, {
            "label": "岳阳楼区",
            "value": "430602",
            "center": "113.120751,29.366784"
        }, {
            "label": "云溪区",
            "value": "430603",
            "center": "113.27387,29.473395"
        }, {
            "label": "君山区",
            "value": "430611",
            "center": "113.004082,29.438062"
        }, {
            "label": "岳阳县",
            "value": "430621",
            "center": "113.116073,29.144843"
        }, {
            "label": "华容县",
            "value": "430623",
            "center": "112.559369,29.524107"
        }, {
            "label": "湘阴县",
            "value": "430624",
            "center": "112.889748,28.677498"
        }, {
            "label": "平江县",
            "value": "430626",
            "center": "113.593751,28.701523"
        }]
    }]
}, {
    "label": "广东省",
    "value": "440000",
    "center": "113.280637,23.125178",
    "children": [{
        "label": "韶关市",
        "value": "440200",
        "center": "113.591544,24.801322",
        "children": [{
            "label": "武江区",
            "value": "440203",
            "center": "113.588289,24.80016"
        }, {
            "label": "浈江区",
            "value": "440204",
            "center": "113.599224,24.803977"
        }, {
            "label": "曲江区",
            "value": "440205",
            "center": "113.605582,24.680195"
        }, {
            "label": "始兴县",
            "value": "440222",
            "center": "114.067205,24.948364"
        }, {
            "label": "仁化县",
            "value": "440224",
            "center": "113.748627,25.088226"
        }, {
            "label": "翁源县",
            "value": "440229",
            "center": "114.131289,24.353887"
        }, {
            "label": "乳源瑶族自治县",
            "value": "440232",
            "center": "113.278417,24.776109"
        }, {
            "label": "新丰县",
            "value": "440233",
            "center": "114.207034,24.055412"
        }, {
            "label": "乐昌市",
            "value": "440281",
            "center": "113.352413,25.128445"
        }, {
            "label": "南雄市",
            "value": "440282",
            "center": "114.311231,25.115328"
        }]
    }, {
        "label": "河源市",
        "value": "441600",
        "center": "114.697802,23.746266",
        "children": [{
            "label": "源城区",
            "value": "441602",
            "center": "114.696828,23.746255"
        }, {
            "label": "紫金县",
            "value": "441621",
            "center": "115.184383,23.633744"
        }, {
            "label": "龙川县",
            "value": "441622",
            "center": "115.256415,24.101174"
        }, {
            "label": "连平县",
            "value": "441623",
            "center": "114.495952,24.364227"
        }, {
            "label": "和平县",
            "value": "441624",
            "center": "114.941473,24.44318"
        }, {
            "label": "东源县",
            "value": "441625",
            "center": "114.742711,23.789093"
        }]
    }, {
        "label": "阳江市",
        "value": "441700",
        "center": "111.975107,21.859222",
        "children": [{
            "label": "江城区",
            "value": "441702",
            "center": "111.968909,21.859182"
        }, {
            "label": "阳西县",
            "value": "441721",
            "center": "111.617556,21.75367"
        }, {
            "label": "阳东县",
            "value": "441723",
            "center": "112.011267,21.864728"
        }, {
            "label": "阳春市",
            "value": "441781",
            "center": "111.7905,22.169598"
        }]
    }, {
        "label": "清远市",
        "value": "441800",
        "center": "113.051227,23.685022",
        "children": [{
            "label": "清城区",
            "value": "441802",
            "center": "113.048698,23.688976"
        }, {
            "label": "佛冈县",
            "value": "441821",
            "center": "113.534094,23.866739"
        }, {
            "label": "阳山县",
            "value": "441823",
            "center": "112.634019,24.470286"
        }, {
            "label": "连山壮族瑶族自治县",
            "value": "441825",
            "center": "112.086555,24.567271"
        }, {
            "label": "连南瑶族自治县",
            "value": "441826",
            "center": "112.290808,24.719097"
        }, {
            "label": "清新县",
            "value": "441827",
            "center": "113.015203,23.736949"
        }, {
            "label": "英德市",
            "value": "441881",
            "center": "113.405404,24.18612"
        }, {
            "label": "连州市",
            "value": "441882",
            "center": "112.379271,24.783966"
        }]
    }, {
        "label": "东莞市",
        "value": "441900",
        "center": "113.846,23.1024",
        "children": [{
            "label": "东莞市",
            "value": "441901",
            "center": "113.746262,23.046237"
        }]
    }, {
        "label": "中山市",
        "value": "442000",
        "center": "113.308,22.6803",
        "children": [{
            "label": "中山市",
            "value": "442001",
            "center": "113.382391,22.521113"
        }]
    }, {
        "label": "潮州市",
        "value": "445100",
        "center": "116.632301,23.661701",
        "children": [{
            "label": "湘桥区",
            "value": "445102",
            "center": "116.63365,23.664675"
        }, {
            "label": "潮安县",
            "value": "445121",
            "center": "116.67931,23.461012"
        }, {
            "label": "饶平县",
            "value": "445122",
            "center": "117.00205,23.668171"
        }]
    }, {
        "label": "揭阳市",
        "value": "445200",
        "center": "116.355733,23.543778",
        "children": [{
            "label": "榕城区",
            "value": "445202",
            "center": "116.357045,23.535524"
        }, {
            "label": "揭东县",
            "value": "445221",
            "center": "116.412947,23.569887"
        }, {
            "label": "揭西县",
            "value": "445222",
            "center": "115.838708,23.4273"
        }, {
            "label": "惠来县",
            "value": "445224",
            "center": "116.295832,23.029834"
        }, {
            "label": "普宁市",
            "value": "445281",
            "center": "116.165082,23.29788"
        }]
    }, {
        "label": "云浮市",
        "value": "445300",
        "center": "112.044439,22.929801",
        "children": [{
            "label": "云城区",
            "value": "445302",
            "center": "112.04471,22.930827"
        }, {
            "label": "新兴县",
            "value": "445321",
            "center": "112.23083,22.703204"
        }, {
            "label": "郁南县",
            "value": "445322",
            "center": "111.535921,23.237709"
        }, {
            "label": "云安县",
            "value": "445323",
            "center": "112.005609,23.073152"
        }, {
            "label": "罗定市",
            "value": "445381",
            "center": "111.578201,22.765415"
        }]
    }, {
        "label": "深圳市",
        "value": "440300",
        "center": "114.085947,22.547",
        "children": [{
            "label": "罗湖区",
            "value": "440303",
            "center": "114.131459,22.548389"
        }, {
            "label": "福田区",
            "value": "440304",
            "center": "114.055072,22.521521"
        }, {
            "label": "南山区",
            "value": "440305",
            "center": "113.930413,22.533287"
        }, {
            "label": "宝安区",
            "value": "440306",
            "center": "113.883802,22.554996"
        }, {
            "label": "龙岗区",
            "value": "440307",
            "center": "114.246899,22.720974"
        }, {
            "label": "盐田区",
            "value": "440308",
            "center": "114.236739,22.557001"
        }]
    }, {
        "label": "珠海市",
        "value": "440400",
        "center": "113.553986,22.224979",
        "children": [{
            "label": "香洲区",
            "value": "440402",
            "center": "113.55027,22.271249"
        }, {
            "label": "斗门区",
            "value": "440403",
            "center": "113.297739,22.209117"
        }, {
            "label": "金湾区",
            "value": "440404",
            "center": "113.345071,22.139122"
        }]
    }, {
        "label": "汕头市",
        "value": "440500",
        "center": "116.708463,23.37102",
        "children": [{
            "label": "龙湖区",
            "value": "440507",
            "center": "116.732015,23.373754"
        }, {
            "label": "金平区",
            "value": "440511",
            "center": "116.703583,23.367071"
        }, {
            "label": "濠江区",
            "value": "440512",
            "center": "116.729528,23.279345"
        }, {
            "label": "潮阳区",
            "value": "440513",
            "center": "116.602602,23.262336"
        }, {
            "label": "潮南区",
            "value": "440514",
            "center": "116.423607,23.249798"
        }, {
            "label": "澄海区",
            "value": "440515",
            "center": "116.76336,23.46844"
        }, {
            "label": "南澳县",
            "value": "440523",
            "center": "117.027105,23.419562"
        }]
    }, {
        "label": "佛山市",
        "value": "440600",
        "center": "113.122717,23.028762",
        "children": [{
            "label": "禅城区",
            "value": "440604",
            "center": "113.112414,23.019643"
        }, {
            "label": "南海区",
            "value": "440605",
            "center": "113.145577,23.031562"
        }, {
            "label": "顺德区",
            "value": "440606",
            "center": "113.281826,22.75851"
        }, {
            "label": "三水区",
            "value": "440607",
            "center": "112.899414,23.16504"
        }, {
            "label": "高明区",
            "value": "440608",
            "center": "112.882123,22.893855"
        }]
    }, {
        "label": "江门市",
        "value": "440700",
        "center": "113.094942,22.590431",
        "children": [{
            "label": "蓬江区",
            "value": "440703",
            "center": "113.07859,22.59677"
        }, {
            "label": "江海区",
            "value": "440704",
            "center": "113.120601,22.572211"
        }, {
            "label": "新会区",
            "value": "440705",
            "center": "113.038584,22.520247"
        }, {
            "label": "台山市",
            "value": "440781",
            "center": "112.793414,22.250713"
        }, {
            "label": "开平市",
            "value": "440783",
            "center": "112.692262,22.366286"
        }, {
            "label": "鹤山市",
            "value": "440784",
            "center": "112.961795,22.768104"
        }, {
            "label": "恩平市",
            "value": "440785",
            "center": "112.314051,22.182956"
        }]
    }, {
        "label": "湛江市",
        "value": "440800",
        "center": "110.364977,21.274898",
        "children": [{
            "label": "赤坎区",
            "value": "440802",
            "center": "110.361634,21.273365"
        }, {
            "label": "霞山区",
            "value": "440803",
            "center": "110.406382,21.194229"
        }, {
            "label": "坡头区",
            "value": "440804",
            "center": "110.455632,21.24441"
        }, {
            "label": "麻章区",
            "value": "440811",
            "center": "110.329167,21.265997"
        }, {
            "label": "遂溪县",
            "value": "440823",
            "center": "110.255321,21.376915"
        }, {
            "label": "徐闻县",
            "value": "440825",
            "center": "110.175718,20.326083"
        }, {
            "label": "廉江市",
            "value": "440881",
            "center": "110.284961,21.611281"
        }, {
            "label": "雷州市",
            "value": "440882",
            "center": "110.088275,20.908523"
        }, {
            "label": "吴川市",
            "value": "440883",
            "center": "110.780508,21.428453"
        }]
    }, {
        "label": "茂名市",
        "value": "440900",
        "center": "110.919229,21.659751",
        "children": [{
            "label": "茂南区",
            "value": "440902",
            "center": "110.920542,21.660425"
        }, {
            "label": "茂港区",
            "value": "440903",
            "center": "114.049827,33.550795"
        }, {
            "label": "电白县",
            "value": "440923",
            "center": "111.007264,21.507219"
        }, {
            "label": "高州市",
            "value": "440981",
            "center": "110.853251,21.915153"
        }, {
            "label": "化州市",
            "value": "440982",
            "center": "110.63839,21.654953"
        }, {
            "label": "信宜市",
            "value": "440983",
            "center": "110.941656,22.352681"
        }]
    }, {
        "label": "肇庆市",
        "value": "441200",
        "center": "112.472529,23.051546",
        "children": [{
            "label": "端州区",
            "value": "441202",
            "center": "112.472329,23.052662"
        }, {
            "label": "鼎湖区",
            "value": "441203",
            "center": "112.565249,23.155822"
        }, {
            "label": "广宁县",
            "value": "441223",
            "center": "112.440419,23.631486"
        }, {
            "label": "怀集县",
            "value": "441224",
            "center": "112.182466,23.913072"
        }, {
            "label": "封开县",
            "value": "441225",
            "center": "111.502973,23.434731"
        }, {
            "label": "德庆县",
            "value": "441226",
            "center": "111.78156,23.141711"
        }, {
            "label": "高要市",
            "value": "441283",
            "center": "112.460846,23.027694"
        }, {
            "label": "四会市",
            "value": "441284",
            "center": "112.695028,23.340324"
        }]
    }, {
        "label": "惠州市",
        "value": "441300",
        "center": "114.412599,23.079404",
        "children": [{
            "label": "惠城区",
            "value": "441302",
            "center": "114.413978,23.079883"
        }, {
            "label": "惠阳区",
            "value": "441303",
            "center": "114.469444,22.78851"
        }, {
            "label": "博罗县",
            "value": "441322",
            "center": "114.284254,23.167575"
        }, {
            "label": "惠东县",
            "value": "441323",
            "center": "114.723092,22.983036"
        }, {
            "label": "龙门县",
            "value": "441324",
            "center": "114.259986,23.723894"
        }]
    }, {
        "label": "梅州市",
        "value": "441400",
        "center": "116.117582,24.299112",
        "children": [{
            "label": "梅江区",
            "value": "441402",
            "center": "116.12116,24.302593"
        }, {
            "label": "梅县",
            "value": "441421",
            "center": "115.729531,24.137101"
        }, {
            "label": "大埔县",
            "value": "441422",
            "center": "116.69552,24.351587"
        }, {
            "label": "丰顺县",
            "value": "441423",
            "center": "116.184419,23.752771"
        }, {
            "label": "五华县",
            "value": "441424",
            "center": "115.775004,23.925424"
        }, {
            "label": "平远县",
            "value": "441426",
            "center": "115.891729,24.569651"
        }, {
            "label": "蕉岭县",
            "value": "441427",
            "center": "116.170531,24.653313"
        }, {
            "label": "兴宁市",
            "value": "441481",
            "center": "115.731648,24.138077"
        }]
    }, {
        "label": "汕尾市",
        "value": "441500",
        "center": "115.364238,22.774485",
        "children": [{
            "label": "陆河县",
            "value": "441523",
            "center": "115.657565,23.302682"
        }, {
            "label": "陆丰市",
            "value": "441581",
            "center": "115.644203,22.946104"
        }, {
            "label": "城区",
            "value": "441502",
            "center": "115.363667,22.776227"
        }, {
            "label": "海丰县",
            "value": "441521",
            "center": "115.337324,22.971042"
        }]
    }, {
        "label": "广州市",
        "value": "440100",
        "center": "113.280637,23.125178",
        "children": [{
            "label": "从化市",
            "value": "440184",
            "center": "113.587386,23.545283"
        }, {
            "label": "荔湾区",
            "value": "440103",
            "center": "113.243038,23.124943"
        }, {
            "label": "越秀区",
            "value": "440104",
            "center": "113.280714,23.125624"
        }, {
            "label": "海珠区",
            "value": "440105",
            "center": "113.262008,23.103131"
        }, {
            "label": "天河区",
            "value": "440106",
            "center": "113.335367,23.13559"
        }, {
            "label": "白云区",
            "value": "440111",
            "center": "113.262831,23.162281"
        }, {
            "label": "黄埔区",
            "value": "440112",
            "center": "113.450761,23.103239"
        }, {
            "label": "番禺区",
            "value": "440113",
            "center": "113.364619,22.938582"
        }, {
            "label": "花都区",
            "value": "440114",
            "center": "113.211184,23.39205"
        }, {
            "label": "南沙区",
            "value": "440115",
            "center": "113.53738,22.794531"
        }, {
            "label": "萝岗区",
            "value": "440116",
            "center": "113.493279,23.17728"
        }, {
            "label": "增城市",
            "value": "440183",
            "center": "113.829579,23.290497"
        }]
    }]
}, {
    "label": "浙江省",
    "value": "330000",
    "center": "120.153576,30.287459",
    "children": [{
        "label": "绍兴市",
        "value": "330600",
        "center": "120.582112,29.997117",
        "children": [{
            "label": "越城区",
            "value": "330602",
            "center": "120.585315,29.996993"
        }, {
            "label": "绍兴县",
            "value": "330621",
            "center": "120.484901,30.086329"
        }, {
            "label": "新昌县",
            "value": "330624",
            "center": "120.905665,29.501205"
        }, {
            "label": "诸暨市",
            "value": "330681",
            "center": "120.244326,29.713662"
        }, {
            "label": "上虞市",
            "value": "330682",
            "center": "120.874185,30.016769"
        }, {
            "label": "嵊州市",
            "value": "330683",
            "center": "120.82888,29.586606"
        }]
    }, {
        "label": "金华市",
        "value": "330700",
        "center": "119.649506,29.089524",
        "children": [{
            "label": "婺城区",
            "value": "330702",
            "center": "119.571728,29.0872"
        }, {
            "label": "金东区",
            "value": "330703",
            "center": "119.69278,29.099723"
        }, {
            "label": "武义县",
            "value": "330723",
            "center": "119.816562,28.89267"
        }, {
            "label": "浦江县",
            "value": "330726",
            "center": "119.892222,29.452476"
        }, {
            "label": "磐安县",
            "value": "330727",
            "center": "120.450005,29.054548"
        }, {
            "label": "兰溪市",
            "value": "330781",
            "center": "119.460472,29.2084"
        }, {
            "label": "义乌市",
            "value": "330782",
            "center": "120.075106,29.306775"
        }, {
            "label": "东阳市",
            "value": "330783",
            "center": "120.241566,29.289648"
        }, {
            "label": "永康市",
            "value": "330784",
            "center": "120.047651,28.888555"
        }]
    }, {
        "label": "衢州市",
        "value": "330800",
        "center": "118.87263,28.941708",
        "children": [{
            "label": "柯城区",
            "value": "330802",
            "center": "118.873041,28.944539"
        }, {
            "label": "衢江区",
            "value": "330803",
            "center": "118.957683,28.973195"
        }, {
            "label": "常山县",
            "value": "330822",
            "center": "118.521654,28.900039"
        }, {
            "label": "开化县",
            "value": "330824",
            "center": "118.414435,29.136503"
        }, {
            "label": "龙游县",
            "value": "330825",
            "center": "119.172525,29.031364"
        }, {
            "label": "江山市",
            "value": "330881",
            "center": "118.627879,28.734674"
        }]
    }, {
        "label": "舟山市",
        "value": "330900",
        "center": "122.106863,30.016028",
        "children": [{
            "label": "定海区",
            "value": "330902",
            "center": "122.108496,30.016423"
        }, {
            "label": "普陀区",
            "value": "330903",
            "center": "122.301953,29.945614"
        }, {
            "label": "岱山县",
            "value": "330921",
            "center": "122.201132,30.242865"
        }, {
            "label": "嵊泗县",
            "value": "330922",
            "center": "122.457809,30.727166"
        }]
    }, {
        "label": "台州市",
        "value": "331000",
        "center": "121.428599,28.661378",
        "children": [{
            "label": "椒江区",
            "value": "331002",
            "center": "121.431049,28.67615"
        }, {
            "label": "黄岩区",
            "value": "331003",
            "center": "121.262138,28.64488"
        }, {
            "label": "路桥区",
            "value": "331004",
            "center": "121.37292,28.581799"
        }, {
            "label": "玉环县",
            "value": "331021",
            "center": "121.231805,28.13593"
        }, {
            "label": "三门县",
            "value": "331022",
            "center": "121.376429,29.118955"
        }, {
            "label": "天台县",
            "value": "331023",
            "center": "121.031227,29.141126"
        }, {
            "label": "仙居县",
            "value": "331024",
            "center": "120.735074,28.849213"
        }, {
            "label": "温岭市",
            "value": "331081",
            "center": "121.373611,28.368781"
        }, {
            "label": "临海市",
            "value": "331082",
            "center": "121.131229,28.845441"
        }]
    }, {
        "label": "丽水市",
        "value": "331100",
        "center": "119.921786,28.451993",
        "children": [{
            "label": "莲都区",
            "value": "331102",
            "center": "119.922293,28.451103"
        }, {
            "label": "青田县",
            "value": "331121",
            "center": "120.291939,28.135247"
        }, {
            "label": "缙云县",
            "value": "331122",
            "center": "120.078965,28.654208"
        }, {
            "label": "遂昌县",
            "value": "331123",
            "center": "119.27589,28.5924"
        }, {
            "label": "松阳县",
            "value": "331124",
            "center": "119.485292,28.449937"
        }, {
            "label": "云和县",
            "value": "331125",
            "center": "119.569458,28.111077"
        }, {
            "label": "庆元县",
            "value": "331126",
            "center": "119.067233,27.618231"
        }, {
            "label": "景宁畲族自治县",
            "value": "331127",
            "center": "119.634669,27.977247"
        }, {
            "label": "龙泉市",
            "value": "331181",
            "center": "119.132319,28.069177"
        }]
    }, {
        "label": "杭州市",
        "value": "330100",
        "center": "120.153576,30.287459",
        "children": [{
            "label": "经济技术开发区",
            "value": "330186",
            "center": "120.323617,30.310284"
        }, {
            "label": "上城区",
            "value": "330102",
            "center": "120.171465,30.250236"
        }, {
            "label": "下城区",
            "value": "330103",
            "center": "120.172763,30.276271"
        }, {
            "label": "江干区",
            "value": "330104",
            "center": "120.202633,30.266603"
        }, {
            "label": "拱墅区",
            "value": "330105",
            "center": "120.150053,30.314697"
        }, {
            "label": "西湖区",
            "value": "330106",
            "center": "120.147376,30.272934"
        }, {
            "label": "滨江区",
            "value": "330108",
            "center": "120.21062,30.206615"
        }, {
            "label": "萧山区",
            "value": "330109",
            "center": "120.27069,30.162932"
        }, {
            "label": "余杭区",
            "value": "330110",
            "center": "120.301737,30.421187"
        }, {
            "label": "桐庐县",
            "value": "330122",
            "center": "119.685045,29.797437"
        }, {
            "label": "淳安县",
            "value": "330127",
            "center": "119.044276,29.604177"
        }, {
            "label": "建德市",
            "value": "330182",
            "center": "119.279089,29.472284"
        }, {
            "label": "富阳市",
            "value": "330183",
            "center": "119.949869,30.049871"
        }, {
            "label": "临安市",
            "value": "330185",
            "center": "119.715101,30.231153"
        }]
    }, {
        "label": "宁波市",
        "value": "330200",
        "center": "121.549792,29.868388",
        "children": [{
            "label": "海曙区",
            "value": "330203",
            "center": "121.539698,29.874452"
        }, {
            "label": "江东区",
            "value": "330204",
            "center": "121.569748,29.860025"
        }, {
            "label": "江北区",
            "value": "330205",
            "center": "121.559282,29.888361"
        }, {
            "label": "北仑区",
            "value": "330206",
            "center": "121.831303,29.90944"
        }, {
            "label": "镇海区",
            "value": "330211",
            "center": "121.713162,29.952107"
        }, {
            "label": "鄞州区",
            "value": "330212",
            "center": "121.558436,29.831662"
        }, {
            "label": "象山县",
            "value": "330225",
            "center": "121.877091,29.470206"
        }, {
            "label": "宁海县",
            "value": "330226",
            "center": "121.432606,29.299836"
        }, {
            "label": "余姚市",
            "value": "330281",
            "center": "121.156294,30.045404"
        }, {
            "label": "慈溪市",
            "value": "330282",
            "center": "121.248052,30.177142"
        }, {
            "label": "奉化市",
            "value": "330283",
            "center": "121.41089,29.662348"
        }]
    }, {
        "label": "温州市",
        "value": "330300",
        "center": "120.672111,28.000575",
        "children": [{
            "label": "鹿城区",
            "value": "330302",
            "center": "120.674231,28.003352"
        }, {
            "label": "龙湾区",
            "value": "330303",
            "center": "120.763469,27.970254"
        }, {
            "label": "瓯海区",
            "value": "330304",
            "center": "120.637145,28.006444"
        }, {
            "label": "洞头县",
            "value": "330322",
            "center": "121.156181,27.836057"
        }, {
            "label": "永嘉县",
            "value": "330324",
            "center": "120.690968,28.153886"
        }, {
            "label": "平阳县",
            "value": "330326",
            "center": "120.564387,27.6693"
        }, {
            "label": "苍南县",
            "value": "330327",
            "center": "120.406256,27.507743"
        }, {
            "label": "文成县",
            "value": "330328",
            "center": "120.09245,27.789133"
        }, {
            "label": "泰顺县",
            "value": "330329",
            "center": "119.71624,27.557309"
        }, {
            "label": "瑞安市",
            "value": "330381",
            "center": "120.646171,27.779321"
        }, {
            "label": "乐清市",
            "value": "330382",
            "center": "120.967147,28.116083"
        }]
    }, {
        "label": "嘉兴市",
        "value": "330400",
        "center": "120.750865,30.762653",
        "children": [{
            "label": "南湖区",
            "value": "330402",
            "center": "120.749953,30.764652"
        }, {
            "label": "秀洲区",
            "value": "330411",
            "center": "120.720431,30.763323"
        }, {
            "label": "嘉善县",
            "value": "330421",
            "center": "120.921871,30.841352"
        }, {
            "label": "海盐县",
            "value": "330424",
            "center": "120.942017,30.522223"
        }, {
            "label": "海宁市",
            "value": "330481",
            "center": "120.688821,30.525544"
        }, {
            "label": "平湖市",
            "value": "330482",
            "center": "121.014666,30.698921"
        }, {
            "label": "桐乡市",
            "value": "330483",
            "center": "120.551085,30.629065"
        }]
    }, {
        "label": "湖州市",
        "value": "330500",
        "center": "120.102398,30.867198",
        "children": [{
            "label": "长兴县",
            "value": "330522",
            "center": "119.910122,31.00475"
        }, {
            "label": "安吉县",
            "value": "330523",
            "center": "119.687891,30.631974"
        }, {
            "label": "吴兴区",
            "value": "330502",
            "center": "120.101416,30.867252"
        }, {
            "label": "南浔区",
            "value": "330503",
            "center": "120.417195,30.872742"
        }, {
            "label": "德清县",
            "value": "330521",
            "center": "119.967662,30.534927"
        }]
    }]
}, {
    "label": "安徽省",
    "value": "340000",
    "center": "117.283042,31.86119",
    "children": [{
        "label": "淮北市",
        "value": "340600",
        "center": "116.794664,33.971707",
        "children": [{
            "label": "杜集区",
            "value": "340602",
            "center": "116.833925,33.991218"
        }, {
            "label": "相山区",
            "value": "340603",
            "center": "116.790775,33.970916"
        }, {
            "label": "烈山区",
            "value": "340604",
            "center": "116.809465,33.889529"
        }, {
            "label": "濉溪县",
            "value": "340621",
            "center": "116.767435,33.916407"
        }]
    }, {
        "label": "铜陵市",
        "value": "340700",
        "center": "117.816576,30.929935",
        "children": [{
            "label": "铜官山区",
            "value": "340702",
            "center": "117.817795,30.931447"
        }, {
            "label": "狮子山区",
            "value": "340703",
            "center": "117.851766,30.950185"
        }, {
            "label": "郊区",
            "value": "340711",
            "center": "117.80707,30.908927"
        }, {
            "label": "铜陵县",
            "value": "340721",
            "center": "117.791544,30.952823"
        }]
    }, {
        "label": "安庆市",
        "value": "340800",
        "center": "117.043551,30.50883",
        "children": [{
            "label": "迎江区",
            "value": "340802",
            "center": "117.044965,30.506375"
        }, {
            "label": "大观区",
            "value": "340803",
            "center": "117.034512,30.505632"
        }, {
            "label": "宜秀区",
            "value": "340811",
            "center": "117.070003,30.541323"
        }, {
            "label": "怀宁县",
            "value": "340822",
            "center": "116.828664,30.734994"
        }, {
            "label": "枞阳县",
            "value": "340823",
            "center": "117.250622,30.705963"
        }, {
            "label": "潜山县",
            "value": "340824",
            "center": "116.573666,30.638222"
        }, {
            "label": "太湖县",
            "value": "340825",
            "center": "116.305225,30.451869"
        }, {
            "label": "宿松县",
            "value": "340826",
            "center": "116.120204,30.158327"
        }, {
            "label": "望江县",
            "value": "340827",
            "center": "116.690927,30.12491"
        }, {
            "label": "岳西县",
            "value": "340828",
            "center": "116.360482,30.848502"
        }, {
            "label": "桐城市",
            "value": "340881",
            "center": "116.959656,31.050576"
        }]
    }, {
        "label": "黄山市",
        "value": "341000",
        "center": "118.317325,29.709239",
        "children": [{
            "label": "屯溪区",
            "value": "341002",
            "center": "118.317354,29.709186"
        }, {
            "label": "黄山区",
            "value": "341003",
            "center": "118.136639,30.294517"
        }, {
            "label": "徽州区",
            "value": "341004",
            "center": "118.339743,29.825201"
        }, {
            "label": "歙县",
            "value": "341021",
            "center": "118.428025,29.867748"
        }, {
            "label": "休宁县",
            "value": "341022",
            "center": "118.188531,29.788878"
        }, {
            "label": "黟县",
            "value": "341023",
            "center": "117.942911,29.923812"
        }, {
            "label": "祁门县",
            "value": "341024",
            "center": "117.717237,29.853472"
        }]
    }, {
        "label": "滁州市",
        "value": "341100",
        "center": "118.316264,32.303627",
        "children": [{
            "label": "琅琊区",
            "value": "341102",
            "center": "118.316475,32.303797"
        }, {
            "label": "南谯区",
            "value": "341103",
            "center": "118.296955,32.329841"
        }, {
            "label": "来安县",
            "value": "341122",
            "center": "118.433293,32.450231"
        }, {
            "label": "全椒县",
            "value": "341124",
            "center": "118.268576,32.09385"
        }, {
            "label": "定远县",
            "value": "341125",
            "center": "117.683713,32.527105"
        }, {
            "label": "凤阳县",
            "value": "341126",
            "center": "117.562461,32.867146"
        }, {
            "label": "天长市",
            "value": "341181",
            "center": "119.011212,32.6815"
        }, {
            "label": "明光市",
            "value": "341182",
            "center": "117.998048,32.781206"
        }]
    }, {
        "label": "阜阳市",
        "value": "341200",
        "center": "115.819729,32.896969",
        "children": [{
            "label": "颍州区",
            "value": "341202",
            "center": "115.813914,32.891238"
        }, {
            "label": "颍东区",
            "value": "341203",
            "center": "115.858747,32.908861"
        }, {
            "label": "颍泉区",
            "value": "341204",
            "center": "115.804525,32.924797"
        }, {
            "label": "临泉县",
            "value": "341221",
            "center": "115.261688,33.062698"
        }, {
            "label": "太和县",
            "value": "341222",
            "center": "115.627243,33.16229"
        }, {
            "label": "阜南县",
            "value": "341225",
            "center": "115.590534,32.638102"
        }, {
            "label": "颍上县",
            "value": "341226",
            "center": "116.259122,32.637065"
        }, {
            "label": "界首市",
            "value": "341282",
            "center": "115.362117,33.26153"
        }]
    }, {
        "label": "宿州市",
        "value": "341300",
        "center": "116.984084,33.633891",
        "children": [{
            "label": "埇桥区",
            "value": "341302",
            "center": "116.983309,33.633853"
        }, {
            "label": "砀山县",
            "value": "341321",
            "center": "116.351113,34.426247"
        }, {
            "label": "萧县",
            "value": "341322",
            "center": "116.945399,34.183266"
        }, {
            "label": "灵璧县",
            "value": "341323",
            "center": "117.551493,33.540629"
        }, {
            "label": "泗县",
            "value": "341324",
            "center": "117.885443,33.47758"
        }]
    }, {
        "label": "六安市",
        "value": "341500",
        "center": "116.507676,31.752889",
        "children": [{
            "label": "金安区",
            "value": "341502",
            "center": "116.503288,31.754491"
        }, {
            "label": "裕安区",
            "value": "341503",
            "center": "116.494543,31.750692"
        }, {
            "label": "寿县",
            "value": "341521",
            "center": "116.787141,32.573306"
        }, {
            "label": "霍邱县",
            "value": "341522",
            "center": "116.278875,32.341305"
        }, {
            "label": "舒城县",
            "value": "341523",
            "center": "116.944088,31.462848"
        }, {
            "label": "金寨县",
            "value": "341524",
            "center": "115.878514,31.681624"
        }, {
            "label": "霍山县",
            "value": "341525",
            "center": "116.333078,31.402456"
        }]
    }, {
        "label": "亳州市",
        "value": "341600",
        "center": "115.782939,33.869338",
        "children": [{
            "label": "谯城区",
            "value": "341602",
            "center": "115.781214,33.869284"
        }, {
            "label": "涡阳县",
            "value": "341621",
            "center": "116.211551,33.502831"
        }, {
            "label": "蒙城县",
            "value": "341622",
            "center": "116.560337,33.260814"
        }, {
            "label": "利辛县",
            "value": "341623",
            "center": "116.207782,33.143503"
        }]
    }, {
        "label": "池州市",
        "value": "341700",
        "center": "117.489157,30.656037",
        "children": [{
            "label": "贵池区",
            "value": "341702",
            "center": "117.488342,30.657378"
        }, {
            "label": "东至县",
            "value": "341721",
            "center": "117.021476,30.096568"
        }, {
            "label": "石台县",
            "value": "341722",
            "center": "117.482907,30.210324"
        }, {
            "label": "青阳县",
            "value": "341723",
            "center": "117.857395,30.63818"
        }]
    }, {
        "label": "宣城市",
        "value": "341800",
        "center": "118.757995,30.945667",
        "children": [{
            "label": "宣州区",
            "value": "341802",
            "center": "118.758412,30.946003"
        }, {
            "label": "郎溪县",
            "value": "341821",
            "center": "119.185024,31.127834"
        }, {
            "label": "广德县",
            "value": "341822",
            "center": "119.417521,30.893116"
        }, {
            "label": "泾县",
            "value": "341823",
            "center": "118.412397,30.685975"
        }, {
            "label": "绩溪县",
            "value": "341824",
            "center": "118.594705,30.065267"
        }, {
            "label": "旌德县",
            "value": "341825",
            "center": "118.543081,30.288057"
        }, {
            "label": "宁国市",
            "value": "341881",
            "center": "118.983407,30.626529"
        }]
    }, {
        "label": "合肥市",
        "value": "340100",
        "center": "117.283042,31.86119",
        "children": [{
            "label": "瑶海区",
            "value": "340102",
            "center": "117.315358,31.86961"
        }, {
            "label": "庐阳区",
            "value": "340103",
            "center": "117.283776,31.869011"
        }, {
            "label": "蜀山区",
            "value": "340104",
            "center": "117.262072,31.855868"
        }, {
            "label": "包河区",
            "value": "340111",
            "center": "117.285751,31.82956"
        }, {
            "label": "长丰县",
            "value": "340121",
            "center": "117.164699,32.478548"
        }, {
            "label": "肥东县",
            "value": "340122",
            "center": "117.463222,31.883992"
        }, {
            "label": "肥西县",
            "value": "340123",
            "center": "117.166118,31.719646"
        }, {
            "label": "庐江县",
            "value": "340124",
            "center": "117.289844,31.251488"
        }, {
            "label": "巢湖市",
            "value": "340181",
            "center": "117.874155,31.600518"
        }]
    }, {
        "label": "芜湖市",
        "value": "340200",
        "center": "118.376451,31.326319",
        "children": [{
            "label": "镜湖区",
            "value": "340202",
            "center": "118.376343,31.32559"
        }, {
            "label": "弋江区",
            "value": "340203",
            "center": "118.377476,31.313394"
        }, {
            "label": "鸠江区",
            "value": "340207",
            "center": "118.400174,31.362716"
        }, {
            "label": "三山区",
            "value": "340208",
            "center": "118.233987,31.225423"
        }, {
            "label": "芜湖县",
            "value": "340221",
            "center": "118.572301,31.145262"
        }, {
            "label": "繁昌县",
            "value": "340222",
            "center": "118.201349,31.080896"
        }, {
            "label": "南陵县",
            "value": "340223",
            "center": "118.337104,30.919638"
        }, {
            "label": "无为县",
            "value": "340225",
            "center": "117.911432,31.303075"
        }]
    }, {
        "label": "蚌埠市",
        "value": "340300",
        "center": "117.363228,32.939667",
        "children": [{
            "label": "龙子湖区",
            "value": "340302",
            "center": "117.382312,32.950452"
        }, {
            "label": "蚌山区",
            "value": "340303",
            "center": "117.355789,32.938066"
        }, {
            "label": "禹会区",
            "value": "340304",
            "center": "117.35259,32.931933"
        }, {
            "label": "淮上区",
            "value": "340311",
            "center": "117.34709,32.963147"
        }, {
            "label": "怀远县",
            "value": "340321",
            "center": "117.200171,32.956934"
        }, {
            "label": "五河县",
            "value": "340322",
            "center": "117.888809,33.146202"
        }, {
            "label": "固镇县",
            "value": "340323",
            "center": "117.315962,33.318679"
        }]
    }, {
        "label": "淮南市",
        "value": "340400",
        "center": "117.018329,32.647574",
        "children": [{
            "label": "大通区",
            "value": "340402",
            "center": "117.053314,32.631519"
        }, {
            "label": "田家庵区",
            "value": "340403",
            "center": "117.017349,32.647277"
        }, {
            "label": "谢家集区",
            "value": "340404",
            "center": "116.859188,32.600037"
        }, {
            "label": "八公山区",
            "value": "340405",
            "center": "116.83349,32.631379"
        }, {
            "label": "潘集区",
            "value": "340406",
            "center": "116.834715,32.77208"
        }, {
            "label": "凤台县",
            "value": "340421",
            "center": "116.71105,32.709444"
        }]
    }, {
        "label": "马鞍山市",
        "value": "340500",
        "center": "118.507906,31.689362",
        "children": [{
            "label": "当涂县",
            "value": "340521",
            "center": "118.497972,31.571213"
        }, {
            "label": "含山县",
            "value": "340522",
            "center": "118.101421,31.735598"
        }, {
            "label": "和县",
            "value": "340523",
            "center": "118.353667,31.742293"
        }, {
            "label": "博望区",
            "value": "340596",
            "center": "118.844538,31.558471"
        }, {
            "label": "花山区",
            "value": "340503",
            "center": "118.492565,31.71971"
        }, {
            "label": "雨山区",
            "value": "340504",
            "center": "118.498578,31.682132"
        }]
    }]
}, {
    "label": "上海",
    "value": "310000",
    "center": "121.472644,31.231706",
    "children": [{
        "label": "上海市",
        "value": "310100",
        "center": "121.472644,31.231706",
        "children": [{
            "label": "黄浦区",
            "value": "310101",
            "center": "121.457,31.2211"
        }, {
            "label": "徐汇区",
            "value": "310104",
            "center": "121.45,31.1536"
        }, {
            "label": "长宁区",
            "value": "310105",
            "center": "121.435,31.2252"
        }, {
            "label": "静安区",
            "value": "310106",
            "center": "121.455,31.3135"
        }, {
            "label": "普陀区",
            "value": "310107",
            "center": "121.355,31.2998"
        }, {
            "label": "闸北区",
            "value": "310108",
            "center": "121.473814,31.248357"
        }, {
            "label": "虹口区",
            "value": "310109",
            "center": "121.462,31.2798"
        }, {
            "label": "杨浦区",
            "value": "310110",
            "center": "121.52,31.2628"
        }, {
            "label": "闵行区",
            "value": "310112",
            "center": "121.352,31.1187"
        }, {
            "label": "宝山区",
            "value": "310113",
            "center": "121.498,31.3375"
        }, {
            "label": "嘉定区",
            "value": "310114",
            "center": "121.203,31.3365"
        }, {
            "label": "浦东新区",
            "value": "310115",
            "center": "121.836,30.9316"
        }, {
            "label": "金山区",
            "value": "310116",
            "center": "121.109,30.8714"
        }, {
            "label": "松江区",
            "value": "310117",
            "center": "121.196,31.0434"
        }, {
            "label": "青浦区",
            "value": "310118",
            "center": "121.257,31.2071"
        }, {
            "label": "奉贤区",
            "value": "310120",
            "center": "121.521,30.8585"
        }, {
            "label": "崇明县",
            "value": "310230",
            "center": "121.397516,31.626946"
        }]
    }]
}, {
    "label": "江苏省",
    "value": "320000",
    "center": "118.767413,32.041544",
    "children": [{
        "label": "无锡市",
        "value": "320200",
        "center": "120.301663,31.574729",
        "children": [{
            "label": "崇安区",
            "value": "320202",
            "center": "120.320393,31.586756"
        }, {
            "label": "南长区",
            "value": "320203",
            "center": "120.308786,31.546937"
        }, {
            "label": "北塘区",
            "value": "320204",
            "center": "120.29037,31.605355"
        }, {
            "label": "锡山区",
            "value": "320205",
            "center": "120.357858,31.589715"
        }, {
            "label": "惠山区",
            "value": "320206",
            "center": "120.298433,31.680335"
        }, {
            "label": "滨湖区",
            "value": "320211",
            "center": "120.283811,31.527276"
        }, {
            "label": "江阴市",
            "value": "320281",
            "center": "120.286129,31.921345"
        }, {
            "label": "宜兴市",
            "value": "320282",
            "center": "119.823308,31.340637"
        }]
    }, {
        "label": "盐城市",
        "value": "320900",
        "center": "120.139998,33.377631",
        "children": [{
            "label": "亭湖区",
            "value": "320902",
            "center": "120.197358,33.390536"
        }, {
            "label": "盐都区",
            "value": "320903",
            "center": "120.153712,33.338283"
        }, {
            "label": "响水县",
            "value": "320921",
            "center": "119.578364,34.199479"
        }, {
            "label": "滨海县",
            "value": "320922",
            "center": "119.82083,33.990334"
        }, {
            "label": "阜宁县",
            "value": "320923",
            "center": "119.802527,33.759325"
        }, {
            "label": "射阳县",
            "value": "320924",
            "center": "120.229986,33.758361"
        }, {
            "label": "建湖县",
            "value": "320925",
            "center": "119.7886,33.439067"
        }, {
            "label": "东台市",
            "value": "320981",
            "center": "120.320328,32.868426"
        }, {
            "label": "大丰市",
            "value": "320982",
            "center": "120.50085,33.200333"
        }]
    }, {
        "label": "扬州市",
        "value": "321000",
        "center": "119.421003,32.393159",
        "children": [{
            "label": "广陵区",
            "value": "321002",
            "center": "119.442267,32.392154"
        }, {
            "label": "邗江区",
            "value": "321003",
            "center": "119.397777,32.377899"
        }, {
            "label": "宝应县",
            "value": "321023",
            "center": "119.321284,33.23694"
        }, {
            "label": "仪征市",
            "value": "321081",
            "center": "119.182443,32.271965"
        }, {
            "label": "高邮市",
            "value": "321084",
            "center": "119.443842,32.785164"
        }, {
            "label": "江都市",
            "value": "321088",
            "center": "119.567481,32.426564"
        }]
    }, {
        "label": "镇江市",
        "value": "321100",
        "center": "119.452753,32.204402",
        "children": [{
            "label": "京口区",
            "value": "321102",
            "center": "119.454571,32.206191"
        }, {
            "label": "润州区",
            "value": "321111",
            "center": "119.414877,32.213501"
        }, {
            "label": "丹徒区",
            "value": "321112",
            "center": "119.433883,32.128972"
        }, {
            "label": "丹阳市",
            "value": "321181",
            "center": "119.581911,31.991459"
        }, {
            "label": "扬中市",
            "value": "321182",
            "center": "119.828054,32.237266"
        }, {
            "label": "句容市",
            "value": "321183",
            "center": "119.167135,31.947355"
        }]
    }, {
        "label": "泰州市",
        "value": "321200",
        "center": "119.915176,32.484882",
        "children": [{
            "label": "海陵区",
            "value": "321202",
            "center": "119.920187,32.488406"
        }, {
            "label": "高港区",
            "value": "321203",
            "center": "119.88166,32.315701"
        }, {
            "label": "兴化市",
            "value": "321281",
            "center": "119.840162,32.938065"
        }, {
            "label": "靖江市",
            "value": "321282",
            "center": "120.26825,32.018168"
        }, {
            "label": "泰兴市",
            "value": "321283",
            "center": "120.020228,32.168784"
        }, {
            "label": "姜堰市",
            "value": "321284",
            "center": "120.148208,32.508483"
        }]
    }, {
        "label": "宿迁市",
        "value": "321300",
        "center": "118.275162,33.963008",
        "children": [{
            "label": "宿城区",
            "value": "321302",
            "center": "118.278984,33.937726"
        }, {
            "label": "宿豫区",
            "value": "321311",
            "center": "118.330012,33.941071"
        }, {
            "label": "沭阳县",
            "value": "321322",
            "center": "118.775889,34.129097"
        }, {
            "label": "泗阳县",
            "value": "321323",
            "center": "118.681284,33.711433"
        }, {
            "label": "泗洪县",
            "value": "321324",
            "center": "118.211824,33.456538"
        }]
    }, {
        "label": "南京市",
        "value": "320100",
        "center": "118.767413,32.041544",
        "children": [{
            "label": "江宁区",
            "value": "320115",
            "center": "118.850621,31.953418"
        }, {
            "label": "六合区",
            "value": "320116",
            "center": "118.85065,32.340655"
        }, {
            "label": "溧水县",
            "value": "320124",
            "center": "119.028732,31.653061"
        }, {
            "label": "高淳县",
            "value": "320125",
            "center": "118.87589,31.327132"
        }, {
            "label": "玄武区",
            "value": "320102",
            "center": "118.792199,32.050678"
        }, {
            "label": "白下区",
            "value": "320103",
            "center": "118.785298,32.034943"
        }, {
            "label": "秦淮区",
            "value": "320104",
            "center": "118.786088,32.033818"
        }, {
            "label": "建邺区",
            "value": "320105",
            "center": "118.732688,32.004538"
        }, {
            "label": "鼓楼区",
            "value": "320106",
            "center": "118.769739,32.066966"
        }, {
            "label": "下关区",
            "value": "320107",
            "center": "114.348307,34.788561"
        }, {
            "label": "浦口区",
            "value": "320111",
            "center": "118.625307,32.05839"
        }, {
            "label": "栖霞区",
            "value": "320113",
            "center": "118.808702,32.102147"
        }, {
            "label": "雨花台区",
            "value": "320114",
            "center": "118.77207,31.995946"
        }]
    }, {
        "label": "徐州市",
        "value": "320300",
        "center": "117.184811,34.261792",
        "children": [{
            "label": "鼓楼区",
            "value": "320302",
            "center": "117.192941,34.269397"
        }, {
            "label": "云龙区",
            "value": "320303",
            "center": "117.194589,34.254805"
        }, {
            "label": "贾汪区",
            "value": "320305",
            "center": "117.450212,34.441642"
        }, {
            "label": "泉山区",
            "value": "320311",
            "center": "117.182225,34.262249"
        }, {
            "label": "铜山区",
            "value": "320312",
            "center": "117.183894,34.19288"
        }, {
            "label": "丰县",
            "value": "320321",
            "center": "116.592888,34.696946"
        }, {
            "label": "沛县",
            "value": "320322",
            "center": "116.937182,34.729044"
        }, {
            "label": "睢宁县",
            "value": "320324",
            "center": "117.95066,33.899222"
        }, {
            "label": "新沂市",
            "value": "320381",
            "center": "118.345828,34.368779"
        }, {
            "label": "邳州市",
            "value": "320382",
            "center": "117.963923,34.314708"
        }]
    }, {
        "label": "常州市",
        "value": "320400",
        "center": "119.946973,31.772752",
        "children": [{
            "label": "天宁区",
            "value": "320402",
            "center": "119.963783,31.779632"
        }, {
            "label": "钟楼区",
            "value": "320404",
            "center": "119.948388,31.78096"
        }, {
            "label": "戚墅堰区",
            "value": "320405",
            "center": "120.064254,31.722638"
        }, {
            "label": "新北区",
            "value": "320411",
            "center": "119.974654,31.824664"
        }, {
            "label": "武进区",
            "value": "320412",
            "center": "119.958773,31.718566"
        }, {
            "label": "溧阳市",
            "value": "320481",
            "center": "119.487816,31.427081"
        }, {
            "label": "金坛市",
            "value": "320482",
            "center": "119.573395,31.744399"
        }]
    }, {
        "label": "苏州市",
        "value": "320500",
        "center": "120.619585,31.299379",
        "children": [{
            "label": "姑苏区",
            "value": "320503",
            "center": "120.617296,31.336392"
        }, {
            "label": "虎丘区",
            "value": "320505",
            "center": "120.566833,31.294845"
        }, {
            "label": "吴中区",
            "value": "320506",
            "center": "120.624621,31.270839"
        }, {
            "label": "相城区",
            "value": "320507",
            "center": "120.618956,31.396684"
        }, {
            "label": "常熟市",
            "value": "320581",
            "center": "120.74852,31.658156"
        }, {
            "label": "张家港市",
            "value": "320582",
            "center": "120.543441,31.865553"
        }, {
            "label": "昆山市",
            "value": "320583",
            "center": "120.958137,31.381925"
        }, {
            "label": "吴江区",
            "value": "320584",
            "center": "120.645158,31.138677"
        }, {
            "label": "太仓市",
            "value": "320585",
            "center": "121.112275,31.452568"
        }]
    }, {
        "label": "南通市",
        "value": "320600",
        "center": "120.864608,32.016212",
        "children": [{
            "label": "崇川区",
            "value": "320602",
            "center": "120.86635,32.015278"
        }, {
            "label": "港闸区",
            "value": "320611",
            "center": "120.8339,32.040299"
        }, {
            "label": "通州区",
            "value": "320612",
            "center": "121.073171,32.084287"
        }, {
            "label": "海安县",
            "value": "320621",
            "center": "120.465995,32.540289"
        }, {
            "label": "如东县",
            "value": "320623",
            "center": "121.186088,32.311832"
        }, {
            "label": "启东市",
            "value": "320681",
            "center": "121.659724,31.810158"
        }, {
            "label": "如皋市",
            "value": "320682",
            "center": "120.566324,32.391591"
        }, {
            "label": "海门市",
            "value": "320684",
            "center": "121.176609,31.893528"
        }]
    }, {
        "label": "连云港市",
        "value": "320700",
        "center": "119.178821,34.600018",
        "children": [{
            "label": "连云区",
            "value": "320703",
            "center": "119.366487,34.739529"
        }, {
            "label": "新浦区",
            "value": "320705",
            "center": "119.16235,34.58325"
        }, {
            "label": "海州区",
            "value": "320706",
            "center": "119.179793,34.601584"
        }, {
            "label": "赣榆县",
            "value": "320721",
            "center": "119.128774,34.839154"
        }, {
            "label": "东海县",
            "value": "320722",
            "center": "118.766489,34.522859"
        }, {
            "label": "灌云县",
            "value": "320723",
            "center": "119.255741,34.298436"
        }, {
            "label": "灌南县",
            "value": "320724",
            "center": "119.352331,34.092553"
        }]
    }, {
        "label": "淮安市",
        "value": "320800",
        "center": "119.021265,33.597506",
        "children": [{
            "label": "金湖县",
            "value": "320831",
            "center": "119.020584,33.025433"
        }, {
            "label": "清河区",
            "value": "320802",
            "center": "119.001547,33.599587"
        }, {
            "label": "淮安区",
            "value": "320803",
            "center": "119.141099,33.502868"
        }, {
            "label": "淮阴区",
            "value": "320804",
            "center": "119.034725,33.631892"
        }, {
            "label": "青浦区",
            "value": "320811",
            "center": "121.124178,31.150681"
        }, {
            "label": "涟水县",
            "value": "320826",
            "center": "119.260227,33.781331"
        }, {
            "label": "洪泽县",
            "value": "320829",
            "center": "118.873241,33.294214"
        }, {
            "label": "盱眙县",
            "value": "320830",
            "center": "118.54436,33.011971"
        }]
    }]
}, {
    "label": "吉林省",
    "value": "220000",
    "center": "125.3245,43.886841",
    "children": [{
        "label": "长春市",
        "value": "220100",
        "center": "125.3245,43.886841",
        "children": [{
            "label": "南关区",
            "value": "220102",
            "center": "125.337237,43.890235"
        }, {
            "label": "宽城区",
            "value": "220103",
            "center": "125.342828,43.903823"
        }, {
            "label": "朝阳区",
            "value": "220104",
            "center": "125.318042,43.86491"
        }, {
            "label": "二道区",
            "value": "220105",
            "center": "125.384727,43.870824"
        }, {
            "label": "绿园区",
            "value": "220106",
            "center": "125.272467,43.892177"
        }, {
            "label": "双阳区",
            "value": "220112",
            "center": "125.659018,43.525168"
        }, {
            "label": "农安县",
            "value": "220122",
            "center": "125.175287,44.431258"
        }, {
            "label": "九台市",
            "value": "220181",
            "center": "125.844682,44.157155"
        }, {
            "label": "榆树市",
            "value": "220182",
            "center": "126.550107,44.827642"
        }, {
            "label": "德惠市",
            "value": "220183",
            "center": "125.703327,44.533909"
        }]
    }, {
        "label": "吉林市",
        "value": "220200",
        "center": "126.55302,43.843577",
        "children": [{
            "label": "昌邑区",
            "value": "220202",
            "center": "126.570766,43.851118"
        }, {
            "label": "龙潭区",
            "value": "220203",
            "center": "126.561429,43.909755"
        }, {
            "label": "船营区",
            "value": "220204",
            "center": "126.55239,43.843804"
        }, {
            "label": "丰满区",
            "value": "220211",
            "center": "126.560759,43.816594"
        }, {
            "label": "永吉县",
            "value": "220221",
            "center": "126.501622,43.667416"
        }, {
            "label": "蛟河市",
            "value": "220281",
            "center": "127.342739,43.720579"
        }, {
            "label": "桦甸市",
            "value": "220282",
            "center": "126.745445,42.972093"
        }, {
            "label": "舒兰市",
            "value": "220283",
            "center": "126.947813,44.410906"
        }, {
            "label": "磐石市",
            "value": "220284",
            "center": "126.059929,42.942476"
        }]
    }, {
        "label": "四平市",
        "value": "220300",
        "center": "124.370785,43.170344",
        "children": [{
            "label": "铁西区",
            "value": "220302",
            "center": "124.360894,43.176263"
        }, {
            "label": "铁东区",
            "value": "220303",
            "center": "124.388464,43.16726"
        }, {
            "label": "梨树县",
            "value": "220322",
            "center": "124.335802,43.30831"
        }, {
            "label": "伊通满族自治县",
            "value": "220323",
            "center": "125.303124,43.345464"
        }, {
            "label": "公主岭市",
            "value": "220381",
            "center": "124.817588,43.509474"
        }, {
            "label": "双辽市",
            "value": "220382",
            "center": "123.505283,43.518275"
        }]
    }, {
        "label": "辽源市",
        "value": "220400",
        "center": "125.145349,42.902692",
        "children": [{
            "label": "龙山区",
            "value": "220402",
            "center": "125.145164,42.902702"
        }, {
            "label": "西安区",
            "value": "220403",
            "center": "125.151424,42.920415"
        }, {
            "label": "东丰县",
            "value": "220421",
            "center": "125.529623,42.675228"
        }, {
            "label": "东辽县",
            "value": "220422",
            "center": "124.991995,42.927724"
        }]
    }, {
        "label": "通化市",
        "value": "220500",
        "center": "125.936501,41.721177",
        "children": [{
            "label": "东昌区",
            "value": "220502",
            "center": "125.936716,41.721233"
        }, {
            "label": "二道江区",
            "value": "220503",
            "center": "126.045987,41.777564"
        }, {
            "label": "通化县",
            "value": "220521",
            "center": "125.753121,41.677918"
        }, {
            "label": "辉南县",
            "value": "220523",
            "center": "126.042821,42.683459"
        }, {
            "label": "柳河县",
            "value": "220524",
            "center": "125.740536,42.281484"
        }, {
            "label": "梅河口市",
            "value": "220581",
            "center": "125.687336,42.530002"
        }, {
            "label": "集安市",
            "value": "220582",
            "center": "126.186204,41.126276"
        }]
    }, {
        "label": "白山市",
        "value": "220600",
        "center": "126.427839,41.942505",
        "children": [{
            "label": "浑江区",
            "value": "220602",
            "center": "126.428035,41.943065"
        }, {
            "label": "江源区",
            "value": "220605",
            "center": "126.584229,42.048109"
        }, {
            "label": "抚松县",
            "value": "220621",
            "center": "127.273796,42.332643"
        }, {
            "label": "靖宇县",
            "value": "220622",
            "center": "126.808386,42.389689"
        }, {
            "label": "长白朝鲜族自治县",
            "value": "220623",
            "center": "128.203384,41.419361"
        }, {
            "label": "临江市",
            "value": "220681",
            "center": "126.919296,41.810689"
        }]
    }, {
        "label": "松原市",
        "value": "220700",
        "center": "124.823608,45.118243",
        "children": [{
            "label": "宁江区",
            "value": "220702",
            "center": "124.827851,45.176498"
        }, {
            "label": "前郭尔罗斯蒙古族自治县",
            "value": "220721",
            "center": "124.826808,45.116288"
        }, {
            "label": "长岭县",
            "value": "220722",
            "center": "123.985184,44.276579"
        }, {
            "label": "乾安县",
            "value": "220723",
            "center": "124.024361,45.006846"
        }, {
            "label": "扶余县",
            "value": "220724",
            "center": "126.042758,44.986199"
        }]
    }, {
        "label": "白城市",
        "value": "220800",
        "center": "122.841114,45.619026",
        "children": [{
            "label": "洮北区",
            "value": "220802",
            "center": "122.842499,45.619253"
        }, {
            "label": "镇赉县",
            "value": "220821",
            "center": "123.202246,45.846089"
        }, {
            "label": "通榆县",
            "value": "220822",
            "center": "123.088543,44.80915"
        }, {
            "label": "洮南市",
            "value": "220881",
            "center": "122.783779,45.339113"
        }, {
            "label": "大安市",
            "value": "220882",
            "center": "124.291512,45.507648"
        }]
    }, {
        "label": "延边朝鲜族自治州",
        "value": "222400",
        "center": "129.513228,42.904823",
        "children": [{
            "label": "延吉市",
            "value": "222401",
            "center": "129.51579,42.906964"
        }, {
            "label": "图们市",
            "value": "222402",
            "center": "129.846701,42.966621"
        }, {
            "label": "敦化市",
            "value": "222403",
            "center": "128.22986,43.366921"
        }, {
            "label": "珲春市",
            "value": "222404",
            "center": "130.365787,42.871057"
        }, {
            "label": "龙井市",
            "value": "222405",
            "center": "129.425747,42.771029"
        }, {
            "label": "和龙市",
            "value": "222406",
            "center": "129.008748,42.547004"
        }, {
            "label": "汪清县",
            "value": "222424",
            "center": "129.766161,43.315426"
        }, {
            "label": "安图县",
            "value": "222426",
            "center": "128.901865,43.110994"
        }]
    }]
}, {
    "label": "辽宁省",
    "value": "210000",
    "center": "123.429096,41.796767",
    "children": [{
        "label": "阜新市",
        "value": "210900",
        "center": "121.648962,42.011796",
        "children": [{
            "label": "海州区",
            "value": "210902",
            "center": "121.657639,42.011162"
        }, {
            "label": "新邱区",
            "value": "210903",
            "center": "121.790541,42.086603"
        }, {
            "label": "太平区",
            "value": "210904",
            "center": "121.677575,42.011145"
        }, {
            "label": "清河门区",
            "value": "210905",
            "center": "121.42018,41.780477"
        }, {
            "label": "细河区",
            "value": "210911",
            "center": "121.654791,42.019218"
        }, {
            "label": "阜新蒙古族自治县",
            "value": "210921",
            "center": "121.743125,42.058607"
        }, {
            "label": "彰武县",
            "value": "210922",
            "center": "122.537444,42.384823"
        }]
    }, {
        "label": "辽阳市",
        "value": "211000",
        "center": "123.18152,41.269402",
        "children": [{
            "label": "白塔区",
            "value": "211002",
            "center": "123.172611,41.26745"
        }, {
            "label": "文圣区",
            "value": "211003",
            "center": "123.188227,41.266765"
        }, {
            "label": "宏伟区",
            "value": "211004",
            "center": "123.200461,41.205747"
        }, {
            "label": "弓长岭区",
            "value": "211005",
            "center": "123.431633,41.157831"
        }, {
            "label": "太子河区",
            "value": "211011",
            "center": "123.185336,41.251682"
        }, {
            "label": "辽阳县",
            "value": "211021",
            "center": "123.079674,41.216479"
        }, {
            "label": "灯塔市",
            "value": "211081",
            "center": "123.325864,41.427836"
        }]
    }, {
        "label": "盘锦市",
        "value": "211100",
        "center": "122.06957,41.124484",
        "children": [{
            "label": "双台子区",
            "value": "211102",
            "center": "122.055733,41.190365"
        }, {
            "label": "兴隆台区",
            "value": "211103",
            "center": "122.071624,41.122423"
        }, {
            "label": "大洼县",
            "value": "211121",
            "center": "122.071708,40.994428"
        }, {
            "label": "盘山县",
            "value": "211122",
            "center": "121.98528,41.240701"
        }]
    }, {
        "label": "铁岭市",
        "value": "211200",
        "center": "123.844279,42.290585",
        "children": [{
            "label": "银州区",
            "value": "211202",
            "center": "123.844877,42.292278"
        }, {
            "label": "清河区",
            "value": "211204",
            "center": "124.14896,42.542978"
        }, {
            "label": "铁岭县",
            "value": "211221",
            "center": "123.725669,42.223316"
        }, {
            "label": "西丰县",
            "value": "211223",
            "center": "124.72332,42.738091"
        }, {
            "label": "昌图县",
            "value": "211224",
            "center": "124.11017,42.784441"
        }, {
            "label": "调兵山市",
            "value": "211281",
            "center": "123.545366,42.450734"
        }, {
            "label": "开原市",
            "value": "211282",
            "center": "124.045551,42.542141"
        }]
    }, {
        "label": "朝阳市",
        "value": "211300",
        "center": "120.451176,41.576758",
        "children": [{
            "label": "双塔区",
            "value": "211302",
            "center": "120.44877,41.579389"
        }, {
            "label": "龙城区",
            "value": "211303",
            "center": "120.413376,41.576749"
        }, {
            "label": "朝阳县",
            "value": "211321",
            "center": "120.404217,41.526342"
        }, {
            "label": "建平县",
            "value": "211322",
            "center": "119.642363,41.402576"
        }, {
            "label": "喀喇沁左翼蒙古族自治县",
            "value": "211324",
            "center": "119.744883,41.125428"
        }, {
            "label": "北票市",
            "value": "211381",
            "center": "120.766951,41.803286"
        }, {
            "label": "凌源市",
            "value": "211382",
            "center": "119.404789,41.243086"
        }]
    }, {
        "label": "葫芦岛市",
        "value": "211400",
        "center": "120.856394,40.755572",
        "children": [{
            "label": "连山区",
            "value": "211402",
            "center": "120.85937,40.755143"
        }, {
            "label": "龙港区",
            "value": "211403",
            "center": "120.838569,40.709991"
        }, {
            "label": "南票区",
            "value": "211404",
            "center": "120.752314,41.098813"
        }, {
            "label": "绥中县",
            "value": "211421",
            "center": "120.342112,40.328407"
        }, {
            "label": "建昌县",
            "value": "211422",
            "center": "119.807776,40.812871"
        }, {
            "label": "兴城市",
            "value": "211481",
            "center": "120.729365,40.619413"
        }]
    }, {
        "label": "沈阳市",
        "value": "210100",
        "center": "123.429096,41.796767",
        "children": [{
            "label": "和平区",
            "value": "210102",
            "center": "123.406664,41.788074"
        }, {
            "label": "沈河区",
            "value": "210103",
            "center": "123.445696,41.795591"
        }, {
            "label": "大东区",
            "value": "210104",
            "center": "123.469956,41.808503"
        }, {
            "label": "皇姑区",
            "value": "210105",
            "center": "123.405677,41.822336"
        }, {
            "label": "铁西区",
            "value": "210106",
            "center": "123.350664,41.787808"
        }, {
            "label": "苏家屯区",
            "value": "210111",
            "center": "123.341604,41.665904"
        }, {
            "label": "东陵区",
            "value": "210112",
            "center": "123.458981,41.741946"
        }, {
            "label": "沈北新区",
            "value": "210113",
            "center": "123.521471,42.052312"
        }, {
            "label": "于洪区",
            "value": "210114",
            "center": "123.310829,41.795833"
        }, {
            "label": "辽中县",
            "value": "210122",
            "center": "122.731269,41.512725"
        }, {
            "label": "康平县",
            "value": "210123",
            "center": "123.352703,42.741533"
        }, {
            "label": "法库县",
            "value": "210124",
            "center": "123.416722,42.507045"
        }, {
            "label": "新民市",
            "value": "210181",
            "center": "122.828868,41.996508"
        }]
    }, {
        "label": "大连市",
        "value": "210200",
        "center": "121.618622,38.91459",
        "children": [{
            "label": "中山区",
            "value": "210202",
            "center": "121.64376,38.921553"
        }, {
            "label": "西岗区",
            "value": "210203",
            "center": "121.616112,38.914266"
        }, {
            "label": "沙河口区",
            "value": "210204",
            "center": "121.593702,38.912859"
        }, {
            "label": "甘井子区",
            "value": "210211",
            "center": "121.582614,38.975148"
        }, {
            "label": "旅顺口区",
            "value": "210212",
            "center": "121.26713,38.812043"
        }, {
            "label": "金州区",
            "value": "210213",
            "center": "121.789413,39.052745"
        }, {
            "label": "长海县",
            "value": "210224",
            "center": "122.587824,39.272399"
        }, {
            "label": "瓦房店市",
            "value": "210281",
            "center": "122.002656,39.63065"
        }, {
            "label": "普兰店市",
            "value": "210282",
            "center": "121.9705,39.401555"
        }, {
            "label": "庄河市",
            "value": "210283",
            "center": "122.970612,39.69829"
        }]
    }, {
        "label": "鞍山市",
        "value": "210300",
        "center": "122.995632,41.110626",
        "children": [{
            "label": "铁东区",
            "value": "210302",
            "center": "122.994475,41.110344"
        }, {
            "label": "铁西区",
            "value": "210303",
            "center": "122.971834,41.11069"
        }, {
            "label": "立山区",
            "value": "210304",
            "center": "123.024806,41.150622"
        }, {
            "label": "千山区",
            "value": "210311",
            "center": "122.949298,41.068909"
        }, {
            "label": "台安县",
            "value": "210321",
            "center": "122.429736,41.38686"
        }, {
            "label": "岫岩满族自治县",
            "value": "210323",
            "center": "123.28833,40.281509"
        }, {
            "label": "海城市",
            "value": "210381",
            "center": "122.752199,40.852533"
        }]
    }, {
        "label": "抚顺市",
        "value": "210400",
        "center": "123.921109,41.875956",
        "children": [{
            "label": "新抚区",
            "value": "210402",
            "center": "123.902858,41.86082"
        }, {
            "label": "东洲区",
            "value": "210403",
            "center": "124.047219,41.866829"
        }, {
            "label": "望花区",
            "value": "210404",
            "center": "123.801509,41.851803"
        }, {
            "label": "顺城区",
            "value": "210411",
            "center": "123.917165,41.881132"
        }, {
            "label": "抚顺县",
            "value": "210421",
            "center": "124.097979,41.922644"
        }, {
            "label": "新宾满族自治县",
            "value": "210422",
            "center": "125.037547,41.732456"
        }, {
            "label": "清原满族自治县",
            "value": "210423",
            "center": "124.927192,42.10135"
        }]
    }, {
        "label": "本溪市",
        "value": "210500",
        "center": "123.770519,41.297909",
        "children": [{
            "label": "平山区",
            "value": "210502",
            "center": "123.761231,41.291581"
        }, {
            "label": "溪湖区",
            "value": "210503",
            "center": "123.765226,41.330056"
        }, {
            "label": "明山区",
            "value": "210504",
            "center": "123.763288,41.302429"
        }, {
            "label": "南芬区",
            "value": "210505",
            "center": "123.748381,41.104093"
        }, {
            "label": "本溪满族自治县",
            "value": "210521",
            "center": "124.126156,41.300344"
        }, {
            "label": "桓仁满族自治县",
            "value": "210522",
            "center": "125.359195,41.268997"
        }]
    }, {
        "label": "丹东市",
        "value": "210600",
        "center": "124.383044,40.124296",
        "children": [{
            "label": "元宝区",
            "value": "210602",
            "center": "124.397814,40.136483"
        }, {
            "label": "振兴区",
            "value": "210603",
            "center": "124.361153,40.102801"
        }, {
            "label": "振安区",
            "value": "210604",
            "center": "124.427709,40.158557"
        }, {
            "label": "宽甸满族自治县",
            "value": "210624",
            "center": "124.784867,40.730412"
        }, {
            "label": "东港市",
            "value": "210681",
            "center": "124.149437,39.883467"
        }, {
            "label": "凤城市",
            "value": "210682",
            "center": "124.071067,40.457567"
        }]
    }, {
        "label": "锦州市",
        "value": "210700",
        "center": "121.135742,41.119269",
        "children": [{
            "label": "古塔区",
            "value": "210702",
            "center": "121.130085,41.115719"
        }, {
            "label": "凌河区",
            "value": "210703",
            "center": "121.151304,41.114662"
        }, {
            "label": "太和区",
            "value": "210711",
            "center": "121.107297,41.105378"
        }, {
            "label": "黑山县",
            "value": "210726",
            "center": "122.117915,41.691804"
        }, {
            "label": "义县",
            "value": "210727",
            "center": "121.242831,41.537224"
        }, {
            "label": "凌海市",
            "value": "210781",
            "center": "121.364236,41.171738"
        }, {
            "label": "北镇市",
            "value": "210782",
            "center": "121.795962,41.598764"
        }]
    }, {
        "label": "营口市",
        "value": "210800",
        "center": "122.235151,40.667432",
        "children": [{
            "label": "站前区",
            "value": "210802",
            "center": "122.253235,40.669949"
        }, {
            "label": "西市区",
            "value": "210803",
            "center": "122.210067,40.663086"
        }, {
            "label": "鲅鱼圈区",
            "value": "210804",
            "center": "122.127242,40.263646"
        }, {
            "label": "老边区",
            "value": "210811",
            "center": "122.382584,40.682723"
        }, {
            "label": "盖州市",
            "value": "210881",
            "center": "122.355534,40.405234"
        }, {
            "label": "大石桥市",
            "value": "210882",
            "center": "122.505894,40.633973"
        }]
    }]
}, {
    "label": "黑龙江省",
    "value": "230000",
    "center": "126.642464,45.756967",
    "children": [{
        "label": "大庆市",
        "value": "230600",
        "center": "125.11272,46.590734",
        "children": [{
            "label": "萨尔图区",
            "value": "230602",
            "center": "125.114643,46.596356"
        }, {
            "label": "龙凤区",
            "value": "230603",
            "center": "125.145794,46.573948"
        }, {
            "label": "让胡路区",
            "value": "230604",
            "center": "124.868341,46.653254"
        }, {
            "label": "红岗区",
            "value": "230605",
            "center": "124.889528,46.403049"
        }, {
            "label": "大同区",
            "value": "230606",
            "center": "124.818509,46.034304"
        }, {
            "label": "肇州县",
            "value": "230621",
            "center": "125.273254,45.708685"
        }, {
            "label": "肇源县",
            "value": "230622",
            "center": "125.081974,45.518832"
        }, {
            "label": "林甸县",
            "value": "230623",
            "center": "124.877742,47.186411"
        }, {
            "label": "杜尔伯特蒙古族自治县",
            "value": "230624",
            "center": "124.446259,46.865973"
        }]
    }, {
        "label": "伊春市",
        "value": "230700",
        "center": "128.899396,47.724775",
        "children": [{
            "label": "伊春区",
            "value": "230702",
            "center": "128.899284,47.726851"
        }, {
            "label": "南岔区",
            "value": "230703",
            "center": "129.28246,47.137314"
        }, {
            "label": "友好区",
            "value": "230704",
            "center": "128.838961,47.854303"
        }, {
            "label": "西林区",
            "value": "230705",
            "center": "129.311441,47.479437"
        }, {
            "label": "翠峦区",
            "value": "230706",
            "center": "128.671746,47.726228"
        }, {
            "label": "新青区",
            "value": "230707",
            "center": "129.52995,48.288292"
        }, {
            "label": "美溪区",
            "value": "230708",
            "center": "129.133411,47.636102"
        }, {
            "label": "金山屯区",
            "value": "230709",
            "center": "129.435944,47.41295"
        }, {
            "label": "五营区",
            "value": "230710",
            "center": "129.245028,48.108204"
        }, {
            "label": "乌马河区",
            "value": "230711",
            "center": "128.802941,47.726961"
        }, {
            "label": "汤旺河区",
            "value": "230712",
            "center": "129.57224,48.453651"
        }, {
            "label": "带岭区",
            "value": "230713",
            "center": "129.021151,47.027532"
        }, {
            "label": "乌伊岭区",
            "value": "230714",
            "center": "129.437847,48.59112"
        }, {
            "label": "红星区",
            "value": "230715",
            "center": "129.388796,48.238368"
        }, {
            "label": "上甘岭区",
            "value": "230716",
            "center": "129.02508,47.974859"
        }, {
            "label": "嘉荫县",
            "value": "230722",
            "center": "130.397684,48.891378"
        }, {
            "label": "铁力市",
            "value": "230781",
            "center": "128.030561,46.985772"
        }]
    }, {
        "label": "佳木斯市",
        "value": "230800",
        "center": "130.361634,46.809606",
        "children": [{
            "label": "向阳区",
            "value": "230803",
            "center": "130.361786,46.809645"
        }, {
            "label": "前进区",
            "value": "230804",
            "center": "130.377684,46.812345"
        }, {
            "label": "东风区",
            "value": "230805",
            "center": "130.403297,46.822476"
        }, {
            "label": "郊区",
            "value": "230811",
            "center": "130.351588,46.80712"
        }, {
            "label": "桦南县",
            "value": "230822",
            "center": "130.570112,46.240118"
        }, {
            "label": "桦川县",
            "value": "230826",
            "center": "130.723713,47.023039"
        }, {
            "label": "汤原县",
            "value": "230828",
            "center": "129.904463,46.730048"
        }, {
            "label": "抚远县",
            "value": "230833",
            "center": "134.294501,48.364707"
        }, {
            "label": "同江市",
            "value": "230881",
            "center": "132.510119,47.651131"
        }, {
            "label": "富锦市",
            "value": "230882",
            "center": "132.037951,47.250747"
        }]
    }, {
        "label": "七台河市",
        "value": "230900",
        "center": "131.015584,45.771266",
        "children": [{
            "label": "新兴区",
            "value": "230902",
            "center": "130.889482,45.794258"
        }, {
            "label": "桃山区",
            "value": "230903",
            "center": "131.015848,45.771217"
        }, {
            "label": "茄子河区",
            "value": "230904",
            "center": "131.071561,45.776587"
        }, {
            "label": "勃利县",
            "value": "230921",
            "center": "130.575025,45.751573"
        }]
    }, {
        "label": "牡丹江市",
        "value": "231000",
        "center": "129.618602,44.582962",
        "children": [{
            "label": "东安区",
            "value": "231002",
            "center": "129.623292,44.582399"
        }, {
            "label": "阳明区",
            "value": "231003",
            "center": "129.634645,44.596328"
        }, {
            "label": "爱民区",
            "value": "231004",
            "center": "129.601232,44.595443"
        }, {
            "label": "西安区",
            "value": "231005",
            "center": "129.61311,44.581032"
        }, {
            "label": "东宁县",
            "value": "231024",
            "center": "131.125296,44.063578"
        }, {
            "label": "林口县",
            "value": "231025",
            "center": "130.268402,45.286645"
        }, {
            "label": "绥芬河市",
            "value": "231081",
            "center": "131.164856,44.396864"
        }, {
            "label": "海林市",
            "value": "231083",
            "center": "129.387902,44.574149"
        }, {
            "label": "宁安市",
            "value": "231084",
            "center": "129.470019,44.346836"
        }, {
            "label": "穆棱市",
            "value": "231085",
            "center": "130.527085,44.91967"
        }]
    }, {
        "label": "黑河市",
        "value": "231100",
        "center": "127.499023,50.249585",
        "children": [{
            "label": "爱辉区",
            "value": "231102",
            "center": "127.497639,50.249027"
        }, {
            "label": "嫩江县",
            "value": "231121",
            "center": "125.229904,49.177461"
        }, {
            "label": "逊克县",
            "value": "231123",
            "center": "128.476152,49.582974"
        }, {
            "label": "孙吴县",
            "value": "231124",
            "center": "127.327315,49.423941"
        }, {
            "label": "北安市",
            "value": "231181",
            "center": "126.508737,48.245437"
        }, {
            "label": "五大连池市",
            "value": "231182",
            "center": "126.197694,48.512688"
        }]
    }, {
        "label": "绥化市",
        "value": "231200",
        "center": "126.99293,46.637393",
        "children": [{
            "label": "北林区",
            "value": "231202",
            "center": "126.990665,46.634912"
        }, {
            "label": "望奎县",
            "value": "231221",
            "center": "126.484191,46.83352"
        }, {
            "label": "兰西县",
            "value": "231222",
            "center": "126.289315,46.259037"
        }, {
            "label": "青冈县",
            "value": "231223",
            "center": "126.112268,46.686596"
        }, {
            "label": "庆安县",
            "value": "231224",
            "center": "127.510024,46.879203"
        }, {
            "label": "明水县",
            "value": "231225",
            "center": "125.907544,47.183527"
        }, {
            "label": "绥棱县",
            "value": "231226",
            "center": "127.111121,47.247195"
        }, {
            "label": "安达市",
            "value": "231281",
            "center": "125.329926,46.410614"
        }, {
            "label": "肇东市",
            "value": "231282",
            "center": "125.991402,46.069471"
        }, {
            "label": "海伦市",
            "value": "231283",
            "center": "126.969383,47.460428"
        }]
    }, {
        "label": "大兴安岭地区",
        "value": "232700",
        "center": "124.711526,52.335262",
        "children": [{
            "label": "加格达奇区",
            "value": "232701",
            "center": "124.126716,50.424654"
        }, {
            "label": "松岭区",
            "value": "232702",
            "center": "124.298918,50.794648"
        }, {
            "label": "新林区",
            "value": "232703",
            "center": "124.397226,51.673995"
        }, {
            "label": "呼中区",
            "value": "232704",
            "center": "123.581431,52.037471"
        }, {
            "label": "呼玛县",
            "value": "232721",
            "center": "126.662105,51.726998"
        }, {
            "label": "塔河县",
            "value": "232722",
            "center": "124.710516,52.335229"
        }, {
            "label": "漠河县",
            "value": "232723",
            "center": "122.536256,52.972074"
        }]
    }, {
        "label": "哈尔滨市",
        "value": "230100",
        "center": "126.642464,45.756967",
        "children": [{
            "label": "道里区",
            "value": "230102",
            "center": "126.612532,45.762035"
        }, {
            "label": "南岗区",
            "value": "230103",
            "center": "126.652098,45.755971"
        }, {
            "label": "道外区",
            "value": "230104",
            "center": "126.648838,45.78454"
        }, {
            "label": "平房区",
            "value": "230108",
            "center": "126.629257,45.605567"
        }, {
            "label": "松北区",
            "value": "230109",
            "center": "126.563066,45.814656"
        }, {
            "label": "香坊区",
            "value": "230110",
            "center": "126.667049,45.713067"
        }, {
            "label": "呼兰区",
            "value": "230111",
            "center": "126.603302,45.98423"
        }, {
            "label": "阿城区",
            "value": "230112",
            "center": "126.972726,45.538372"
        }, {
            "label": "依兰县",
            "value": "230123",
            "center": "129.565594,46.315105"
        }, {
            "label": "方正县",
            "value": "230124",
            "center": "128.836131,45.839536"
        }, {
            "label": "宾县",
            "value": "230125",
            "center": "127.48594,45.759369"
        }, {
            "label": "巴彦县",
            "value": "230126",
            "center": "127.403602,46.081889"
        }, {
            "label": "木兰县",
            "value": "230127",
            "center": "128.042675,45.949826"
        }, {
            "label": "通河县",
            "value": "230128",
            "center": "128.747786,45.977618"
        }, {
            "label": "延寿县",
            "value": "230129",
            "center": "128.331886,45.455648"
        }, {
            "label": "双城市",
            "value": "230182",
            "center": "126.308784,45.377942"
        }, {
            "label": "尚志市",
            "value": "230183",
            "center": "127.968539,45.214953"
        }, {
            "label": "五常市",
            "value": "230184",
            "center": "127.15759,44.919418"
        }]
    }, {
        "label": "齐齐哈尔市",
        "value": "230200",
        "center": "123.95792,47.342081",
        "children": [{
            "label": "龙沙区",
            "value": "230202",
            "center": "123.957338,47.341736"
        }, {
            "label": "建华区",
            "value": "230203",
            "center": "123.955888,47.354494"
        }, {
            "label": "铁锋区",
            "value": "230204",
            "center": "123.973555,47.339499"
        }, {
            "label": "昂昂溪区",
            "value": "230205",
            "center": "123.813181,47.156867"
        }, {
            "label": "富拉尔基区",
            "value": "230206",
            "center": "123.638873,47.20697"
        }, {
            "label": "碾子山区",
            "value": "230207",
            "center": "122.887972,47.51401"
        }, {
            "label": "梅里斯达斡尔族区",
            "value": "230208",
            "center": "123.754599,47.311113"
        }, {
            "label": "龙江县",
            "value": "230221",
            "center": "123.187225,47.336388"
        }, {
            "label": "依安县",
            "value": "230223",
            "center": "125.307561,47.890098"
        }, {
            "label": "泰来县",
            "value": "230224",
            "center": "123.41953,46.39233"
        }, {
            "label": "甘南县",
            "value": "230225",
            "center": "123.506034,47.917838"
        }, {
            "label": "富裕县",
            "value": "230227",
            "center": "124.469106,47.797172"
        }, {
            "label": "克山县",
            "value": "230229",
            "center": "125.874355,48.034342"
        }, {
            "label": "克东县",
            "value": "230230",
            "center": "126.249094,48.03732"
        }, {
            "label": "拜泉县",
            "value": "230231",
            "center": "126.091911,47.607363"
        }, {
            "label": "讷河市",
            "value": "230281",
            "center": "124.882172,48.481133"
        }]
    }, {
        "label": "鸡西市",
        "value": "230300",
        "center": "130.975966,45.300046",
        "children": [{
            "label": "鸡冠区",
            "value": "230302",
            "center": "130.974374,45.30034"
        }, {
            "label": "恒山区",
            "value": "230303",
            "center": "130.910636,45.213242"
        }, {
            "label": "滴道区",
            "value": "230304",
            "center": "130.846823,45.348812"
        }, {
            "label": "梨树区",
            "value": "230305",
            "center": "130.697781,45.092195"
        }, {
            "label": "城子河区",
            "value": "230306",
            "center": "131.010501,45.338248"
        }, {
            "label": "麻山区",
            "value": "230307",
            "center": "130.481126,45.209607"
        }, {
            "label": "鸡东县",
            "value": "230321",
            "center": "131.148907,45.250892"
        }, {
            "label": "虎林市",
            "value": "230381",
            "center": "132.973881,45.767985"
        }, {
            "label": "密山市",
            "value": "230382",
            "center": "131.874137,45.54725"
        }]
    }, {
        "label": "鹤岗市",
        "value": "230400",
        "center": "130.277487,47.332085",
        "children": [{
            "label": "向阳区",
            "value": "230402",
            "center": "130.292478,47.345372"
        }, {
            "label": "工农区",
            "value": "230403",
            "center": "130.276652,47.331678"
        }, {
            "label": "南山区",
            "value": "230404",
            "center": "130.275533,47.31324"
        }, {
            "label": "兴安区",
            "value": "230405",
            "center": "130.236169,47.252911"
        }, {
            "label": "东山区",
            "value": "230406",
            "center": "130.31714,47.337385"
        }, {
            "label": "兴山区",
            "value": "230407",
            "center": "130.30534,47.35997"
        }, {
            "label": "萝北县",
            "value": "230421",
            "center": "130.829087,47.577577"
        }, {
            "label": "绥滨县",
            "value": "230422",
            "center": "131.860526,47.289892"
        }]
    }, {
        "label": "双鸭山市",
        "value": "230500",
        "center": "131.157304,46.643442",
        "children": [{
            "label": "宝清县",
            "value": "230523",
            "center": "132.206415,46.328781"
        }, {
            "label": "饶河县",
            "value": "230524",
            "center": "134.021162,46.801288"
        }, {
            "label": "尖山区",
            "value": "230502",
            "center": "131.15896,46.642961"
        }, {
            "label": "岭东区",
            "value": "230503",
            "center": "131.163675,46.591076"
        }, {
            "label": "四方台区",
            "value": "230505",
            "center": "131.333181,46.594347"
        }, {
            "label": "宝山区",
            "value": "230506",
            "center": "131.404294,46.573366"
        }, {
            "label": "集贤县",
            "value": "230521",
            "center": "131.13933,46.72898"
        }, {
            "label": "友谊县",
            "value": "230522",
            "center": "131.810622,46.775159"
        }]
    }]
}]